import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UserAccountType } from 'src/app/core/enums/user-account-type.enum';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { MustMatch } from '../../validators/mustmatch.validator';
import { AddUser } from '../../actions/user.actions';
import { getAccountTypeId } from '../../selectors/auth.selector';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

    formAU: FormGroup;
    UserAccountType = UserAccountType;

    roles = [
        { id: UserAccountType.ADMIN, name: 'ADMIN' },
        { id: UserAccountType.USER, name: 'USER' },
        { id: UserAccountType.CONTACT, name: 'CONTACT' },
    ];

    constructor(
        public dialogRef: MatDialogRef<AddUserComponent>,
        private fb: FormBuilder,
        private store: Store<AppState>) {

        this.formAU = this.fb.group({
            role: [UserAccountType.USER, [Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            userEmail: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
            phone: ['', [Validators.pattern(/^[0-9]*$/), Validators.minLength(10), Validators.maxLength(10)]],
            userPassword: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/)]],
            confirmPassword: ['', [Validators.required]],
            // username: [''],
            // workPhone: ['', [Validators.pattern(/^[0-9]*$/)]],
        }, {
            validator: MustMatch('userPassword', 'confirmPassword')
        });
    }

    ngOnInit() {
        this.store.select(getAccountTypeId).subscribe((accountType) => {
            if (accountType === AccountTypes.Consumer) {
                this.roles = this.roles.filter(r => r.id !== UserAccountType.CONTACT);
            }
        });

        this.form.role.valueChanges.subscribe(roleValue => {
            if (roleValue === UserAccountType.CONTACT) {
                this.form.userPassword.clearValidators();
                this.form.userPassword.reset();
                this.form.confirmPassword.clearValidators();
                this.form.confirmPassword.reset();
                this.formAU.clearValidators();
            } else {
                this.form.userPassword.setValidators([Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/)]);
                this.form.confirmPassword.setValidators(Validators.required);
                this.formAU.setValidators(MustMatch('userPassword', 'confirmPassword'));
            }
            this.formAU.updateValueAndValidity();
        });
    }

    get form() { return this.formAU.controls; }

    onSubmit() {
        if (this.formAU.invalid) {
            return;
        }
        this.store.dispatch(new AddUser({ user: this.formAU.value }));
    }

    close(): void {
        this.dialogRef.close();
    }
}
