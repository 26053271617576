import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { RegisterActionTypes, Register, RegisterInfo, UpdateRegisterLoading, VerifyAccount, VerifyAccountSuccess, VerifyAccountError, LoadProfiles, LoadProfilesSuccess } from '../actions/register.actions';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { of } from 'rxjs';
import { RegisterService } from 'src/app/core/services/register/register.service';


@Injectable()
export class RegisterEffects {

    @Effect({ dispatch: false })
    register$ = this.actions$.pipe(
        ofType<Register>(RegisterActionTypes.Register),
        exhaustMap((action) => this.registerSvc.register(action.payload.account)
            .pipe(
                map((response: any) => this.router.navigate(['/createaccount', 'info'], {
                    queryParams: {
                        token: response.token
                    }
                })),
                catchError(err => {
                    this.notifier.notify('error', err.error.message);
                    this.store.dispatch(new UpdateRegisterLoading({
                        loading: false
                    }));
                    return of(err);
                })
            ))
    );

    @Effect({ dispatch: false })
    registerInfo$ = this.actions$.pipe(
        ofType<RegisterInfo>(RegisterActionTypes.RegisterInfo),
        exhaustMap((action) => this.registerSvc.registerInfo(action.payload.account)
            .pipe(
                catchError(err => {
                    this.notifier.notify('error', err.error.message);
                    this.store.dispatch(new UpdateRegisterLoading({
                        loading: false
                    }));
                    return of(err);
                })
            )
        )
    );

    @Effect()
    verifyAccount$ = this.actions$.pipe(
        ofType<VerifyAccount>(RegisterActionTypes.VerifyAccount),
        exhaustMap((action) =>
            this.registerSvc.verifyAccount(action.payload.token)
                .pipe(
                    map(response => new VerifyAccountSuccess()),
                    catchError((response: HttpErrorResponse) => of(new VerifyAccountError({
                        message: response.message
                    }))
                    )
                )
        )
    );

    @Effect()
    loadProfiles$ = this.actions$.pipe(
        ofType<LoadProfiles>(RegisterActionTypes.LoadProfiles),
        exhaustMap((action) => this.registerSvc.loadProfiles()),
        map((response: { profiles: any[] }) => new LoadProfilesSuccess({ profiles: response.profiles }))
    );

    constructor(private actions$: Actions,
        private registerSvc: RegisterService,
        private router: Router,
        private notifier: NotifierService,
        private store: Store<AppState>) { }

}
