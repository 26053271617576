import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerDetailsModel, CustomerDetailsUpdateModel } from '../../models/customer/customer-details.model';
import { CustomerInvitesFilter } from '../../models/customer/customer-invites.filter';
import { ResendInviteModel } from '../../models/customer/resend-invite.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient) { }


    getCustomers(params: any) {
        return this.http.post(`${environment.api.content}/Customer/search`, {
            page: params.page,
            statuses: params.statuses,
            priceLevels: params.priceLevels,
            // startDate: this.datePipe.transform(params.start, 'MM/dd/yyyy'),
            // endDate: this.datePipe.transform(params.end, 'MM/dd/yyyy'),
            keyword: params.keyword
        });
    }

    getCustomerLeads(params: any) {
        return this.http.post(`${environment.api.content}/Customer/leads`, {
            page: params.page,
            keyword: params.keyword,
            isArchived: params.isArchived
        });
    }

    saveLeads(params: any) {
        return this.http.post(`${environment.api.content}/Customer/leads/${params.leadId}`, {
            isProcessing: params.isProcessing,
            statusId: params.statusId,
            customerSiteId: params.customerSiteId,
            notes: params.notes,
            priceLevelId: params.priceLevelId,
            representativeId: params.representativeId,
            buyerSiteCode: params.buyerSiteCode
        });
    }

    getCustomerDetails(customerSiteId: number) {
        return this.http.get(`${environment.api.content}/Customer/${customerSiteId}`);
    }

    updateCustomerDetails(payload: any) {
        return this.http.put(`${environment.api.content}/Customer/${payload.customerId}`, payload.customerDetailsUpdate);
    }

    getCustomerInvites(invitesFilter: CustomerInvitesFilter) {
        return this.http.post(`${environment.api.content}/Customer/invites`, invitesFilter);
    }

    inviteUser(invite: ResendInviteModel) {
        return this.http.post(`${environment.api.content}/Customer/invite_user`, invite);
    }

    deleteInvite(invitationId: number) {
        return this.http.delete(`${environment.api.content}/Customer/invites/${invitationId}`);
    }
}
