import { CartActions, CartActionTypes } from '../actions/cart.actions';
import { randomColor } from '../constants/global.contants';
import { query } from '@angular/animations';

export interface CartState {
    summary: any,
    loadingSummary: boolean,
    cartDetails?: any,
    loadingCart?: boolean,
    paymentProfiles: any[],
    closePaymentModal?: boolean,
    savingTips?: boolean;
}

export const initialState: CartState = {
    summary: false,
    loadingSummary: true,
    cartDetails: undefined,
    loadingCart: false,
    paymentProfiles: [],
    closePaymentModal: false,
    savingTips: false
};

export function cartReducer(state = initialState,
    action: CartActions): CartState {
    switch (action.type) {
        case CartActionTypes.LoadCartSummaryAction:
            return {
                ...state,
                loadingSummary: true
            };
        case CartActionTypes.CartSummaryLoadedAction:
            return {
                ...state,
                summary: action.payload.summary,
                loadingSummary: false
            };
        case CartActionTypes.LoadCart:
            return {
                ...state,
                loadingCart: true
            };
        case CartActionTypes.CartLoaded:
            return {
                ...state,
                cartDetails: action.payload.cartDetails ? action.payload.cartDetails.map(i => ({
                    ...i,
                    isExpanded: true,
                    randomColor: randomColor()
                })) : [],
                loadingCart: false
            };
        case CartActionTypes.DeleteCartByVendorId:
            return {
                ...state,
                loadingCart: true
            };
        case CartActionTypes.DeleteCartByVendorIdSuccess:
            return {
                ...state,
                cartDetails: state.cartDetails.filter(cd => cd.id !== action.payload.vendorId) || [],
                loadingCart: false
            };
        case CartActionTypes.ExpandCartOrder:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    isExpanded: cd.id === action.payload.vendorId ? action.payload.isExpanded : cd.isExpanded
                }))
            }
        case CartActionTypes.SaveNotesSuccess:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    notes: cd.id === action.payload.vendorId ? action.payload.notes : cd.notes
                }))
            }
        case CartActionTypes.UpdateShoppingCartAfterAddSuccess:
            if (!state.cartDetails) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    items: cd.items
                        .filter(i => !(cd.id === action.payload.vendorId && i.productId === action.payload.productId && action.payload.quantity === 0))
                        .map(i => ({
                            ...i,
                            vendor: {
                                ...i.vendor,
                                quantity: cd.id === action.payload.vendorId && i.productId === action.payload.productId ? action.payload.quantity : i.vendor.quantity
                            }
                        }))
                }))
            }
        case CartActionTypes.MoveProduct:
            return {
                ...state,
                loadingCart: true
            };

        case CartActionTypes.SubmitOrder:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: cd.id === action.payload.vendorId
                }))
            };
        case CartActionTypes.SubmitOrderSuccess:
            return {
                ...state,
                closePaymentModal: true,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: false,
                    submittedDetails: cd.id === action.payload.response.vendorId ? action.payload.response : cd.submittedDetails
                }))
            };
        case CartActionTypes.SubmitOrderError:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: false,
                    validationMessages: cd.id === action.payload.vendorId ? [...cd.validationMessages.filter(vm => vm !== action.payload.message), action.payload.message] : cd.validationMessages
                }))
            };
        case CartActionTypes.SaveDelivery:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: cd.id === action.payload.vendorId ? true : cd.loading
                }))
            };
        case CartActionTypes.SaveDeliverySuccess:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    validationMessages: cd.validationMessages.filter(vm => vm !== 'The delivery date is not available!'),
                    loading: cd.id === action.payload.vendorId ? false : cd.loading,
                    errorMessage: cd.id === action.payload.vendorId ? undefined : cd.errorMessage,
                    deliveryDate: cd.id === action.payload.vendorId ? action.payload.deliveryDate : cd.deliveryDate,
                    deliveryFromTime: cd.id === action.payload.vendorId ? action.payload.deliveryFromTime : cd.deliveryFromTime,
                    deliveryToTime: cd.id === action.payload.vendorId ? action.payload.deliveryToTime : cd.deliveryToTime
                })),
            };
        case CartActionTypes.SaveDeliveryError:
            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    loading: false,
                    deliveryDate: cd.id === action.payload.vendorId ? action.payload.deliveryDate : cd.deliveryDate,
                    deliveryFromTime: cd.id === action.payload.vendorId ? action.payload.deliveryFromTime : cd.deliveryFromTime,
                    deliveryToTime: cd.id === action.payload.vendorId ? action.payload.deliveryToTime : cd.deliveryToTime,
                    validationMessages: cd.id === action.payload.vendorId ? [...cd.validationMessages.filter(vm => vm !== action.payload.errorMessage), action.payload.errorMessage] : cd.validationMessages
                }))
            };
        case CartActionTypes.LoadCartTotalsSuccess:
            if (!action.payload.cartVendorTotals) {
                return state;
            }

            return {
                ...state,
                cartDetails: state.cartDetails.map(cd => ({
                    ...cd,
                    totalPrice: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.totalPrice : cd.totalPrice,
                    deliveryFee: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.deliveryFee : cd.deliveryFee,
                    subTotalPrice: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.subTotalPrice : cd.subTotalPrice,
                    items: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.items : cd.items,
                    canSubmitOrder: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.canSubmitOrder : cd.canSubmitOrder,
                    validationMessages: cd.id === action.payload.cartVendorTotals.id ? action.payload.cartVendorTotals.validationMessages : cd.validationMessages
                }))
            };
        case CartActionTypes.SaveTips:
            return {
                ...state,
                savingTips: true
            };
        case CartActionTypes.SaveTipsSuccess:
            return {
                ...state,
                savingTips: false
            };
        default:
            return state;
    }
}