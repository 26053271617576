import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { LoadCountries, LoadStates, LoadTimezones } from '../../actions/utils.actions';
import { selectStates, selectCountries, selectTimezones } from '../../selectors/utils.selector';
import { filter, tap } from 'rxjs/operators';
import { AddSite, UpdateSite } from '../../actions/site.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToggleInfobar } from '../../actions/infobar.actions';

@Component({
    selector: 'app-add-location',
    templateUrl: './add-location.component.html',
    styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit, OnChanges {

    @Input() site: any;

    formAddL: FormGroup;

    countries$: Observable<any[]>;
    billingStates$: Observable<any[]>;
    shippingStates$: Observable<any[]>;
    timezones$: Observable<any[]>;
    locationsSA$: Observable<any[]>;
    locationsBA$: Observable<any[]>;
    billingCountryId: number;
    isBillingAddressSameAsShipping = false;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>) {
        this.formAddL = this.fb.group({
            id: [null, []],
            name: [null, [Validators.required]],
            timezoneId: [null, [Validators.required]],
            shippingAddress: this.fb.group({
                existingSA: [null],
                address1: [null, [Validators.required]],
                city: [null, [Validators.required]],
                zipCode: [null, [Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
                countryId: [null, [Validators.required]],
                stateId: [null, [Validators.required]],
            }),
            billingAddress: this.fb.group({
                existingBA: [null],
                address1: [null, [Validators.required]],
                city: [null, [Validators.required]],
                zipCode: [null, [Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
                countryId: [null, [Validators.required]],
                stateId: [null, [Validators.required]],
            }),
        });
    }

    ngOnInit() {
        this.countries$ = this.store.select(selectCountries);
        this.timezones$ = this.store.select(selectTimezones);

        this.store.dispatch(new LoadCountries());
        this.store.dispatch(new LoadTimezones());

        // Shipping Address
        this.shippingAddress.countryId.valueChanges
            .pipe(filter(value => value !== null && value !== undefined))
            .subscribe(() => {
                this.shippingStates$ = this.store.select(selectStates, {
                    countryId: this.shippingAddress.countryId.value
                });

                this.store.dispatch(new LoadStates({
                    countryId: this.shippingAddress.countryId.value
                }));

                this.shippingAddress.stateId.setValue(null);
                this.shippingAddress.stateId.updateValueAndValidity({ onlySelf: true });
            });

        // Billing Address
        this.billingAddress.countryId.valueChanges
            .pipe(filter(value => value !== null && value !== undefined))
            .subscribe((value) => {
                this.billingStates$ = this.store.select(selectStates, {
                    countryId: this.billingAddress.countryId.value
                });

                this.store.dispatch(new LoadStates({
                    countryId: this.billingAddress.countryId.value
                }));
                if (this.billingCountryId !== +value) {
                    this.billingCountryId = +value;
                    this.billingAddress.stateId.setValue(null);
                    this.billingAddress.stateId.updateValueAndValidity({ onlySelf: true });
                }
            });

        //TO DO; after endpoint
        this.locationsSA$ = of([0, 21176, 21178]);
        this.locationsBA$ = of([0, 21177, 21179]);

        this.shippingAddress.existingSA.valueChanges
            .pipe(filter(value => value !== null && value !== undefined))
            .subscribe((shippingValue) => {
                if (shippingValue === 0) {
                    this.shippingAddress.address1.setValidators(Validators.required);
                    this.shippingAddress.city.setValidators(Validators.required);
                    this.shippingAddress.zipCode.setValidators([Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]);
                    this.shippingAddress.countryId.setValidators(Validators.required);
                    this.shippingAddress.stateId.setValidators(Validators.required);
                } else {
                    this.shippingAddress.address1.setValidators(null);
                    this.shippingAddress.city.setValidators(null);
                    this.shippingAddress.zipCode.setValidators(null);
                    this.shippingAddress.countryId.setValidators(null);
                    this.shippingAddress.stateId.setValidators(null);
                }

                this.shippingAddress.address1.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.city.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.zipCode.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.countryId.updateValueAndValidity({ onlySelf: true });
                this.shippingAddress.stateId.updateValueAndValidity({ onlySelf: true });
            });

        this.billingAddress.existingBA.valueChanges
            .pipe(filter(value => value !== null && value !== undefined))
            .subscribe((billlingValue) => {
                this.changeBillingAddressValidators(billlingValue === 0);
            });
    }

    ngOnChanges(changes) {
        if (changes.site.currentValue) {
            this.formAddL.patchValue(changes.site.currentValue, {
                emitEvent: false
            });

            this.shippingStates$ = this.store.select(selectStates, {
                countryId: changes.site.currentValue.shippingAddress.countryId
            });

            this.billingStates$ = this.store.select(selectStates, {
                countryId: changes.site.currentValue.billingAddress.countryId
            });

            this.store.dispatch(new LoadStates({ countryId: changes.site.currentValue.billingAddress.countryId }));
            this.store.dispatch(new LoadStates({ countryId: changes.site.currentValue.shippingAddress.countryId }));
        } else {
            this.formAddL.reset(undefined, { emitEvent: false });
        }
    }

    get form() { return this.formAddL.controls; }

    get shippingAddress() { return (this.formAddL.controls.shippingAddress as FormGroup).controls }

    get billingAddress() { return (this.formAddL.controls.billingAddress as FormGroup).controls }


    onSubmit() {
        if (this.isBillingAddressSameAsShipping) {
            this.form.billingAddress.patchValue(this.form.shippingAddress.value);
        }

        if (this.formAddL.invalid) {
            return;
        }

        let billingAddressObj = null;
        let shippingAddressObj = null;

        if (this.shippingAddress.existingSA.value === 0 || this.shippingAddress.existingSA.value === null) {
            shippingAddressObj = {
                address1: this.shippingAddress.address1.value,
                city: this.shippingAddress.city.value,
                zipCode: this.shippingAddress.zipCode.value,
                countryId: this.shippingAddress.countryId.value,
                stateId: this.shippingAddress.stateId.value,
            };
        }
        if (this.billingAddress.existingBA.value === 0 || this.billingAddress.existingBA.value === null) {
            billingAddressObj = {
                address1: this.billingAddress.address1.value,
                city: this.billingAddress.city.value,
                zipCode: this.billingAddress.zipCode.value,
                countryId: this.billingAddress.countryId.value,
                stateId: this.billingAddress.stateId.value,
            };
        }

        const site = {
            ...this.formAddL.value,
            shippingAddressId: this.shippingAddress.existingSA.value,
            shippingAddress: shippingAddressObj,
            billingAddressId: this.billingAddress.existingBA.value,
            billingAddress: billingAddressObj
        }

        if (this.form.id.value) {
            this.store.dispatch(new UpdateSite({ site }));
        } else {
            this.store.dispatch(new AddSite({ site }))
        }
    }

    onSameBillingAddressChange($event: MatCheckboxChange) {
        this.isBillingAddressSameAsShipping = $event.checked;
        this.changeBillingAddressValidators(!this.isBillingAddressSameAsShipping);
    }

    onCancel() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    private changeBillingAddressValidators(applyDefaultValidators: boolean) {
        if (applyDefaultValidators) {
            this.billingAddress.address1.setValidators(Validators.required);
            this.billingAddress.city.setValidators(Validators.required);
            this.billingAddress.zipCode.setValidators([Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]);
            this.billingAddress.countryId.setValidators(Validators.required);
            this.billingAddress.stateId.setValidators(Validators.required);
        } else {
            this.billingAddress.address1.clearValidators();
            this.billingAddress.city.clearValidators();
            this.billingAddress.zipCode.clearValidators();
            this.billingAddress.countryId.clearValidators();
            this.billingAddress.stateId.clearValidators();
        }

        this.billingAddress.address1.updateValueAndValidity();
        this.billingAddress.city.updateValueAndValidity();
        this.billingAddress.zipCode.updateValueAndValidity();
        this.billingAddress.countryId.updateValueAndValidity();
        this.billingAddress.stateId.updateValueAndValidity();
    }

}
