import { Action } from '@ngrx/store';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';

export enum InfobarActionTypes {
    ToggleInfobar = '[Global] Toggle Infobar',
    UpdateTransaction = '[Infobar] Update transaction',
    UpdateSelectedProducts = '[Infobar] Update Selected Products'
}

export class ToggleInfobar implements Action {
    readonly type = InfobarActionTypes.ToggleInfobar;

    constructor(public payload: {
        open: boolean,
        title?: string,
        componentsIds?: InfobarComponents[],
        params?: { [id: string]: any } // use InfobarComponents enum for keys
    }) { }
}

export class UpdateTransaction implements Action {
    readonly type = InfobarActionTypes.UpdateTransaction;

    constructor(public payload: {
        transaction: any
    }) { }
}

export type InfobarActions = ToggleInfobar | UpdateTransaction;
