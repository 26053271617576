// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    platformId: '2a812ad1-8fcb-47a4-98e0-9776dda4d5e1',
    platformName: 'Dine Market',
    api: {
        auth: 'http://staging.auth.amast.com/api',
        content: 'https://staging.api.amast.com/api'
    },
    assets: {
        key: '2a812ad1-8fcb-47a4-98e0-9776dda4d5e1',
        baseUrl: 'https://dinemarket.s3.amazonaws.com/content'
    },
    basePlatformUrl: 'http://staging.app.dinemarket.com',
    FUNDBOX_CONFIG: {
        KEY: 'pk_test_924ccada-e5e9-4e1e-bec0-f66ecab6193c',
        ENV: 'https://checkout-integration.fundboxpay.com'
    },
    PUBNUB_KEYS: {
        PUBLISH_KEY: 'pub-c-e4f4a1bd-86fb-4774-a909-b24ee43df1e3',
        SUBSCRIBE_KEY: 'sub-c-45f25d2a-bb97-11e6-a856-0619f8945a4f',
    },
    createAccountLink: '',
    GA_TRACKER_ID: 'UA-17021264-16'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
