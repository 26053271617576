export class CompleteAccountModel {
    email: string;
    password: string;
    confirmPassword: string;
    acceptedTermsAndConditions = true;
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    street: string;
    city: string;
    zipCode: string;
    stateId: number;
    countryId: number;
    address: string;
}
