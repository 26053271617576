import { Component, OnInit } from '@angular/core';
import { LoadPaymentProfiles, DeletePaymentProfile } from '../../actions/payment.actions';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { selectPaymentProfiles, selectPaymentProfilesLoading } from '../../selectors/payment.selector';
import { currentSiteID } from '../../selectors/auth.selector';

@Component({
  selector: 'app-manage-payment-methods',
  templateUrl: './manage-payment-methods.component.html',
  styleUrls: ['./manage-payment-methods.component.scss']
})
export class ManagePaymentMethodsComponent implements OnInit {

  paymentProfiles$: Observable<any[]>;
  loading$: Observable<boolean>;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading$ = this.store.select(selectPaymentProfilesLoading);
    this.paymentProfiles$ = this.store.select(selectPaymentProfiles)
    .pipe(
        takeUntil(this.ngUnsubscribe),
        map(p => p.filter(pm => pm.paymentMethod === PAYMENT_METHOD.CREDIT_CARD))
    );

    this.store.select(currentSiteID).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(siteId => siteId !== undefined),
      tap(() => {
        this.store.dispatch(new LoadPaymentProfiles());
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onDelete(profile) {
    const confirmRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: `Are you sure you want to delete this credit card ?`
      },
    });
    confirmRef.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.store.dispatch(new DeletePaymentProfile({
          profileId: profile.id
        }));
      }
    });
  }

}
