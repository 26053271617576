import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { selectSitesList, selectCreditRequestApp } from '../../selectors/site.selector';
import { map, takeUntil, filter } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { selectStates } from '../../selectors/utils.selector';
import { LoadStates } from '../../actions/utils.actions';
import { CreditApplication, AddCreditApplication } from '../../actions/site.actions';
import { BusinessType } from 'src/app/core/enums/business-type.enum';
import { TitleType } from 'src/app/core/enums/title-type.enum';

@Component({
    selector: 'app-credit-request-application',
    templateUrl: './credit-request-application.component.html',
    styleUrls: ['./credit-request-application.component.scss']
})

export class CreditRequestApplicationComponent implements OnInit, OnChanges, OnDestroy {

    @Input() locationId: number;

    users$: Observable<any>;
    site$: Observable<any>;
    states$: Observable<any>;
    creditRA$: Observable<any>;
    formCreditRA: FormGroup;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder) { }

    ngOnInit() {
        this.formCreditRA = this.fb.group({
            business: this.fb.group({
                corporateName: [null, [Validators.required]],
                dbaName: [null, [Validators.required]],
                typeOfBusiness: [null],
                AuthorizedPurchasers: [null],
                federalTaxId: [null],
                liquorLicenceNumber: [null],
                accountPayableContact: this.fb.group({
                    firstName: [null, [Validators.required]],
                    lastName: [null, [Validators.required]],
                    email: [null, [Validators.required]],
                    phone: [null, [Validators.required]],
                }),
                corporateAddress: this.fb.group({
                    address1: [null, [Validators.required]],
                    address2: [null],
                    city: [null, [Validators.required]],
                    zipCode: [null, [Validators.required]],
                    email: [null],
                    company: [null],
                    stateName: [null, [Validators.required]],
                    firstName: [null],
                    lastName: [null],
                    phone: [null, [Validators.required]],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                }),
            }),
            deliveryInformation: this.fb.group({
                deliveryAddress: this.fb.group({
                    address1: [null, [Validators.required]],
                    address2: [null],
                    city: [null, [Validators.required]],
                    zipCode: [null, [Validators.required]],
                    email: [null],
                    company: [null],
                    stateName: [null, [Validators.required]],
                    firstName: [null],
                    lastName: [null],
                    phone: [null, [Validators.required]],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                }),
                earliestDeliveryTime: [null, [Validators.required, Validators.pattern(/^([1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)?$/i)]],
                latestDeliveryTime: [null, [Validators.required, Validators.pattern(/^([1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)?$/i)]],
                specialInstructions: [null],
            }),
            ownerInformation: this.fb.group({
                title: [null],
                position: [null],
                name: [null],
                ssn: [null],
                address: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            officeInformation: this.fb.group({
                title: [null],
                position: [null],
                name: [null],
                ssn: [null],
                address: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            financialInformation: this.fb.group({
                bankName: [null],
                bankRoutingNumber: [null],
                accountNumber: [null],
                bankAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            tradeReferences: this.fb.group({
                vendorName: [null],
                vendorAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            tradeReferences1: this.fb.group({
                vendorName: [null],
                vendorAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            tradeReferences2: this.fb.group({
                vendorName: [null],
                vendorAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            })
        });

        this.states$ = this.store.select(selectStates, { countryId: 1 });
        this.store.dispatch(new LoadStates({ countryId: 1 }));

        this.store.dispatch(new CreditApplication({ siteId: this.locationId }));
        this.store.select(selectCreditRequestApp).pipe(
            takeUntil(this.ngUnsubscribe),
            filter(creditReqApp => creditReqApp !== undefined),
        ).subscribe((creditReqApp) => {
            if (creditReqApp) {
                this.formCreditRA.patchValue(creditReqApp);
            }
        });
    }

    ngOnChanges() {
        this.site$ = this.store.select(selectSitesList).pipe(
            map(site => site.filter(s => s.id === this.locationId))
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    get formDeliveryInfo() { return (this.formCreditRA.controls.deliveryInformation as FormGroup).controls; }
    get businessTypeEnum() { return Object.keys(BusinessType); }
    get titleTypeEnum() { return Object.keys(TitleType); }

    onSubmit() {
        if (this.formCreditRA.invalid) {
            return;
        }

        this.store.dispatch(new AddCreditApplication({
            siteId: this.locationId,
            creditApplication: this.formCreditRA.value
        }));
    }

}
