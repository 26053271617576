import { Action } from '@ngrx/store';
import { CategoryModel } from '../../core/models/product/category.model';
import { ProductCatalogFilter } from 'src/app/core/models/product/product-catalog.filter';
import { ProductDetailsModel } from 'src/app/core/models/product/product-details.model';
import { ProductSettingsModel } from 'src/app/core/models/product/product-settings.model';
import { SaveProductCatalogModel } from 'src/app/core/models/product/save-product.model';
import { BaseCatalogModel } from 'src/app/core/models/product/base-catalog.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { CategoryOptionModel } from 'src/app/core/models/product/category-option.model';
import { ProductSaveRequestModel } from 'src/app/core/models/product/product-save-request.model';
import { ConvertedPriceModel } from 'src/app/core/models/product/converted-price.model';
import { ProductUploadModel } from 'src/app/core/models/product/product-upload.model';
import { ProductUploadResponseModel } from 'src/app/core/models/product/upload-response.model';
import { SaveUploadModel } from 'src/app/core/models/product/save-upload.model';
import { ProductPriceLevelModel } from 'src/app/core/models/product/product-price-level.model';


export enum ProductActionTypes {
    LoadCategories = '[Product] Load Categories',
    LoadCategoriesSuccess = '[Product] Load Categories Success',
    LoadProducts = '[Product] Load Products',
    LoadProductsSuccess = '[Product] Load Products Success',
    SaveProductSettings = '[Product] Save Product Settings',
    SaveProductSettingsSuccess = '[Product] Save Product Settings Success',
    SaveProduct = '[Product] Save product',
    SaveProductSuccess = '[Product] Save product Success',
    LoadProductDetails = '[Product] Load Price Levels For Product',
    LoadProductDetailsSuccess = '[Product] Load Price Levels For Product Success',
    SavePriceLevelsForProduct = '[Product] Save Price Levels For Product',
    SavePriceLevelsForProductSuccess = '[Product] Save Price Levels For Product Success',
    ResetPrice = '[Product] Reset Price',
    ResetPriceSuccess = '[Product] Reset Price Success',
    LoadBaseCatalogs = '[Product] Load Base Catalogs',
    LoadBaseCatalogsSuccess = '[Product] Load Base Catalogs Success',
    LoadCategoryOptions = '[Product] Load Category Options',
    LoadCategoryOptionsSuccess = '[Product] Load Category Options Success',
    LoadUomOptions = '[Product] Load Uom Options',
    LoadUomOptionsSuccess = '[Product] Load Uom Options Success',
    SaveProductCatalog = '[Product] Save Product Catalog',
    SaveProductCatalogSuccess = '[Product] Save Product Catalog Success',
    LoadConvertedPrice = '[Product] Load Converted Price',
    LoadConvertedPriceSuccess = '[Product] Load Converted Price Success',
    LoadRelatedUom = '[Product] Load Related Uom',
    LoadRelatedUomSuccess = '[Product] Load Related Uom Success',
    UploadPrices = '[Product] Upload Product Prices',
    UploadPricesSuccess = '[Product] Upload Product Prices Success',
    UploadPricesFinish = '[Product] Upload Product Prices Finish',
    UploadPricesSuccessFinish = '[Product] Upload Product Prices Finish Success',
    LoadPriceLevels = '[Product] Load Price Level Without Base',
    LoadPriceLevelsSuccess = '[Product] Load Price Level Without Base Success',
    ExportProducts = '[Product] Export Products',
    ExportProductsSuccess = '[Product] Export Products Success'
}

export class LoadCategories implements Action {
    readonly type = ProductActionTypes.LoadCategories;

    constructor() { }
}

export class LoadCategoriesSuccess implements Action {
    readonly type = ProductActionTypes.LoadCategoriesSuccess;

    constructor(public payload: Array<CategoryModel>) { }
}

export class LoadProducts implements Action {
    readonly type = ProductActionTypes.LoadProducts;

    constructor(public payload: ProductCatalogFilter) { }
}

export class LoadProductsSuccess implements Action {
    readonly type = ProductActionTypes.LoadProductsSuccess;

    constructor(public payload: ProductDetailsModel) { }
}

export class SaveProductSettings implements Action {
    readonly type = ProductActionTypes.SaveProductSettings;

    constructor(public payload: ProductSettingsModel) { }
}

export class SaveProductSettingsSuccess implements Action {
    readonly type = ProductActionTypes.SaveProductSettingsSuccess;

    constructor(public payload: number) { }
}

export class SaveProduct implements Action {
    readonly type = ProductActionTypes.SaveProduct;

    constructor(public payload: SaveProductCatalogModel) { }
}

export class SaveProductSuccess implements Action {
    readonly type = ProductActionTypes.SaveProductSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class LoadProductDetails implements Action {
    readonly type = ProductActionTypes.LoadProductDetails;

    constructor(public payload: number) { }
}

export class LoadProductDetailsSuccess implements Action {
    readonly type = ProductActionTypes.LoadProductDetailsSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class SavePriceLevelsForProduct implements Action {
    readonly type = ProductActionTypes.SavePriceLevelsForProduct;

    constructor(public payload: ProductCatalogModel) { }
}

export class SavePriceLevelsForProductSuccess implements Action {
    readonly type = ProductActionTypes.SavePriceLevelsForProductSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class LoadBaseCatalogs implements Action {
    readonly type = ProductActionTypes.LoadBaseCatalogs;
}

export class LoadBaseCatalogsSuccess implements Action {
    readonly type = ProductActionTypes.LoadBaseCatalogsSuccess;

    constructor(public payload: Array<BaseCatalogModel>) { }
}

export class LoadCategoryOptions implements Action {
    readonly type = ProductActionTypes.LoadCategoryOptions;
}

export class LoadCategoryOptionsSuccess implements Action {
    readonly type = ProductActionTypes.LoadCategoryOptionsSuccess;

    constructor(public payload: Array<CategoryOptionModel>) { }
}

export class LoadUomOptions implements Action {
    readonly type = ProductActionTypes.LoadUomOptions;
}

export class LoadUomOptionsSuccess implements Action {
    readonly type = ProductActionTypes.LoadUomOptionsSuccess;

    constructor(public payload: Array<UnitOfMeasureModel>) { }
}

export class SaveProductCatalog implements Action {
    readonly type = ProductActionTypes.SaveProductCatalog;

    constructor(public payload: ProductSaveRequestModel) { }
}

export class SaveProductCatalogSuccess implements Action {
    readonly type = ProductActionTypes.SaveProductCatalogSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class LoadConvertedPrice implements Action {
    readonly type = ProductActionTypes.LoadConvertedPrice;

    constructor(public payload: Array<ConvertedPriceModel>) { }
}

export class LoadConvertedPriceSuccess implements Action {
    readonly type = ProductActionTypes.LoadConvertedPriceSuccess;

    constructor(public payload: Array<ConvertedPriceModel>) { }
}

export class LoadRelatedUom implements Action {
    readonly type = ProductActionTypes.LoadRelatedUom;

    constructor(public payload: number) { }
}

export class LoadRelatedUomSuccess implements Action {
    readonly type = ProductActionTypes.LoadRelatedUomSuccess;

    constructor(public payload: Array<UnitOfMeasureModel>) { }
}

export class UploadPrices implements Action {
    readonly type = ProductActionTypes.UploadPrices;

    constructor(public payload: ProductUploadModel) { }
}

export class UploadPricesSuccess implements Action {
    readonly type = ProductActionTypes.UploadPricesSuccess;

    constructor(public payload: ProductUploadResponseModel) { }
}

export class UploadPricesFinish implements Action {
    readonly type = ProductActionTypes.UploadPricesFinish;

    constructor(public payload: SaveUploadModel) { }
}

export class UploadPricesSuccessFinish implements Action {
    readonly type = ProductActionTypes.UploadPricesSuccessFinish;
}

export class LoadPriceLevels implements Action {
    readonly type = ProductActionTypes.LoadPriceLevels;
}

export class LoadPriceLevelsSuccess implements Action {
    readonly type = ProductActionTypes.LoadPriceLevelsSuccess;

    constructor(public payload: Array<ProductPriceLevelModel>) { }
}

export class ExportProducts implements Action {
    readonly type = ProductActionTypes.ExportProducts;

    constructor(public payload: ProductCatalogFilter) { }
}

export class ExportProductsSuccess implements Action {
    readonly type = ProductActionTypes.ExportProductsSuccess;

    constructor(public payload: any) { }
}

export type ProductActions =
    | LoadCategories
    | LoadCategoriesSuccess
    | LoadProducts
    | LoadProductsSuccess
    | SaveProductSettings
    | SaveProductSettingsSuccess
    | SaveProduct
    | SaveProductSuccess
    | LoadProductDetails
    | LoadProductDetailsSuccess
    | SavePriceLevelsForProduct
    | SavePriceLevelsForProductSuccess
    | LoadBaseCatalogs
    | LoadBaseCatalogsSuccess
    | LoadCategoryOptions
    | LoadCategoryOptionsSuccess
    | LoadUomOptions
    | LoadUomOptionsSuccess
    | SaveProductCatalog
    | SaveProductCatalogSuccess
    | LoadConvertedPrice
    | LoadConvertedPriceSuccess
    | LoadRelatedUom
    | LoadRelatedUomSuccess
    | UploadPrices
    | UploadPricesSuccess
    | UploadPricesFinish
    | UploadPricesSuccessFinish
    | LoadPriceLevels
    | LoadPriceLevelsSuccess
    | ExportProducts
    | ExportProductsSuccess;

