import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddPaymentMethodModalComponent } from '../add-payment-method-modal/add-payment-method-modal.component';

@Component({
  selector: 'app-add-payment-methods',
  templateUrl: './add-payment-methods.component.html',
  styleUrls: ['./add-payment-methods.component.scss']
})
export class AddPaymentMethodsComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  onAddNew() {
    this.dialog.open(AddPaymentMethodModalComponent, {
      id: 'add-payment-method'
    });
  }

}
