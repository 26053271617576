import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Observable, combineLatest } from 'rxjs';
import { SetUserActive } from '../../actions/user.actions';
import { selectUsers } from '../../selectors/user.selector';
import { UserAccountType } from 'src/app/core/enums/user-account-type.enum';
import { map } from 'rxjs/operators';
import { getCurrentUserID } from '../../selectors/auth.selector';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  @Input() userId: number;

  users$: Observable<any>;
  
  constructor(
    private store: Store<AppState>) { }

  ngOnInit() { }

  ngOnChanges() {
    this.users$ = combineLatest(
      this.store.select(selectUsers),
      this.store.select(getCurrentUserID)
    ).pipe(
      map(([users, currentUserId]) => users.filter(u => u.userId === this.userId)
        .map(u => ({...u, isCurrentUser: u.userId === currentUserId})
      ))
    );
  }

  get userTypeEnum() { return UserAccountType; }
  
  onSetActive($event: MatSlideToggleChange, user) {
    this.store.dispatch(new SetUserActive({
      userId: user.userId,
      isActive: $event.checked
    }));

    user.isActive = $event.checked;
  }
}
