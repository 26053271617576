import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from 'src/app/core/enums/order-status.enum';

@Pipe({
  name: 'removeCanceledStatus'
})
export class RemoveCanceledStatusPipe implements PipeTransform {

  transform(values: any, ...args: any[]): any {
    if (!values) {
      return [];
    }

    return values.filter(v => v.id !== OrderStatus.CANCELED);
  }

}
