import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { IconsModule } from './icons/icons.module';
import { LocationSelectionComponent } from './components/location-selection/location-selection.component';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NotifierModule } from 'angular-notifier';
import { ContainsFilterPipe } from './pipes/contains-filter/contains-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageWithSidebarComponent } from './components/page-with-sidebar/page-with-sidebar.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SafeUrlPipe } from './pipes/safeUrl/safe-url.pipe';
import { DateFilterTypeSelectionComponent } from './components/date-filter-type-selection/date-filter-type-selection.component';

import { ChartsModule } from 'ng2-charts';
import { ReportsListsComponent } from './components/reports-lists/reports-lists.component';
import { RouterModule } from '@angular/router';
import { TransactionInfoComponent } from './components/transaction-info/transaction-info.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ShowItemsComponent } from './components/show-items/show-items.component';
import { TagsFilterComponent } from './components/tags-filter/tags-filter.component';
import { CategoriesFilterComponent } from './components/categories-filter/categories-filter.component';
import { VendorsFilterComponent } from './components/vendors-filter/vendors-filter.component';
import { GeneralFilterPipe } from './pipes/general-filter/general-filter.pipe';
import { ProductVendorsComponent } from './components/product-vendors/product-vendors.component';
import { ProductQuantityComponent } from './components/product-quantity/product-quantity.component';
import { SortSelectionComponent } from './components/sort-selection/sort-selection.component';
import { IconBankComponent } from './icons/icon-bank/icon-bank.component';
import { EditProductNameComponent } from './components/edit-product-name/edit-product-name.component';
import { TagsFilterModalComponent } from './components/tags-filter-modal/tags-filter-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { TagsComponent } from './components/tags/tags.component';
import { MatNativeDateModule } from '@angular/material/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DatepickerFilterComponent } from './components/datepicker-filter/datepicker-filter.component';
import { SortbyFilterComponent } from './components/sortby-filter/sortby-filter.component';
import { StatusFilterComponent } from './components/status-filter/status-filter.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RemoveCanceledStatusPipe } from './pipes/remove-canceled-status/remove-canceled-status.pipe';
import { ProductRelatedComponent } from './components/product-related/product-related.component';
import { ProductPriceChartComponent } from './components/product-price-chart/product-price-chart.component';
import { ProductPurchaseChartComponent } from './components/product-purchase-chart/product-purchase-chart.component';
import { AddProductComponent } from './components/add-product/add-product.component';
import { CountdownModule } from 'ngx-countdown';
import { VendorCategoryFilterComponent } from './components/vendor-category-filter/vendor-category-filter.component';
import { AddCreditCardComponent } from './components/add-credit-card/add-credit-card.component';
import { ManagePaymentMethodsComponent } from './components/manage-payment-methods/manage-payment-methods.component';
import { AddPaymentMethodsComponent } from './components/add-payment-methods/add-payment-methods.component';
import { AddPaymentMethodModalComponent } from './components/add-payment-method-modal/add-payment-method-modal.component';
import { CaptureTransactionModalComponent } from './components/capture-transaction-modal/capture-transaction-modal.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { EditSiteNameComponent } from './components/edit-site-name/edit-site-name.component';
import { AddLocationComponent } from './components/add-location/add-location.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { RequestPriceComponent } from './components/request-price/request-price.component';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { UserNotificationsComponent } from './components/user-notifications/user-notifications.component';
import { RatingComponent } from './components/rating/rating.component';
import { AddRatingComponent } from './components/add-rating/add-rating.component';
import { RatingDetailsComponent } from './components/rating-details/rating-details.component';
import { VendorHoverDirective } from './directives/vendor-hover.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { ChatComponent } from './components/chat/chat.component';
import { InboxComponent } from './components/inbox/inbox.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CreditRequestApplicationComponent } from './components/credit-request-application/credit-request-application.component';
import { TipsModalComponent } from './components/tips-modal/tips-modal.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProductGroupComponent } from '../modules/order-guide/components/product-group/product-group.component';
import { NewPriceLevelComponent } from '../modules/order-guide/components/new-price-level/new-price-level.component';
import { AddEditProductComponent } from '../modules/order-guide/components/add-new-product/add-edit-product.component';
import { UploadProductsComponent } from '../modules/order-guide/components/upload-products/upload-products.component';
import { CustomerStatusFilterComponent } from './components/customer-status-filter/customer-status-filter.component';
import { CustomerPriceLevelComponent } from './components/customer-price-level/customer-price-level.component';
import { DatepickerRangeFilterComponent } from './components/datepicker-range-filter/datepicker-range-filter.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { LeadsApplicationComponent } from './components/leads-application/leads-application.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CustomerLeadsStatusFilterComponent } from './components/customer-leads-status-filter/customer-leads-status-filter.component';
import { LeadsDetailsComponent } from './components/leads-details/leads-details.component';
import { ResendInviteComponent } from './components/resend-invite/resend-invite.component';
import { SettingsOrderMinimumComponent } from './components/settings-order-minimum/settings-order-minimum.component';
import { SettingsOrderWeightComponent } from './components/settings-order-weight/settings-order-weight.component';
import { PriceLevelListComponent } from '../modules/order-guide/components/price-level-list/price-level-list.component';
import { LeadsRejectModalComponent } from './components/leads-reject-modal/leads-reject-modal.component';
import { LeadsAcceptModalComponent } from './components/leads-accept-modal/leads-accept-modal.component';
import { ProductPriceRequestComponent } from './components/product-price-request/product-price-request.component';
import {
    EditCompanyDetailsComponent
} from '../modules/account/components/company-details/components/edit-company-details/edit-company-details.component';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { CustomersFilterComponent } from './components/customers-filter/customers-filter.component';
import { IsGuestDirective } from './directives/is-guest.directive';
import { CompleteAccountComponent } from '../modules/create-account/complete-account/complete-account.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { MatAutocompleteModule } from '@angular/material';
import { AddEditCouponComponent } from '../modules/cupons/add-edit-coupon/add-edit-coupon.component';
import { HasAddonDirective } from './directives/has-addon.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [CartSummaryComponent, LocationSelectionComponent,
        ContainsFilterPipe, PageWithSidebarComponent, SafeUrlPipe, DateFilterTypeSelectionComponent,
        ReportsListsComponent, TransactionInfoComponent, GlobalSearchComponent, PaginationComponent,
        ShowItemsComponent, TagsFilterComponent, CategoriesFilterComponent, VendorsFilterComponent, GeneralFilterPipe,
        ProductVendorsComponent, ProductQuantityComponent, SortSelectionComponent,
        EditProductNameComponent,
        TagsFilterModalComponent,
        OrderByPipe,
        TagsComponent,
        ConfirmationModalComponent,
        DatepickerFilterComponent,
        SortbyFilterComponent,
        StatusFilterComponent,
        OrderStatusComponent,
        RemoveCanceledStatusPipe,
        ProductRelatedComponent,
        ProductPriceChartComponent,
        ProductPurchaseChartComponent,
        AddProductComponent,
        VendorCategoryFilterComponent,
        AddCreditCardComponent,
        ManagePaymentMethodsComponent,
        AddPaymentMethodsComponent,
        AddPaymentMethodModalComponent,
        CaptureTransactionModalComponent,
        EditSiteNameComponent,
        AddLocationComponent,
        UserDetailsComponent,
        AddUserComponent,
        EditUserComponent,
        RequestPriceComponent,
        PaymentModalComponent,
        UserNotificationsComponent,
        AddRatingComponent,
        RatingComponent,
        RatingDetailsComponent,
        VendorHoverDirective,
        HasPermissionDirective,
        ChatComponent,
        InboxComponent,
        CreditRequestApplicationComponent,
        TipsModalComponent,
        ChangePasswordComponent,
        ProductGroupComponent,
        NewPriceLevelComponent,
        AddEditProductComponent,
        UploadProductsComponent,
        CustomerStatusFilterComponent,
        CustomerPriceLevelComponent,
        DatepickerRangeFilterComponent,
        CustomerDetailsComponent,
        LeadsApplicationComponent,
        CustomerLeadsStatusFilterComponent,
        LeadsDetailsComponent,
        ResendInviteComponent,
        SettingsOrderMinimumComponent,
        SettingsOrderWeightComponent,
        PriceLevelListComponent,
        LeadsRejectModalComponent,
        LeadsAcceptModalComponent,
        ProductPriceRequestComponent,
        EditCompanyDetailsComponent,
        EditAddressComponent,
        CustomersFilterComponent,
        IsGuestDirective,
        CompleteAccountComponent,
        CouponComponent,
        AddEditCouponComponent,
        HasAddonDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatRadioModule,
        MatSlideToggleModule,
        IconsModule,
        NgSlimScrollModule,
        NgxDaterangepickerMd.forRoot({
            separator: ' to '
        }),
        NotifierModule.withConfig({
            position: {
                horizontal: { position: 'middle' },
                vertical: { position: 'top', distance: 100 }
            }
        }),
        PerfectScrollbarModule,
        ChartsModule,
        MatDialogModule,
        MatNativeDateModule,
        FormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        LazyLoadImageModule.forRoot({}),
        CountdownModule,
        NgxCurrencyModule,
        NgxMaskModule.forRoot(),
        NgxDropzoneModule
    ],
    exports: [
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSelectModule,
        MatTooltipModule,
        MatRadioModule,
        MatSlideToggleModule,
        IconsModule,
        CartSummaryComponent,
        LocationSelectionComponent,
        NgSlimScrollModule,
        NotifierModule,
        PageWithSidebarComponent,
        NgxDaterangepickerMd,
        PerfectScrollbarModule,
        SafeUrlPipe,
        DateFilterTypeSelectionComponent,
        ChartsModule,
        ReportsListsComponent,
        TransactionInfoComponent,
        GlobalSearchComponent,
        PaginationComponent,
        ShowItemsComponent,
        TagsFilterComponent,
        CategoriesFilterComponent,
        VendorsFilterComponent,
        GeneralFilterPipe,
        ProductVendorsComponent,
        ProductQuantityComponent,
        TagsFilterModalComponent,
        SortSelectionComponent,
        EditProductNameComponent,
        OrderByPipe,
        TagsComponent,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ConfirmationModalComponent,
        DatepickerFilterComponent,
        SortbyFilterComponent,
        StatusFilterComponent,
        OrderStatusComponent,
        LazyLoadImageModule,
        RemoveCanceledStatusPipe,
        ProductRelatedComponent,
        ProductPriceChartComponent,
        ProductPurchaseChartComponent,
        AddProductComponent,
        CountdownModule,
        VendorCategoryFilterComponent,
        AddCreditCardComponent,
        ManagePaymentMethodsComponent,
        AddPaymentMethodsComponent,
        AddPaymentMethodModalComponent,
        EditSiteNameComponent,
        AddLocationComponent,
        UserDetailsComponent,
        AddUserComponent,
        EditUserComponent,
        RequestPriceComponent,
        PaymentModalComponent,
        UserNotificationsComponent,
        AddRatingComponent,
        RatingComponent,
        RatingDetailsComponent,
        VendorHoverDirective,
        HasPermissionDirective,
        ChatComponent,
        InboxComponent,
        NgxMaskModule,
        CreditRequestApplicationComponent,
        TipsModalComponent,
        ChangePasswordComponent,
        ProductGroupComponent,
        NewPriceLevelComponent,
        AddEditProductComponent,
        UploadProductsComponent,
        CustomerStatusFilterComponent,
        CustomerPriceLevelComponent,
        DatepickerRangeFilterComponent,
        CustomerDetailsComponent,
        LeadsApplicationComponent,
        CustomerLeadsStatusFilterComponent,
        LeadsDetailsComponent,
        ResendInviteComponent,
        SettingsOrderMinimumComponent,
        SettingsOrderWeightComponent,
        PriceLevelListComponent,
        LeadsRejectModalComponent,
        LeadsAcceptModalComponent,
        ContainsFilterPipe,
        ProductPriceRequestComponent,
        EditCompanyDetailsComponent,
        EditAddressComponent,
        CustomersFilterComponent,
        IsGuestDirective,
        CompleteAccountComponent,
        CouponComponent,
        AddEditCouponComponent,
        HasAddonDirective
    ],
    providers: [
        {
            provide: SLIMSCROLL_DEFAULTS,
            useValue: {
                alwaysVisible: false,
                barWidth: 5,
                barBackground: '#D8D8D8',
                barBorderRadius: 10,
                gridWidth: 0,
                gridBackground: 'none'
            }
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        SafeUrlPipe,
        OrderByPipe,
        DatePipe,
        ContainsFilterPipe
    ],
    entryComponents: [
        TagsFilterModalComponent,
        ConfirmationModalComponent,
        AddCreditCardComponent,
        AddPaymentMethodModalComponent,
        CaptureTransactionModalComponent,
        AddUserComponent,
        EditUserComponent,
        PaymentModalComponent,
        UserNotificationsComponent,
        AddRatingComponent,
        RatingComponent,
        TipsModalComponent,
        LeadsRejectModalComponent,
        LeadsAcceptModalComponent
    ]
})
export class SharedModule { }
