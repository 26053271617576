import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { PAYMENT_METHOD } from '../../enums/payment-method.enum';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe
    ) { }

    getNewOrdersCount() {
        return this.http.get(`${environment.api.content}/Order/new_orders_info`);
    }

    @Cacheable()
    getOrders(params: any) {
        return this.http.post(`${environment.api.content}/Order/search`, {
            page: params.page,
            sites: params.vendors.length > 0 ? params.vendors : params.buyers,
            statuses: params.statuses,
            orderBy: params.sortby,
            startDate: this.datePipe.transform(params.start, 'MM/dd/yyyy'),
            endDate: this.datePipe.transform(params.end, 'MM/dd/yyyy'),
            keyword: params.keyword
        });
    }

    @Cacheable()
    getStatuses() {
        return this.http.get(`${environment.api.content}/Order/statuses`);
    }

    reorder(orderId) {
        return this.http.post(`${environment.api.content}/Order/reorder`, {
            orders: [orderId]
        });
    }

    changeStatus(orderId, statusId) {
        return this.http.post(`${environment.api.content}/Order/${orderId}/update_status`, {
            id: statusId
        });
    }

    save(params: {
        vendorId: number,
        popNotifications?: boolean,
        paymentMethod: PAYMENT_METHOD,
        paymentProfileId?: string,
        creditCard?: any,
        address?: any,
        save?: boolean,
        fbxOrderToken?: string,
        profileId?: number
    }) {
        return this.http.post(`${environment.api.content}/Order/save/${params.vendorId}`, {
            paymentMethodId: params.paymentMethod,
            profileGuid: params.paymentProfileId,
            saveProfile: params.save,
            profile: params.paymentProfileId ? undefined : {
                address: params.address,
                creditCard: params.creditCard
            },
            fbxOrderToken: params.fbxOrderToken,
            profileId: params.profileId
        });
    }

    getOrderDetails(orderId: number) {
        return this.http.get(`${environment.api.content}/Order/${orderId}/load_details`);
    }

    sendBuyerCopy(orderId: number) {
        return this.http.post(`${environment.api.content}/Order/${orderId}/send_buyer_copy`, {});
    }

    sendVendorCopy(orderId: number) {
        return this.http.post(`${environment.api.content}/Order/${orderId}/send_vendor_copy`, {});
    }

    printOrder(orderId: number) {
        return this.http.get(`${environment.api.content}/Order/${orderId}/print`);
    }

    editOrder(orderId: number, cancelPreviousVersion: boolean = false, checkPreviousVersion: boolean = true) {
        return this.http.post(`${environment.api.content}/OrderEdit/${orderId}/load_edit_order`, {
            cancelPreviousVersion,
            checkPreviousVersion
        });
    }

    cancelEdit(orderId: number) {
        return this.http.delete(`${environment.api.content}/OrderEdit/${orderId}/clear_edit`);
    }

    saveEdit(orderId: number, shoppingCartId: number) {
        return this.http.post(`${environment.api.content}/OrderEdit/${orderId}/update_order/${shoppingCartId}`, null);
    }

    changeQuantity(params: any) {
        return this.http.post(`${environment.api.content}/OrderEdit/${params.shoppingCartId}/add_to_cart`, {
            orderId: params.orderId,
            productId: params.productId,
            quantity: params.quantity
        });
    }

    loadEditProducts(orderId: number, vendorId: number, keyword: string) {
        return this.http.post(`${environment.api.content}/OrderEdit/${orderId}/load_addable_products`, {
            keyword,
            id: vendorId
        });
    }

    loadEditOrder(orderId: number, shoppingCartId: number) {
        return this.http.get(`${environment.api.content}/OrderEdit/${orderId}/load_order/${shoppingCartId}`);
    }

    saveEditNotes(orderId: number, notes: string) {
        return this.http.post(`${environment.api.content}/OrderEdit/${orderId}/save_notes`, {
            notes
        });
    }

    saveEditDeliveryDates(params: {
        orderId: number, shoppingCartId: number, deliveryDate: any,
        deliveryFromTime: any, deliveryToTime: any
    }) {
        return this.http.post(`${environment.api.content}/OrderEdit/${params.orderId}/save_delivery_info/${params.shoppingCartId}`, {
            deliveryDate: this.datePipe.transform(params.deliveryDate, 'MM/dd/yyyy'),
            fromTime: this.datePipe.transform(params.deliveryFromTime, 'HH:mm'),
            toTime: this.datePipe.transform(params.deliveryToTime, 'HH:mm'),
        });
    }

}
