import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, Renderer2, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadVendors, SetVendorFavorite } from '../../actions/order-guide.action';
import { Observable, Subject } from 'rxjs';
import { selectVendors, selectVendorsCutoff } from '../../selectors/order-guide.selector';
import { map, tap, takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { PlatformType } from 'src/environments/platform-type.enum';
import { environment } from 'src/environments/environment';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';
import { ChatEntityType } from 'src/app/core/enums/chat-entity-type.enum';
import { ChatDisplayStatusAction } from '../../actions/chat.actions';

@Component({
    selector: 'app-vendors-filter',
    templateUrl: './vendors-filter.component.html',
    styleUrls: ['./vendors-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VendorsFilterComponent implements OnInit, OnChanges, OnDestroy {

    DEFAULT_VENDORS_COUNT = 6;

    EntityType = EntityType;

    vendors$: Observable<any[]>;
    cutoffDetails: Map<number, any> = new Map<number, any>();

    showItemsCount = this.DEFAULT_VENDORS_COUNT;

    showMore = false;

    PlatformType = PlatformType;
    currentPlatform = environment.platformId;
    chatDisplayStatus: ChatDisplayStatus = new ChatDisplayStatus();

    private vendors = [];
    private enteredButton = false;
    private isMatMenuOpen = false;
    private prevButtonTrigger;

    @Input() selectedVendors: number[] = [];
    @Output() change = new EventEmitter<any>();

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private ren: Renderer2
    ) { }

    ngOnInit() {
        this.store.dispatch(new LoadVendors());
    }

    ngOnChanges() {
        this.vendors$ = this.store.select(selectVendors)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((vendors) => vendors.map(v => ({
                    ...v,
                    isSelected: this.selectedVendors.includes(v.customerSiteID)
                }))),
                tap(vendors => this.vendors = vendors)
            );

        this.store.select(selectVendorsCutoff).pipe(
            takeUntil(this.ngUnsubscribe),
            tap(((details: any[]) => {
                this.cutoffDetails = new Map<number, any>();
                details.forEach(d => this.cutoffDetails.set(d.vendorId, d));
            }))
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    trackById(vendor) {
        return vendor.id;
    }

    onSelect($event: MatCheckboxChange, vendor) {
        if ($event.checked) {
            this.selectedVendors.push(vendor.customerSiteID);
        } else {
            const idx = this.selectedVendors.findIndex(v => v === vendor.customerSiteID);
            this.selectedVendors.splice(idx, 1);
        }

        this.change.emit(this.selectedVendors);
    }

    onNameClick($event, vendor) {
        $event.preventDefault();
        this.change.emit([vendor.customerSiteID]);
    }

    onSelectAll() {
        this.change.emit([]);
    }

    onSelectFavorite() {
        this.change.emit(this.vendors.filter(v => v.isFavorite).map(v => v.customerSiteID));
    }

    onSetVendorFavorite($event, vendor) {
        $event.stopPropagation();

        this.store.dispatch(new SetVendorFavorite({
            vendorId: vendor.customerSiteID,
            isFavorite: !vendor.isFavorite
        }));
    }

    toggleMore() {
        this.showMore = !this.showMore;
        this.showItemsCount = this.showMore ? this.vendors.length : this.DEFAULT_VENDORS_COUNT;
    }

    menuenter() {
        this.isMatMenuOpen = true;
    }

    menuLeave(trigger, button) {
        setTimeout(() => {
            if (!this.enteredButton) {
                this.isMatMenuOpen = false;
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            } else {
                this.isMatMenuOpen = false;
            }
        }, 80);
    }

    buttonEnter(trigger) {
        setTimeout(() => {
            if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
                this.prevButtonTrigger.closeMenu();
                this.prevButtonTrigger = trigger;
                this.isMatMenuOpen = false;
                trigger.openMenu();
            } else if (!this.isMatMenuOpen) {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
                trigger.openMenu();
            } else {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
            }
        });
    }

    buttonLeave(trigger, button) {
        setTimeout(() => {
            if (this.enteredButton && !this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            }
            if (!this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            } else {
                this.enteredButton = false;
            }
        }, 100);
    }

    openConversationWithCustomer(vendor) {
        this.chatDisplayStatus.showChat = true;
        this.chatDisplayStatus.showOnlyInbox = false;
        this.chatDisplayStatus.entityTypeId = ChatEntityType.General;
        this.chatDisplayStatus.buyerVendor = vendor;
        this.chatDisplayStatus.entity = vendor.name;
        this.chatDisplayStatus.chatClosed = false;

        this.store.dispatch(new ChatDisplayStatusAction(this.chatDisplayStatus));
    }

}
