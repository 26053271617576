import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { LoadEditOrderProducts } from '../../actions/order.actions';
import { selectEditProducts } from '../../selectors/order.selector';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  @Input() orderId: number; 
  
  @Input() vendorId: number; 

  @Output() selected = new EventEmitter();
  
  public products$: Observable<any[]>;

  form: FormGroup;

  @ViewChild('addProductMenuTrigger', { static: true }) menuTrigger: any;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.products$ = this.store.select(selectEditProducts).pipe(
      tap((products) => {
        if (products && products.length) {
          this.menuTrigger.openMenu();
        } else {
          this.menuTrigger.closeMenu();
        }
      })
    );

    this.form = this.fb.group({
      keyword: [null, []]
    });
    
    this.form.controls.keyword.valueChanges
    .pipe(
      debounceTime(500),
    ).subscribe((keyword) => {      
      if (keyword.length < 2) {
        return;
      }

      this.store.dispatch(new LoadEditOrderProducts({
        orderId: this.orderId,
        vendorId: this.vendorId,
        keyword
      }));
    });

    
  }

  onSelectProduct(product) {
    this.selected.emit(product.id);
  }

}
