import {
    Component, OnInit, Input, ChangeDetectionStrategy, EventEmitter, Output,
    OnChanges, SimpleChanges, OnDestroy
} from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { SetProductVendorFavorite, SelectVendor, SetVendorFavorite } from '../../actions/order-guide.action';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { selectVendorsCutoff } from '../../selectors/order-guide.selector';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-product-vendors',
    templateUrl: './product-vendors.component.html',
    styleUrls: ['./product-vendors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class ProductVendorsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() vendors: any;
    @Input() useMode: number = 1; // 1 | 2
    @Input() inlineMode = false;
    @Input() vendorsShowCount = 3;
    @Output() moved = new EventEmitter<any>();
    @Output() selected = new EventEmitter<any>();

    EntityType = EntityType;

    isOpen = false;
    currentPlatform = environment.platformId;
    PlatformType = PlatformType;
    cutoffDetails: Map<number, any> = new Map<number, any>();

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        if (this.useMode === 2) {
            this.vendorsShowCount = 1;
        }

        this.store.select(selectVendorsCutoff).pipe(
            takeUntil(this.ngUnsubscribe),
            tap(((details: any[]) => {
                this.cutoffDetails = new Map<number, any>();
                details.forEach(d => this.cutoffDetails.set(d.vendorId, d));
            }))
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.vendors && this.vendorsShowCount === 1) {
            const selectedVendor = this.vendors.find(v => v.isSelected);
            this.vendors = [
                selectedVendor,
                ...this.vendors.filter(v => v.id !== selectedVendor.id)
            ];
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    selectVendor(vendor) {
        if (vendor.isSelected) {
            return;
        }

        switch (this.useMode) {
            case 1:
                this.selected.emit(vendor);
                break;
            case 2:
                this.moved.emit({
                    currentVendor: this.vendors.find(v => v.isSelected),
                    newVendor: vendor
                });
                break;
        }
    }

    setProductVendorFavorite($event, vendor) {
        if (this.useMode !== 1) {
            return false;
        }

        $event.stopPropagation();
        this.store.dispatch(new SetProductVendorFavorite({
            productId: vendor.productId,
            vendorId: vendor.id,
            isFavorite: !vendor.isFavorite
        }));
    }

    onSetVendorFavorite($event, vendor) {
        if (this.useMode !== 1) {
            return false;
        }

        $event.stopPropagation();

        this.store.dispatch(new SetVendorFavorite({
            vendorId: vendor.id,
            isFavorite: !vendor.isFavoriteForSearch
        }));
    }
}
