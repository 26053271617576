import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';

@Component({
    selector: 'app-edit-company-details',
    templateUrl: './edit-company-details.component.html',
    styleUrls: ['./edit-company-details.component.scss']
})
export class EditCompanyDetailsComponent implements OnInit {

    @Input() company: any;
    formEditDetails: FormGroup;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>) { }

    ngOnInit() {
        this.formEditDetails = this.fb.group({
            name: [null, [Validators.required]],
            email: [null, [Validators.required]],
            phone: [null, [Validators.required]],
            website: [null],
        });

        this.formEditDetails.patchValue(this.company);
    }

    onSubmit() { }

    cancel() {
        this.formEditDetails.reset();
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }
}
