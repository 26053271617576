import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { MatDialog } from '@angular/material/dialog';
import { LoadSettingsOrderWeight, DeleteOrderWeight, AddOrderWeight } from '../../actions/settings.actions';
import { settingsOrderWeight } from '../../selectors/settings.selector';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { NotifierService } from 'angular-notifier';

@Component({
    selector: 'app-settings-order-weight',
    templateUrl: './settings-order-weight.component.html',
    styleUrls: ['./settings-order-weight.component.scss']
})
export class SettingsOrderWeightComponent implements OnInit {

    orderWeightForm: FormGroup;
    addOrderWeightForm: FormGroup;

    showAddWeightSettings = false;
    countOrderWeight = 0;
    settingsOrderWeight$: Observable<any>;

    currentSiteId: number;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        private dialog: MatDialog,
        private notifierSvc: NotifierService) {
    }

    ngOnInit() {
        this.orderWeightForm = this.fb.group({
            minWeight: ['', [Validators.required]],
        });
        this.addOrderWeightForm = this.fb.group({
            minWeight: ['', [Validators.required]],
        });

        this.store.dispatch(new LoadSettingsOrderWeight());
        this.settingsOrderWeight$ = this.store.select(settingsOrderWeight);
        this.settingsOrderWeight$.subscribe(result => { this.countOrderWeight = result.length });
    }

    //add new entry
    onAddMinSettings() {
        this.addOrderWeightForm.reset();
        if (this.countOrderWeight >= 1) {
            this.notifierSvc.show({
                type: 'error',
                message: 'You cannot add more than 1 site settings!'
            });

        } else {
            this.showAddWeightSettings = true;
        }
    }

    onAddRevert() {
        this.addOrderWeightForm.reset();
        this.showAddWeightSettings = !this.showAddWeightSettings;
    }

    onAddSave() {
        if (!this.addOrderWeightForm.value.minWeight) {
            this.showAddWeightSettings = false;
            return;
        }

        const orderWeight = {
            id: -1,
            minWeight: this.addOrderWeightForm.value.minWeight
        };

        this.store.dispatch(new AddOrderWeight({ orderWeight }))
        this.showAddWeightSettings = false;
    }

    //edit settings
    onEditOrderMin(settings) {
        settings.showEditActions = !settings.showEditActions;
    }

    onSave(settings) {
        if (!this.orderWeightForm.value.minWeight) {
            settings.showEditActions = false;
            return;
        }

        const orderWeight = {
            id: settings.id,
            minWeight: this.orderWeightForm.value.minWeight
        };

        this.store.dispatch(new AddOrderWeight({ orderWeight }))
        this.orderWeightForm.reset();
        settings.showEditActions = false;
    }

    onRevert(settings) {
        this.orderWeightForm.reset();
        settings.showEditActions = !settings.showEditActions;
    }

    onDelete(orderWeightId: number) {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to delete this order minimum ?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store.dispatch(new DeleteOrderWeight({ orderWeightId }));
            }
        });
    }
}
