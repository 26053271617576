import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, exhaustMap, map, catchError } from 'rxjs/operators';
import { OrderService } from 'src/app/core/services/order/order.service';
import { LoadNewOrdersCount, OrderActionTypes, NewOrdersCountLoaded, LoadOrders, OrdersLoaded, LoadStatuses, StatusesLoaded, Reorder, ChangeOrderStatus, ChangeOrderStatusSuccess, LoadOrder, OrderLoaded, SendBuyerCopy, SendVendorCopy, PrintOrder, EditOrder, CancelEditOrder, EditOrderLoaded, SaveEditOrder, EditOrderChangeQuantity, EditOrderChangeQuantitySuccess, LoadEditOrderProducts, LoadEditOrderProductsSuccess, SaveOrderEditNotes, SaveOrderEditNotesSuccess, UpdateOrderEdit, SaveOrderEditDelivery, SaveOrderEditDeliverySuccess, SaveOrderEditDeliveryError, ReAuthorizeFundboxTransaction, UpdateOrderDetailsLoad, TriggerReAuthorizeFundboxOrder } from '../actions/order.actions';
import { Router } from '@angular/router';
import { LoadCartSummary } from '../actions/cart.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { NotifierService } from 'angular-notifier';
import { throwError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { PaymentsService } from 'src/app/core/services/payments/payments.service';

@Injectable()
export class OrderEffects {

    @Effect()
    loadNewOrdersCount$ = this.actions$.pipe(
        ofType<LoadNewOrdersCount>(OrderActionTypes.LoadNewOrdersCountAction),
        exhaustMap(() => this.orderSvc.getNewOrdersCount().pipe(
            map((response: any) => new NewOrdersCountLoaded({ count: response.count }))
        ))
    );

    @Effect()
    orders$ = this.actions$.pipe(
        ofType<LoadOrders>(OrderActionTypes.LoadOrdersAction),
        exhaustMap((action) => this.orderSvc.getOrders(action.payload).pipe(
            map(orders => new OrdersLoaded ({ orders }))
        ))
    );

    @Effect()
    statuses$ = this.actions$.pipe(
        ofType<LoadStatuses>(OrderActionTypes.LoadStatusAction),
        exhaustMap(() => this.orderSvc.getStatuses()),
        map((response: any) => new StatusesLoaded({
            statuses: response
        }))
    );

    
    @Effect({ dispatch: false })
    reorder$ = this.actions$.pipe(
        ofType<Reorder>(OrderActionTypes.Reorder),
        exhaustMap((action) => this.orderSvc.reorder(action.payload.orderId)),
        map((response: any) => {
            this.router.navigate(['/cart']);
            this.store.dispatch(new LoadCartSummary());
        })
    );

    @Effect()
    changeStatus$ = this.actions$.pipe(
        ofType<ChangeOrderStatus>(OrderActionTypes.ChangeOrderStatus),
        exhaustMap((action) => this.orderSvc.changeStatus(action.payload.orderId, action.payload.statusId).pipe(
            map((r: any) => ({
                availableStatuses: r,
                payload: action.payload
            }))
        )),
        map((response: any) => new ChangeOrderStatusSuccess({
            availableStatuses: response.availableStatuses,
            statusId: response.payload.statusId,
            orderId: response.payload.orderId,
        }))
    );

    @Effect()
    changeStatusSuccess$ = this.actions$.pipe(
        ofType<ChangeOrderStatusSuccess>(OrderActionTypes.ChangeOrderStatusSuccess),
        map(() => new LoadNewOrdersCount())
    );
    

    @Effect()
    order$ = this.actions$.pipe(
        ofType<LoadOrder>(OrderActionTypes.LoadOrderAction),
        exhaustMap((action) => this.orderSvc.getOrderDetails((action.payload.order))),
        map(order => new OrderLoaded({ orderDetails: order }))
    );

    @Effect({ dispatch: false })
    sendBuyerCopy$ = this.actions$.pipe(
        ofType<SendBuyerCopy>(OrderActionTypes.SendBuyerCopyAction),
        exhaustMap((action) => this.orderSvc.sendBuyerCopy(action.payload.orderId)),
        map((response: any) => {
            this.notifier.notify('success', 'Email sent with success');
        }),
        catchError(err => {
            this.notifier.notify('error', 'Something went wrong. Please try again.');
            return err;
        }),
    );

    @Effect({ dispatch: false })
    sendVendorCopy$ = this.actions$.pipe(
        ofType<SendVendorCopy>(OrderActionTypes.SendVendorCopyAction),
        exhaustMap((action) => this.orderSvc.sendVendorCopy(action.payload.orderId)),
        map((response: any) => {
            this.notifier.notify('success', 'Email sent with success');
        }),
        catchError(err => {
            this.notifier.notify('error', 'Something went wrong. Please try again.');
            return err;
        }),
    );

    @Effect({ dispatch: false })
    printOrder$ =  this.actions$.pipe(
        ofType<PrintOrder>(OrderActionTypes.PrintOrder),
        exhaustMap((action) => this.orderSvc.printOrder(action.payload.orderId)),
        map((response: any) => {
            const myWindow = window.open('', "PrintWindow", "width=800,height=600,top=200,left=200,toolbars=no,scrollbars=no,status=no,resizable=no");
            myWindow.document.write(response.template);
            myWindow.document.close();
            myWindow.focus();
            myWindow.print();
        })
    );

    @Effect({ dispatch: false })
    editOrder$ = this.actions$.pipe(
        ofType<EditOrder>(OrderActionTypes.EditOrder),
        exhaustMap((action) => this.orderSvc.editOrder(action.payload.orderId, action.payload.cancelPreviousVersion, action.payload.checkPreviousVersion)
            .pipe(
                map((response) => this.store.dispatch(new EditOrderLoaded({ editDetails: response }))),
                catchError((errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 620) {
                        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
                            data: {
                              message: "Do you want to keep the previous version of this order ?"
                            },
                            disableClose: true
                          });
                      
                          confirmRef.afterClosed().subscribe(isConfirmed => {
                            const { orderId } = action.payload;
                            if (isConfirmed) {
                              this.store.dispatch(new EditOrder({
                                orderId,
                                checkPreviousVersion: false
                              }));
                            } else {
                              this.store.dispatch(new EditOrder({
                                orderId,
                                cancelPreviousVersion: true,
                                checkPreviousVersion: false
                              }));
                            }
                          });
                    }
                    return of();
                })
            )
        
        )
    );
    
    @Effect()
    cancelEdit$ = this.actions$.pipe(
        ofType<CancelEditOrder>(OrderActionTypes.CancelEditOrder),
        exhaustMap((action) => this.orderSvc.cancelEdit(action.payload.orderId).pipe(map(() => action.payload.orderId))),
        map((order: number) => new LoadOrder({
            order
        }))
    );

    @Effect()
    saveEdit$ = this.actions$.pipe(
        ofType<SaveEditOrder>(OrderActionTypes.SaveEditOrder),
        exhaustMap((action) => this.orderSvc.saveEdit(action.payload.orderId, action.payload.shoppingCartId)
            .pipe(
                map(() => new LoadOrder({
                    order: action.payload.orderId
                })),
                tap(() => {
                    this.notifier.show({
                        type: 'success',
                        message: 'Order updated with success'
                    });
                })
            ))
    );

    @Effect()
    changeQuantityEdit$ = this.actions$.pipe(
        ofType<EditOrderChangeQuantity>(OrderActionTypes.EditOrderChangeQuantity),
        exhaustMap((action) => this.orderSvc.changeQuantity(action.payload).pipe(map(() => action.payload))),
        map((payload: any) => new EditOrderChangeQuantitySuccess(payload)),
    );

    @Effect()
    changeQuantityEditSuccess$ = this.actions$.pipe(
        ofType<EditOrderChangeQuantitySuccess>(OrderActionTypes.EditOrderChangeQuantitySuccess),
        exhaustMap((action) => this.orderSvc.loadEditOrder(action.payload.orderId, action.payload.shoppingCartId)),
        map((orderDetails: any) => new UpdateOrderEdit({
            orderDetails
        })),
    );

    @Effect()
    loadEditProducts$ = this.actions$.pipe(
        ofType<LoadEditOrderProducts>(OrderActionTypes.LoadEditOrderProducts),
        exhaustMap((action) => this.orderSvc.loadEditProducts(action.payload.orderId, action.payload.vendorId, action.payload.keyword)),
        map((response: any) => new LoadEditOrderProductsSuccess({
            products: response.products
        })),
    );

    @Effect()
    saveNotesEditOrder$ = this.actions$.pipe(
        ofType<SaveOrderEditNotes>(OrderActionTypes.SaveOrderEditNotes),
        exhaustMap((action) => this.orderSvc.saveEditNotes(action.payload.orderId, action.payload.notes).pipe(map(() => action.payload))),
        map((payload: any) => new SaveOrderEditNotesSuccess(payload)),
    );

    @Effect()
    saveDeliveryDatesEdit$ = this.actions$.pipe(
        ofType<SaveOrderEditDelivery>(OrderActionTypes.SaveOrderEditDelivery),
        exhaustMap((action) => this.orderSvc.saveEditDeliveryDates(action.payload).pipe(
            map(() => action.payload),
            map(payload => new SaveOrderEditDeliverySuccess({
                orderId: payload.orderId,
                shoppingCartId: payload.shoppingCartId,
                deliveryDate: this.datePipe.transform(payload.deliveryDate, 'MM/dd/yyyy'),
                deliveryFromTime: this.datePipe.transform(payload.deliveryFromTime, 'HH:mm'),
                deliveryToTime: this.datePipe.transform(payload.deliveryToTime, 'HH:mm'),
            })),
            catchError((e: HttpErrorResponse) => {
                return of(new SaveOrderEditDeliveryError({
                    orderId: action.payload.orderId,
                    shoppingCartId: action.payload.shoppingCartId,
                    errorMessage: e.error.message
                }));
            })
        ))
    );

    @Effect()
    reAuthorizeFbx$ = this.actions$.pipe(
        ofType<ReAuthorizeFundboxTransaction>(OrderActionTypes.ReAuthorizeFundboxTransaction),
        exhaustMap((action) => this.paymentService.reAuthorizeFundboxTransaction(action.payload.fbxOrderToken, action.payload.transactionId, action.payload.orderId)
            .pipe(
                map(() => new LoadOrder({ order: action.payload.orderId })),
                catchError(() => of(new UpdateOrderDetailsLoad({ isLoading: false })))
            )
        ),
    );

    @Effect({ dispatch: false })
    triggerReAuthorizeFbx$ = this.actions$.pipe(
        ofType<TriggerReAuthorizeFundboxOrder>(OrderActionTypes.TriggerReAuthorizeFundboxOrder),
        exhaustMap((action) => this.router.navigate(['orders', action.payload.orderId])
        )
    );
    
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>,
        private orderSvc: OrderService,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private paymentService: PaymentsService
    ) { }

}