import { Action } from '@ngrx/store';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';

export enum CartActionTypes {
    LoadCartSummaryAction = '[Global] Load Cart Action',
    CartSummaryLoadedAction = '[Global] Loaded Cart Summary Action',
    LoadCart = '[Cart] LoadCart',
    CartLoaded = '[Cart] CartLoaded',
    DeleteCartByVendorId = '[Cart] DeleteCartByVendorId',
    DeleteCartByVendorIdSuccess = '[Cart] DeleteCartByVendorIdSuccess',
    ExpandCartOrder = '[Cart] ExpandCartOrder',
    SaveNotes = '[Cart] SaveNotes',
    SaveNotesSuccess = '[Cart] SaveNotesSuccess',
    UpdateShoppingCartAfterAddSuccess = '[Cart] UpdateShoppingCartAfterAddSuccess',
    MoveProduct = '[Cart] MoveProduct',
    SubmitOrder = '[Cart] SubmitOrder',
    SubmitOrderSuccess = '[Cart] SubmitOrderSuccess',
    SubmitOrderError = '[Cart] SubmitOrderError',
    SaveDelivery = '[Cart] SaveDelivery',
    SaveDeliverySuccess = '[Cart] SaveDeliverySuccess',
    SaveDeliveryError = '[Cart] SaveDeliveryError',
    LoadCartTotals = '[Cart] LoadCartTotals',
    LoadCartTotalsSuccess = '[Cart] LoadCartTotalsSuccess',
    CheckTipsAvailability = '[Cart] CheckTipsAvailability',
    CheckTipsAvailabilitySuccess = '[Cart] CheckTipsAvailabilitySuccess',
    SaveTips = '[Cart] SaveTips',
    SaveTipsSuccess = '[Cart] SaveTipsSuccess',
}

export class LoadCartSummary implements Action {
    readonly type = CartActionTypes.LoadCartSummaryAction;
}

export class CartSummaryLoaded implements Action {
    readonly type = CartActionTypes.CartSummaryLoadedAction

    constructor(public payload: { summary: any }) { }
}

export class LoadCart implements Action {
    readonly type = CartActionTypes.LoadCart;
}

export class CartLoaded implements Action {
    readonly type = CartActionTypes.CartLoaded;

    constructor(public payload: { cartDetails: any }) { }
}

export class DeleteCartByVendorId implements Action {
    readonly type = CartActionTypes.DeleteCartByVendorId;

    constructor(public payload: { vendorId: number }) { }
}

export class DeleteCartByVendorIdSuccess implements Action {
    readonly type = CartActionTypes.DeleteCartByVendorIdSuccess;

    constructor(public payload: { vendorId: number }) { }
}

export class ExpandCartOrder implements Action {
    readonly type = CartActionTypes.ExpandCartOrder;

    constructor(public payload: { vendorId: number, isExpanded: boolean }) { }
}

export class SaveNotes implements Action {
    readonly type = CartActionTypes.SaveNotes;

    constructor(public payload: { vendorId: number, notes: string }) { }
}

export class SaveNotesSuccess implements Action {
    readonly type = CartActionTypes.SaveNotesSuccess;

    constructor(public payload: { vendorId: number, notes: string }) { }
}

export class UpdateShoppingCartAfterAddSuccess implements Action {
    readonly type = CartActionTypes.UpdateShoppingCartAfterAddSuccess;

    constructor(public payload: { productId: number, vendorId: number, quantity: number, productIdFromGroup?: number }) { }
}

export class MoveProduct implements Action {
    readonly type = CartActionTypes.MoveProduct;

    constructor(public payload: { productId: number, toVendorId: number, fromVendorId: number, quantity: number }) { }
}

export class SubmitOrder implements Action {
    readonly type = CartActionTypes.SubmitOrder;

    constructor(public payload: {
        vendorId: number,
        popNotifications?: boolean,
        paymentMethod: PAYMENT_METHOD,
        paymentProfileId?: string,
        creditCard?: any,
        address?: any,
        save?: boolean,
        modalInstance?: any,
        fbxOrderToken?: string,
        profileId?: number
    }) { }
}

export class SubmitOrderSuccess implements Action {
    readonly type = CartActionTypes.SubmitOrderSuccess;

    constructor(public payload: { response: any }) { }
}

export class SubmitOrderError implements Action {
    readonly type = CartActionTypes.SubmitOrderError;

    constructor(public payload: { vendorId: number, message: string }) { }
}

export class SaveDelivery implements Action {
    readonly type = CartActionTypes.SaveDelivery;

    constructor(public payload: { vendorId: number, deliveryDate: Date, deliveryFromTime: Date, deliveryToTime: Date, vendor?: any, showPaymentModal?: boolean }) { }
}

export class SaveDeliverySuccess implements Action {
    readonly type = CartActionTypes.SaveDeliverySuccess;

    constructor(public payload: { vendorId: number, deliveryDate: string, deliveryFromTime: string, deliveryToTime: string }) { }
}

export class SaveDeliveryError implements Action {
    readonly type = CartActionTypes.SaveDeliveryError;

    constructor(public payload: { vendorId: number, errorMessage: string, deliveryDate: string, deliveryFromTime: string, deliveryToTime: string }) { }
}

export class LoadCartTotals implements Action {
    readonly type = CartActionTypes.LoadCartTotals;

    constructor(public payload: { vendorId: number }) { }
}

export class LoadCartTotalsSuccess implements Action {
    readonly type = CartActionTypes.LoadCartTotalsSuccess;

    constructor(public payload: { cartVendorTotals: any }) { }
}

export class CheckTipsAvailability implements Action {
    readonly type = CartActionTypes.CheckTipsAvailability;

    constructor(public payload: { vendorId: number, vendor?: any }) { }
}

export class CheckTipsAvailabilitySuccess implements Action {
    readonly type = CartActionTypes.CheckTipsAvailabilitySuccess;

    constructor(public payload: { vendorId: number, isEnabled: boolean }) { }
}

export class SaveTips implements Action {
    readonly type = CartActionTypes.SaveTips;

    constructor(public payload: { vendorId: number, amount: number, vendor?: any }) { }
}

export class SaveTipsSuccess implements Action {
    readonly type = CartActionTypes.SaveTipsSuccess;
}

export type CartActions = LoadCartSummary
    | CartSummaryLoaded
    | LoadCart
    | CartLoaded
    | DeleteCartByVendorId
    | DeleteCartByVendorIdSuccess
    | ExpandCartOrder
    | SaveNotes
    | SaveNotesSuccess
    | UpdateShoppingCartAfterAddSuccess
    | MoveProduct
    | SubmitOrder
    | SubmitOrderSuccess
    | SubmitOrderError
    | SaveDelivery
    | SaveDeliverySuccess
    | SaveDeliveryError
    | LoadCartTotals
    | LoadCartTotalsSuccess
    | CheckTipsAvailability
    | CheckTipsAvailabilitySuccess
    | SaveTips
    | SaveTipsSuccess;