import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-datepicker-filter',
  templateUrl: './datepicker-filter.component.html',
  styleUrls: ['./datepicker-filter.component.scss']
})
export class DatepickerFilterComponent implements OnInit, OnChanges {

  @Input() start: any;
  @Input() end: any;

  @Output() changed = new EventEmitter<any>();

  dateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) { 
    this.dateForm = this.fb.group({
      start: [this.start, []],
      end: [this.end, []]
    });

    this.dateForm.valueChanges.subscribe((values) => {
      if (!values.start || !values.end) {
        return;
      }

      this.changed.emit({
        start: this.datePipe.transform(values.start, 'MM-dd-yyyy'),
        end: this.datePipe.transform(values.end, 'MM-dd-yyyy')
      });
    });
  }

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dateForm.patchValue({
      start: this.start,
      end: this.end
    });
  }
  
}
