import { SiteActions, SiteActionTypes } from '../actions/site.actions';
import { randomColor } from '../constants/global.contants';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ADDRESS_TYPE } from 'src/app/core/enums/address-type.enum';

export interface SiteState {
    sites: any[];
    loadingSites: boolean;
    sitesList: any[];
    site: any;
    newSite: any;
    creditRequestApp: any;
    companyDetails: any;
    loadingCompanyDetails: boolean;
    loadingEditAddress: boolean;
}

export const initialState: SiteState = {
    sites: [],
    loadingSites: false,
    sitesList: [],
    site: [],
    newSite: [],
    creditRequestApp: [],
    companyDetails: [],
    loadingCompanyDetails: false,
    loadingEditAddress: false
};

export function siteReducer(
    state = initialState,
    action: SiteActions): SiteState {
    let newSites = null;
    let headerSite = null;
    let headerSiteUpdate = null;
    switch (action.type) {
        case SiteActionTypes.SitesLoadedAction:
            return {
                ...state,
                sites: action.payload.sites
            };
        case SiteActionTypes.LoadSitesListAction:
            return {
                ...state,
                loadingSites: true
            };
        case SiteActionTypes.SitesListLoadedAction:
            return {
                ...state,
                sitesList: action.payload.sitesList.map(s => ({
                    ...s,
                    randomColor: randomColor()
                })),
                loadingSites: false
            };
        case SiteActionTypes.EditSiteAction:
            return {
                ...state,
            };
        case SiteActionTypes.EditSiteSuccess:
            newSites = state.sitesList;

            const siteToUpdate = newSites.find(t => t.id === action.payload.sitesList.id);
            if (siteToUpdate) {
                siteToUpdate.active = action.payload.sitesList.active;
                siteToUpdate.loading = false;
            }

            let headerSites = state.sites;
            if (!action.payload.sitesList.active) {
                headerSites = headerSites.filter(s => s.id !== action.payload.sitesList.id);
            }

            return {
                ...state,
                sites: headerSites,
                sitesList: [...newSites]
            };
        case SiteActionTypes.DeleteSiteSuccess:
            const newSite = state.sitesList;
            const tagToDeleteIdx = newSite.findIndex(t => t.id === action.payload.siteId);
            newSite.splice(tagToDeleteIdx, 1);

            return {
                ...state,
                sitesList: [...newSite]
            };

        case SiteActionTypes.ChangeSiteName:
            newSites = state.sitesList;
            headerSite = state.sites;

            const siteUpdate = newSites.find(t => t.id === action.payload.id);
            if (siteUpdate) {
                siteUpdate.name = action.payload.name;
                siteUpdate.isNameEditing = false;
            }

            headerSiteUpdate = headerSite.find(t => t.id === action.payload.id);
            if (headerSiteUpdate) {
                headerSiteUpdate.name = action.payload.name;
            }

            return {
                ...state,
                sitesList: [...newSites]
            };
        case SiteActionTypes.AddSiteAction:
            return {
                ...state,
                site: action.payload.site
            };
        case SiteActionTypes.AddSiteSuccess:
            return {
                ...state,
                sitesList: [...state.sitesList, action.payload.newSite]
            };
        case SiteActionTypes.UpdateSiteSuccess:
            newSites = state.sitesList;
            headerSite = state.sites;

            const siteIdx = newSites.findIndex(t => t.id === action.payload.site.id);
            if (siteIdx >= 0) {
                newSites[siteIdx] = { ...newSites[siteIdx], ...action.payload.site };
            }

            headerSiteUpdate = headerSite.find(t => t.id === action.payload.site.id);
            if (headerSiteUpdate) {
                headerSiteUpdate.name = action.payload.site.name;
            }

            return {
                ...state,
                sitesList: [...newSites]
            };
        case SiteActionTypes.ChangeSiteAvailability:
            newSites = state.sites;

            if (action.payload.isAssigned) {
                newSites.push(action.payload.site);
            } else {
                const tagToDeleteIdx = newSites.findIndex(t => t.id === action.payload.siteId);
                newSites.splice(tagToDeleteIdx, 1);
            }

            return {
                ...state,
                sites: [...newSites]
            };
        case SiteActionTypes.CreditApplicationSuccess:
            return {
                ...state,
                creditRequestApp: action.payload.creditApplicationDetails
            };
        case SiteActionTypes.SavePriceLevelSuccess:
            return {
                ...state,
                loadingSites: false,
                sites: [...state.sites, action.payload]
            };
        case SiteActionTypes.SavePriceLevel:
            return {
                ...state,
                loadingSites: true
            };

        case SiteActionTypes.UpdatePriceLevel:
            return {
                ...state,
                loadingSites: true
            };
        case SiteActionTypes.UpdatePriceLevelSuccess:
            const siteIndex = state.sites.findIndex(site => site.id === action.payload.id);
            state.sites.splice(siteIndex, 1, action.payload);

            return {
                ...state,
                loadingSites: false,
                sites: [...state.sites]
            };
        case SiteActionTypes.LoadCompanyDetails:
            return {
                ...state,
                loadingCompanyDetails: true
            };
        case SiteActionTypes.CompanyDetailsLoaded:
            return {
                ...state,
                loadingCompanyDetails: false,
                companyDetails: action.payload.companyDetails
            };
        case SiteActionTypes.UpdateAddress:
            return {
                ...state,
                loadingEditAddress: true
            };
        case SiteActionTypes.UpdateAddressSuccess:
            return {
                ...state,
                loadingEditAddress: false,
                companyDetails: {
                    ...state.companyDetails,
                    billingAddress: action.payload.addressType === ADDRESS_TYPE.BILLING ? action.payload.address : state.companyDetails.billingAddress,
                    shippingAddress: action.payload.addressType === ADDRESS_TYPE.SHIPPING ? action.payload.address : state.companyDetails.shippingAddress
                }
            };
        default:
            return state;
    }
}
