import { createSelector, select } from '@ngrx/store';

export const selectCartState = state => state.cart;

export const cartSummary = createSelector(
    selectCartState,
    cart => cart.summary
);

export const loadingCartSummary = createSelector(
    selectCartState,
    cart => cart.loadingSummary
);

export const selectCart = createSelector(
    selectCartState,
    cart => cart.cartDetails
);

export const selectLoadingCart = createSelector(
    selectCartState,
    cart => cart.loadingCart
);

export const selectClosePaymentModal = createSelector(
    selectCartState,
    cart => cart.closePaymentModal
);

export const getSavingTips = createSelector(
    selectCartState,
    cart => cart.savingTips
);
