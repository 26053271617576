import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SortOptions } from 'src/app/core/enums/sort-options.enum';

@Component({
  selector: 'app-sort-selection',
  templateUrl: './sort-selection.component.html',
  styleUrls: ['./sort-selection.component.scss']
})
export class SortSelectionComponent implements OnInit {

  showSortOptions = false;

  options = [
    { id: SortOptions.RELEVANCY, name : 'Relevance', isSelected: true },
    { id: SortOptions.AZ, name : 'A to Z', isSelected: false },
    { id: SortOptions.FAVORITES, name : 'Favorites', isSelected: false },
    { id: SortOptions.RECENT, name : 'Recent', isSelected: false }
  ];

  SortOptions = SortOptions;

  @Output() changed = new EventEmitter<SortOptions>();

  constructor() { }

  ngOnInit() {
  }
 
  sortOpened(isOpen: boolean) {
    this.showSortOptions = isOpen;
  }

  onClick(option) {
    this.options.forEach(o => {
      o.isSelected = o.id === option.id;
    });

    this.changed.emit(option.id);
  }

}
