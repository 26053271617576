import { InfobarActions, InfobarActionTypes } from '../actions/infobar.actions';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';

export interface InfobarState {
    isOpen: boolean;
    title?: string;
    componentsIds: InfobarComponents[];
    params: {
        [id: string]: any
    };
    transaction: any;
}

export const initialState: InfobarState = {
    isOpen: false,
    componentsIds: [],
    params: {},
    transaction: undefined
};

export function infobarReducer(state = initialState,
    action: InfobarActions): InfobarState {
    switch (action.type) {
        case InfobarActionTypes.ToggleInfobar:
            return {
                ...state,
                isOpen: action.payload.open,
                title: action.payload.title,
                componentsIds: action.payload.componentsIds,
                params: action.payload.params
            };
        case InfobarActionTypes.UpdateTransaction:
            return {
                ...state,
                transaction: action.payload.transaction
            };
        default:
            return state;
    }
}