import { Action } from '@ngrx/store';
import { PriceLevelModel } from 'src/app/core/models/product/price-level.model';
import { ADDRESS_TYPE } from 'src/app/core/enums/address-type.enum';

export enum SiteActionTypes {
    LoadSitesAction = '[Global] Load Sites',
    SitesLoadedAction = '[Global] Sites Loaded',
    LoadSitesListAction = '[Account] Load Sites',
    SitesListLoadedAction = '[Account] SitesList Loaded Success',
    EditSiteAction = '[Account] EditSite Action',
    EditSiteSuccess = '[Account] EditSite Success',
    DeleteSiteAction = '[Account] Delete Site Action ',
    DeleteSiteSuccess = '[Account] Delete Site Success',
    ChangeSiteName = '[Account] ChangeSiteName Action',
    ChangeSiteNameSuccess = '[Account] ChangeSiteName Success',
    AddSiteAction = '[Account] Add Site Action',
    AddSiteSuccess = '[Account] AddSite Success',
    UpdateSite = '[Account] UpdateSite',
    UpdateSiteSuccess = '[Account] UpdateSiteSuccess',
    ChangeSiteAvailability = '[Account] ChangeSiteAvailability',
    NavigateToAddSite = '[Global] NavigateToAddSite',
    LoadCreditApplication = '[Account] LoadCreditApplication Action',
    CreditApplicationSuccess = '[Account] CreditApplication Success',
    AddCreditApplication = '[Account] AddCreditApplication Action',
    AddCreditApplicationSuccess = '[Account] AddCreditApplication Success',
    SavePriceLevelSuccess = '[Account] Save Price Level Success',
    SavePriceLevel = '[Product] Save Price Level',
    UpdatePriceLevel = '[Product] Update Price Level',
    UpdatePriceLevelSuccess = '[Product] Update Price Level Success',
    LoadCompanyDetails = '[Account] Load Company Details',
    CompanyDetailsLoaded = '[Account] Company Details Loaded',
    UpdateAddress = '[Company details] UpdateAddress',
    UpdateAddressSuccess = '[Company details] UpdateAddressSuccess'
}

export class LoadSites implements Action {
    readonly type = SiteActionTypes.LoadSitesAction;
}

export class SitesLoaded implements Action {
    readonly type = SiteActionTypes.SitesLoadedAction;

    constructor(public payload: { sites: any[] }) { }
}
export class LoadSitesList implements Action {
    readonly type = SiteActionTypes.LoadSitesListAction;

    constructor(public payload: { keyword?: string } = {}) { }
}

export class SitesListLoaded implements Action {
    readonly type = SiteActionTypes.SitesListLoadedAction;

    constructor(public payload: { sitesList: any }) { }
}

export class EditSite implements Action {
    readonly type = SiteActionTypes.EditSiteAction;

    constructor(public payload: { id: any, active?: any, name?: any }) { }
}

export class EditSiteSuccess implements Action {
    readonly type = SiteActionTypes.EditSiteSuccess;

    constructor(public payload: { sitesList: any }) { }
}

export class DeleteSite implements Action {
    readonly type = SiteActionTypes.DeleteSiteAction;

    constructor(public payload: { siteId: any }) { }
}

export class DeleteSiteSuccess implements Action {
    readonly type = SiteActionTypes.DeleteSiteSuccess;

    constructor(public payload: { siteId: any }) { }
}

export class ChangeSiteName implements Action {
    readonly type = SiteActionTypes.ChangeSiteName;

    constructor(public payload: { id: number, name: string, currentName: string }) { }
}

export class ChangeSiteNameSuccess implements Action {
    readonly type = SiteActionTypes.ChangeSiteNameSuccess;

    constructor(public payload: { siteId: number, name: string }) { }
}

export class AddSite implements Action {
    readonly type = SiteActionTypes.AddSiteAction;

    constructor(public payload: { site: any }) { }
}

export class AddSiteSuccess implements Action {
    readonly type = SiteActionTypes.AddSiteSuccess;

    constructor(public payload: { newSite: any }) { }
}

export class UpdateSite implements Action {
    readonly type = SiteActionTypes.UpdateSite;

    constructor(public payload: { site: any }) { }
}

export class UpdateSiteSuccess implements Action {
    readonly type = SiteActionTypes.UpdateSiteSuccess;

    constructor(public payload: { site: any }) { }
}

export class ChangeSiteAvailability implements Action {
    readonly type = SiteActionTypes.ChangeSiteAvailability;

    constructor(public payload: { userId: number, siteId: number, isAssigned: boolean, site?: any }) { }
}

export class NavigateToAddSite implements Action {
    readonly type = SiteActionTypes.NavigateToAddSite;
}

export class CreditApplication implements Action {
    readonly type = SiteActionTypes.LoadCreditApplication;

    constructor(public payload: { siteId: number }) { }
}

export class CreditApplicationSuccess implements Action {
    readonly type = SiteActionTypes.CreditApplicationSuccess;

    constructor(public payload: { creditApplicationDetails: any[] }) { }
}

export class AddCreditApplication implements Action {
    readonly type = SiteActionTypes.AddCreditApplication;

    constructor(public payload: { siteId: number, creditApplication: any }) { }
}

export class AddCreditApplicationSuccess implements Action {
    readonly type = SiteActionTypes.AddCreditApplicationSuccess;

    constructor(public payload: { newCreditApplication: any }) { }
}

export class SavePriceLevelSuccess implements Action {
    readonly type = SiteActionTypes.SavePriceLevelSuccess;

    constructor(public payload: PriceLevelModel) { }
}

export class SavePriceLevel implements Action {
    readonly type = SiteActionTypes.SavePriceLevel;

    constructor(public payload: PriceLevelModel) { }
}

export class UpdatePriceLevel implements Action {
    readonly type = SiteActionTypes.UpdatePriceLevel;

    constructor(public payload: PriceLevelModel) { }
}

export class UpdatePriceLevelSuccess implements Action {
    readonly type = SiteActionTypes.UpdatePriceLevelSuccess;

    constructor(public payload: PriceLevelModel) { }
}

export class LoadCompanyDetails implements Action {
    readonly type = SiteActionTypes.LoadCompanyDetails;
}

export class CompanyDetailsLoaded implements Action {
    readonly type = SiteActionTypes.CompanyDetailsLoaded;

    constructor(public payload: { companyDetails: any }) { }
}

export class UpdateAddress implements Action {
    readonly type = SiteActionTypes.UpdateAddress;

    constructor(public payload: { address: any, addressType: ADDRESS_TYPE }) { }
}

export class UpdateAddressSuccess implements Action {
    readonly type = SiteActionTypes.UpdateAddressSuccess;

    constructor(public payload: { address: any, addressType: ADDRESS_TYPE }) { }
}

export type SiteActions = LoadSites
    | SitesLoaded
    | LoadSitesList
    | SitesListLoaded
    | EditSite
    | EditSiteSuccess
    | DeleteSite
    | DeleteSiteSuccess
    | ChangeSiteName
    | ChangeSiteNameSuccess
    | AddSite
    | AddSiteSuccess
    | UpdateSite
    | UpdateSiteSuccess
    | ChangeSiteAvailability
    | NavigateToAddSite
    | CreditApplication
    | CreditApplicationSuccess
    | AddCreditApplication
    | AddCreditApplicationSuccess
    | SavePriceLevelSuccess
    | SavePriceLevel
    | UpdatePriceLevel
    | UpdatePriceLevelSuccess
    | LoadCompanyDetails
    | CompanyDetailsLoaded
    | UpdateAddress
    | UpdateAddressSuccess;
