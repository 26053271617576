import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-orders',
  templateUrl: './icon-orders.component.html',
  styleUrls: ['./icon-orders.component.scss']
})
export class IconOrdersComponent implements OnInit {

  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
