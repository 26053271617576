
import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { UserService } from '../services/user/user.service';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoadUserDetails, Logout, LoadUserPermissions } from 'src/app/shared/actions/auth.actions';
import { AccountTypes } from '../enums/account-type.enum';
import { LoadBranding, LoadBrandingSuccess } from 'src/app/shared/actions/branding.actions';
import { BrandingService } from '../services/branding/branding.service';
import { BrandingModel } from '../models/branding/branding.model';
import { tap, switchMap, map, exhaustMap, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare let ga: Function;

@Injectable()
export class UserDetailsResolve implements Resolve<any> {
    constructor(
        private service: UserService,
        private store: Store<AppState>,
        private router: Router,
        private brandingSvc: BrandingService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        document.body.classList.add('loading', 'loading--large');

        return this.service.loadUserDetails()
            .pipe(
                switchMap((userDetails) => this.brandingSvc.getBranding(userDetails.defaultSiteId).pipe(map((brandingModel) => ({ brandingModel, userDetails })))),
                tap((params) => {
                    const { userDetails, brandingModel } = params;
                    if (userDetails && !userDetails.sites) {
                        userDetails.sites = [];
                    }

                    document.body.classList.remove('loading', 'loading--large');
                    if (userDetails && userDetails.sites && !userDetails.sites.length) {
                        this.store.dispatch(new Logout({
                            forceLogout: true,
                            message: 'Your account is suspended. Please contact support'
                        }));
                        return false;
                    } else {
                        this.store.dispatch(new LoadUserDetails({ userDetails }));
                        this.store.dispatch(new LoadUserPermissions(userDetails.userId));
                        this.store.dispatch(new LoadBrandingSuccess(brandingModel));

                        try {
                            ga('create', environment.GA_TRACKER_ID, 'auto', 'tracker', {
                                userId: userDetails.userId,
                                email: userDetails.email,
                                firstName: userDetails.firstName,
                                lastName: userDetails.lastName
                            });

                            ga('tracker.require', 'ecommerce');
                            console.log('Created ga tracker');
                        }
                        catch (ex) { }

                        return userDetails;
                    }
                }, () => {
                    document.body.classList.remove('loading', 'loading--large');
                    this.store.dispatch(new Logout());
                    return false;
                })
            );

        return this.service.loadUserDetails()
            .pipe(
                tap(userDetails => {
                    if (!(userDetails && userDetails.sites && !userDetails.sites.length)) {
                        this.store.dispatch(new LoadUserDetails({ userDetails }));
                        this.store.dispatch(new LoadUserPermissions(userDetails.userId));
                    }
                }),
                mergeMap((userDetails) => this.brandingSvc.getBranding()
                    .pipe(
                        map((brandingProfile: BrandingModel) => ({ userDetails, brandingProfile }))
                    )
                )
            ).subscribe((params: { brandingProfile: BrandingModel, userDetails: any }) => {
                document.body.classList.remove('loading', 'loading--large');
                const { userDetails, brandingProfile } = params;
                if (userDetails && userDetails.sites && !userDetails.sites.length) {
                    this.store.dispatch(new Logout({
                        forceLogout: true,
                        message: 'Your account is suspended. Please contact support'
                    }));
                    return false;
                }
                else {
                    return userDetails;
                }
            }, () => {
                document.body.classList.remove('loading', 'loading--large');
                this.store.dispatch(new Logout());
                return false;
            });

        // return this.service.loadUserDetails().subscribe((userDetails) => {
        //     document.body.classList.remove('loading', 'loading--large');
        //     if (userDetails && userDetails.sites && !userDetails.sites.length) {
        //         this.store.dispatch(new Logout({
        //             forceLogout: true,
        //             message: 'Your account is suspended. Please contact support'
        //         }));
        //         return false;
        //     } else {
        //         this.store.dispatch(new LoadUserDetails({ userDetails }));
        //         this.store.dispatch(new LoadUserPermissions(userDetails.userId));
        //         return userDetails;
        //     }
        // }, () => {
        //     document.body.classList.remove('loading', 'loading--large');
        //     this.store.dispatch(new Logout());
        //     return false;
        // });
    }
}