import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import { BrandingActionTypes, LoadBrandingSuccess, LoadPlatformConfigurationSuccess } from '../actions/branding.actions';
import { BrandingService } from 'src/app/core/services/branding/branding.service';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { PlatformConfigurationModel } from 'src/app/core/models/branding/platform-config.model';

@Injectable()
export class BrandingEffects {

    constructor(
        private actions$: Actions,
        private brandingService: BrandingService
    ) { }

    @Effect()
    loadBranding$: Observable<Action> = this.actions$.pipe(
        ofType(BrandingActionTypes.LoadBranding),
        mergeMap(() => this.brandingService.getBranding().pipe(
            map((data: BrandingModel) => new LoadBrandingSuccess(data))
        ))
    );

    @Effect({ dispatch: false })
    loadBrandingSuccess$ = this.actions$.pipe(
        ofType<LoadBrandingSuccess>(BrandingActionTypes.LoadBrandingSuccess),
        map((action) => {
            if (action.payload) {
                this.brandingService.storeVendorStoreKey(action.payload.customerAccountId, action.payload.vendorStoreSiteId);
            } else {
                this.brandingService.clearVendorStoreKey();
            }
        }),
    );
}
