import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe
    ) { }

    loadCartSummary() {
        return this.http.get(`${environment.api.content}/cart/summary`);
    }

    loadCart() {
        return this.http.get(`${environment.api.content}/cart`);
    }

    deleteCartByVendorId(vendorId: number) {
        return this.http.delete(`${environment.api.content}/cart/delete/${vendorId}`);
    }

    saveNotes(notes: string, vendorId: number) {
        return this.http.post(`${environment.api.content}/cart/save_notes`, {
            notes: notes.trim(),
            vendorId
        });
    }

    saveDelivery(deliveryDate: Date, fromTime: Date, toTime: Date, vendorId: number) {
        return this.http.post(`${environment.api.content}/cart/save_delivery_info`, {
            deliveryDate: this.datePipe.transform(deliveryDate, 'MM/dd/yyyy'),
            fromTime: this.datePipe.transform(fromTime, 'HH:mm'),
            toTime: this.datePipe.transform(toTime, 'HH:mm'),
            vendorId
        });
    }

    addToCart(params: any) {
        return this.http.post(`${environment.api.content}/cart/add_to_cart`, {
            productId: params.productIdFromGroup && params.productIdFromGroup > 0 ? params.productIdFromGroup : params.productId,
            vendorId: params.vendorId,
            quantity: params.quantity
        });
    }

    moveProduct(params: any) {
        return this.http.post(`${environment.api.content}/cart/move_product`, {
            productId: params.productId,
            fromVendorId: params.fromVendorId,
            toVendorId: params.toVendorId,
            quantity: params.quantity
        });
    }

    loadCartTotals(vendorId: number) {
        return this.http.get(`${environment.api.content}/cart/${vendorId}`);
    }

    @Cacheable()
    getCuttoff(vendors: number[]) {
        return this.http.post(`${environment.api.content}/cart/cutOffInfo`, {
            ids: vendors
        });
    }

    hasTipsEnabled(vendorId: number) {
        return this.http.get(`${environment.api.content}/cart/${vendorId}/has_tips_enabled`);
    }

    saveTips(vendorId: number, amount: number) {
        return this.http.post(`${environment.api.content}/cart/${vendorId}/save_tip`, {
            quantity: amount
        });
    }

}
