import { createSelector } from '@ngrx/store';

export const selectSettingsState = state => state.settings;

export const settingsOrderMin = createSelector(
    selectSettingsState,
    state => state.settingsOrderMin
);

export const settingsOrderWeight = createSelector(
    selectSettingsState,
    state => state.settingsOrderWeight
);