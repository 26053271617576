import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
    LoadSettingsOrderMin = '[Account Settings] Load Settings Order Minimum',
    SettingsOrderMinLoaded = '[Account Settings] Settings Order Minimum Loaded ',
    AddOrderMin = '[Account Settings] Add Order Minimum Action',
    AddOrderMinSuccess = '[Account Settings] Add Order Minimum Success ',
    DeleteOrderMin = '[Account Settings] Delete Order Minimum Action ',
    DeleteOrderMinSuccess = '[Account Settings] Delete Order Minimum Success ',
    LoadSettingsOrderWeight = '[Account Settings] Load Settings Order Weight',
    SettingsOrderWeightLoaded = '[Account Settings] Settings Order Weight Loaded ',
    AddOrderWeight = '[Account Settings] Add Order Weight Action',
    AddOrderWeightSuccess = '[Account Settings] Add Order Weight Success ',
    DeleteOrderWeight = '[Account Settings] Delete Order Weight Action ',
    DeleteOrderWeightSuccess = '[Account Settings] Delete Order Weight Success ',
}

export class LoadSettingsOrderMin implements Action {
    readonly type = SettingsActionTypes.LoadSettingsOrderMin;
}

export class SettingsOrderMinLoaded implements Action {
    readonly type = SettingsActionTypes.SettingsOrderMinLoaded;

    constructor(public payload: { settingsOrderMin: any }) { }
}

export class AddOrderMin implements Action {
    readonly type = SettingsActionTypes.AddOrderMin;

    constructor(public payload: { orderMin: any }) { }
}

export class AddOrderMinSuccess implements Action {
    readonly type = SettingsActionTypes.AddOrderMinSuccess;

    constructor(public payload: { newOrderMin: any }) { }
}

export class DeleteOrderMin implements Action {
    readonly type = SettingsActionTypes.DeleteOrderMin;

    constructor(public payload: { orderMinId: any }) { }
}

export class DeleteOrderMinSuccess implements Action {
    readonly type = SettingsActionTypes.DeleteOrderMinSuccess;

    constructor(public payload: { orderMinId: any }) { }
}

export class LoadSettingsOrderWeight implements Action {
    readonly type = SettingsActionTypes.LoadSettingsOrderWeight;
}

export class SettingsOrderWeightLoaded implements Action {
    readonly type = SettingsActionTypes.SettingsOrderWeightLoaded;

    constructor(public payload: { settingsOrderWeight: any }) { }
}

export class AddOrderWeight implements Action {
    readonly type = SettingsActionTypes.AddOrderWeight;

    constructor(public payload: { orderWeight: any }) { }
}

export class AddOrderWeightSuccess implements Action {
    readonly type = SettingsActionTypes.AddOrderWeightSuccess;

    constructor(public payload: { newOrderWeight: any }) { }
}

export class DeleteOrderWeight implements Action {
    readonly type = SettingsActionTypes.DeleteOrderWeight;

    constructor(public payload: { orderWeightId: any }) { }
}

export class DeleteOrderWeightSuccess implements Action {
    readonly type = SettingsActionTypes.DeleteOrderWeightSuccess;

    constructor(public payload: { orderWeightId: any }) { }
}


export type SettingsActions =
    LoadSettingsOrderMin
    | SettingsOrderMinLoaded
    | AddOrderMin
    | AddOrderMinSuccess
    | DeleteOrderMin
    | DeleteOrderMinSuccess
    | LoadSettingsOrderWeight
    | SettingsOrderWeightLoaded
    | AddOrderWeight
    | AddOrderWeightSuccess
    | DeleteOrderWeight
    | DeleteOrderWeightSuccess;
