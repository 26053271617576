import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { noop } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { Login } from 'src/app/shared/actions/auth.actions';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { MarketingService } from 'src/app/core/services/marketing/marketing.service';
import { getBranding, getPlatformConfiguration } from 'src/app/shared/selectors/branding.selector';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { PlatformRegistrationType } from 'src/app/core/models/branding/platform-registration.enum';
import { PlatformConfigurationModel } from 'src/app/core/models/branding/platform-config.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    loginForm: FormGroup;
    loading = false;
    errorMessages: string[] = [];
    loginBannerUrl: string;
    platformConfiguration: PlatformConfigurationModel;
    branding: BrandingModel;
    registrationType = PlatformRegistrationType;

    @ViewChild('iframe', { static: false }) iframe: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private notifier: NotifierService,
        private authenticationService: AuthenticationService,
        private store: Store<AppState>,
        private marketingSvc: MarketingService
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.store.pipe(select(getBranding)).subscribe((branding: BrandingModel) => {
            if (branding) {
                this.branding = branding;
                this.loginBannerUrl = branding.loginBannerUrl;
            }
        });

        this.store.pipe(select(getPlatformConfiguration)).subscribe((configuration: any) => {
            if (configuration) {
                this.platformConfiguration = configuration;
            }
        });
    }

    ngAfterViewInit() {
        this.marketingSvc.getLoginUrl().subscribe(response => {
            if (response.url && !this.loginBannerUrl) {
                if (response.url.toUpperCase().match(/\.(JPEG|JPG|PNG)$/) != null) {
                    this.loginBannerUrl = response.url;
                } else {
                    this.iframe.nativeElement.src = response.url;
                }
            }
        });
    }

    get form() { return this.loginForm.controls; }

    get logo() { return `${environment.assets.baseUrl}/logo.png`; }

    get createAccountLink() { return `${environment.createAccountLink}`; }

    onSubmit() {
        if (this.loginForm.invalid || this.loading) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.form.username.value, this.form.password.value)
            .pipe(
                tap((loginResponse: any) => {
                    this.loading = false;
                    this.store.dispatch(new Login({ loginResponse }));
                    this.router.navigate(['/home']);
                })
            )
            .subscribe(noop, (response: HttpErrorResponse) => {
                this.loading = false;
                this.notifier.notify('error', response.error.message);
            });
    }

}
