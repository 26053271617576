import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductState } from '../reducers/product.reducer';

const getProductState = createFeatureSelector<ProductState>('product');

export const getProductDetails = createSelector(
    getProductState,
    state => state.productDetails
);

export const getProductsLoading = createSelector(
    getProductState,
    state => state.loading
);

export const getProductCategories = createSelector(
    getProductState,
    state => state.categories
);

export const getProductCatalog = createSelector(
    getProductState,
    (state, props) => {
        if (state.productCatalog && state.productCatalog.id === props.productId) {
            return state.productCatalog;
        }
    }
);

export const getProductCatalogLoading = createSelector(
    getProductState,
    state => state.loadingProductCatalog
);

export const getBaseCatalogList = createSelector(
    getProductState,
    state => state.baseCatalogs
);

export const getCategoryOptions = createSelector(
    getProductState,
    state => state.categoryOptions
);

export const getUomOptions = createSelector(
    getProductState,
    state => state.uomOptions
);

export const getConvertedPrice = createSelector(
    getProductState,
    state => state.convertedPrice
);

export const getRelatedUom = createSelector(
    getProductState,
    state => state.relatedUom
);

export const getSavedProductRow = createSelector(
    getProductState,
    state => state.lastSavedProductId
);

export const getReloadProductsPage = createSelector(
    getProductState,
    state => state.reloadPage
);

export const getUploadSummary = createSelector(
    getProductState,
    state => state.uploadPricesSummary
);

export const getPriceLevels = createSelector(
    getProductState,
    state => state.priceLevels
);

export const getUploadLoading = createSelector(
    getProductState,
    state => state.uploadLoading
);
