export enum InfobarComponents {
    TRANSACTION = 'transaction',
    MANAGE_PAYMENTS = 'manage-payments',
    ADD_PAYMENTS = 'add-payments',
    SITE = 'site'
}

export enum InfobarLocationComponents {
    PAGE_NAME = 'location',
    CREDIT_REQUEST = 'creditRequest',
}

export enum InfobarUserComponents {
    PAGE_NAME = 'team',
}

export enum InfobarChangePasswordComponents {
    PAGE_NAME = 'change_password',
}

export enum InfobarProductGroupComponents {
    PAGE_NAME = 'product_group',
}

export enum InfobarNewPriceLevelComponents {
    PAGE_NAME = 'new_price_level'
}

export enum InfobarPriceLevelsListComponents {
    PAGE_NAME = 'price_level_list'
}

export enum InfobarNewProductComponents {
    PAGE_NAME = 'new_product',
}

export enum InfobarUploadProductComponents {
    PAGE_NAME = 'upload_product',
}

export enum InfobarCuponComponents {
    PAGE_NAME = 'add_edit_cupon',
}

export enum InfobarCustomerComponents {
    PAGE_NAME = 'customer',
    LEADS_PAGE = 'leads',
    LEADS_DETAILS = 'leadsDetails',
    INVITES = 'invites'
}

export enum InfobarCompanyDetailsComponents {
    EDIT_DETAILS = 'editCompanyDetails',
    EDIT_SHIPPING = 'editShipping',
    EDIT_BILLING = 'editBilling',
}

export enum InfobarCompleteAccountComponents {
    PAGE_NAME = 'complete_account',
}
