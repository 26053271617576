import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { PAYMENT_STATUS } from 'src/app/core/enums/payment-status.enum';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { currentCompanyType } from '../../selectors/auth.selector';
import { takeUntil, filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { PaymentsService } from 'src/app/core/services/payments/payments.service';
import { NotifierService } from 'angular-notifier';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { ToggleInfobar, UpdateTransaction } from '../../actions/infobar.actions';
import { environment } from 'src/environments/environment';
import { OrderService } from 'src/app/core/services/order/order.service';
import { CaptureTransactionModalComponent } from '../capture-transaction-modal/capture-transaction-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadTransactionHistory } from '../../actions/payment.actions';
import { selectTransactionHistory, selectLoadingTransactionHistory } from '../../selectors/payment.selector';

@Component({
  selector: 'app-transaction-info',
  templateUrl: './transaction-info.component.html',
  styleUrls: ['./transaction-info.component.scss']
})
export class TransactionInfoComponent implements OnInit, OnChanges {

  @Input('transaction') transaction: any

  transactions$: Observable<any[]>;
  loadingTransactionHistory$: Observable<boolean>;

  companyType$: Observable<COMPANY_TYPE>;

  COMPANY_TYPE = COMPANY_TYPE;
  PAYMENT_STATUS = PAYMENT_STATUS;
  PAYMENT_METHOD = PAYMENT_METHOD;

  loading = false;
  private fbxHandler: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private paymentService: PaymentsService,
    private notifier: NotifierService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.companyType$ = this.store.select(currentCompanyType).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(companyType => companyType !== undefined)
    );

    // configure fundbox
    this.fbxHandler = (window as any).FbxCheckout.configure({
      fbxKey: environment.FUNDBOX_CONFIG.KEY,
      env: environment.FUNDBOX_CONFIG.ENV,
      onComplete: (fbxOrderToken: string) => this.onAfterFbxCheckoutFinish(fbxOrderToken, this.transaction)
    });

    this.transactions$ = this.store.select(selectTransactionHistory);
    this.loadingTransactionHistory$ = this.store.select(selectLoadingTransactionHistory);
  }

  ngOnChanges() {
    if (this.transaction.paymentMethodId === PAYMENT_METHOD.CREDIT_CARD) {
      this.store.dispatch(new LoadTransactionHistory({ transactionId: this.transaction.id }));
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onCaptureCreditCardTransaction(transaction) {
    this.dialog.open(CaptureTransactionModalComponent, {
      id: 'capture-credit-card-transaction',
      data: {
        transactionId: transaction.id,
        amount: transaction.amount,
        orderNumber: transaction.orderNumber
      },
    });
  }

  changeTransactionStatus(transaction, status: PAYMENT_STATUS) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.paymentService.updateTransactionStatus(transaction.id, status)
      .subscribe((response: any) => {
        this.loading = false;
        this.store.dispatch(new ToggleInfobar({
          open: true,
          title: `#${response.transaction.id}`,
          componentsIds: [InfobarComponents.TRANSACTION],
          params: {
            [InfobarComponents.TRANSACTION]: response.transaction
          }
        }));
        
        this.transaction = response.transaction;
        this.store.dispatch(new UpdateTransaction({
          transaction: response.transaction
        }));
        
        if (status !== PAYMENT_STATUS.ReAuthorized) {
          this.notifier.notify('success', 'Payment updated with success');
        }
      }, (errorResponse) => {
        this.loading = false;
        this.notifier.notify('error', errorResponse.error.message);
      });

      // open fbx flow to reauthorize here
      if (status === PAYMENT_STATUS.ReAuthorized) {
        this.fbxHandler.open({
          ctaType: "confirm-and-pay",
          orderDetails: {
              amount_cents: (transaction.amount * 100).toFixed(0),
              shipping_amount_cents: (0 * 100).toFixed(0),
              checkout_items: [{
                name: transaction.orderNumber,
                sku:  transaction.orderNumber,
                description:  transaction.orderNumber,
                total_amount_cents: (transaction.amount * 100).toFixed(0),
                item_amount_cents: (transaction.amount * 100).toFixed(0),
                quantity: 1
              }]
          }
        });
      }
  }

  private onAfterFbxCheckoutFinish(fbxOrderToken: string, transaction: any) {
    // update order with new transaction
    this.paymentService.reAuthorizeFundboxTransaction(fbxOrderToken, transaction.id, transaction.orderId)
      .subscribe((responseTransaction: any) => {
        this.notifier.notify('success', 'Payment updated with success');
        this.store.dispatch(new UpdateTransaction({
          transaction: {
            ...responseTransaction,
            addNew: true
          }
        }));
      }, (errorResponse) => {
        this.notifier.notify('error', errorResponse.error.message);
      });
  }

}
