import { Component, OnInit, Input, OnDestroy, HostListener, AfterViewChecked } from '@angular/core';
import * as moment from 'moment';
import { MessageModel } from 'src/app/core/models/chat/message.model';
import { Subject } from 'rxjs';
import { EntityChanel } from 'src/app/core/models/chat/entity-chanel.model';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';
import { ChannelFilter } from 'src/app/core/models/chat/channel.filter';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import * as chatActions from '../../actions/chat.actions';
import * as chatSelectors from '../../selectors/chat.selector';
import { ChatConversation } from 'src/app/core/models/chat/chat-conversation.model';
import { BuyerVendorModel } from 'src/app/core/models/chat/buyer-vendor.model';
import { currentSite, currentSiteID, currentCompanyType } from '../../selectors/auth.selector';
import { ChatEntityType } from 'src/app/core/enums/chat-entity-type.enum';
import { PubnubService } from 'src/app/core/services/chat/pubnub.service';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { debounceTime, } from 'rxjs/operators';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html'
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() chatDisplayStatus: ChatDisplayStatus;

    ngUnsubscribe: Subject<void> = new Subject<void>();
    pubnub: any;
    isLoading: boolean;
    channel: EntityChanel = new EntityChanel();
    chatConversation: ChatConversation = new ChatConversation();
    buyersVendors: Array<BuyerVendorModel>;
    message: string;
    site: any;
    currentSiteId: number;
    test: any;
    isFromBuyerSide: boolean;
    keyword = '';
    public search$ = new Subject<any>();
    scrollContainer: any;

    constructor(private store: Store<AppState>, private pubnubService: PubnubService) { }

    ngOnInit() {
        this.store.pipe(select(currentSiteID)).subscribe(siteId => {
            this.currentSiteId = siteId;

            this.store.pipe(select(currentSite, { currentSiteId: this.currentSiteId })).subscribe(site => {
                this.site = site;
            });
        });

        this.store.select(currentCompanyType).subscribe(companyType => {
            this.isFromBuyerSide = companyType === COMPANY_TYPE.BUYER;
        });

        this.store.pipe(select(chatSelectors.getChatDisplayStatus)).subscribe((data: ChatDisplayStatus) => {
            if (data && !data.chatClosed) {
                this.chatDisplayStatus = data;

                if (!this.isGeneralEntityType()) {
                    this.createOrderChatConversation();
                }

                if (this.chatDisplayStatus.buyerVendor && this.chatDisplayStatus.showChat) {
                    this.createGeneralChatChatConversation(this.chatDisplayStatus.buyerVendor);
                }
            }
        });

        this.search$.pipe(debounceTime(500)).subscribe(() => {
            this.store.dispatch(new chatActions.LoadBuyerVendor(this.keyword));
        });
    }

    ngAfterViewChecked() {
        const element = document.querySelector('#bottom');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    sendMessage() {
        const messageData = {
            member: {
                displayName: this.site.displayName,
                secretKey: this.site.secretKey,
                acronym: this.site.acronym,
            },
            message: {
                acronym: this.site.acronym,
                text: this.message,
                typeId: 1,
                fromBuyerSide: this.isFromBuyerSide,
                channelUuid: this.chatConversation.channel.uuid
            },
            channel: {
                vendorSiteKey: this.chatConversation.channel.vendorSiteKey,
                buyerSiteKey: this.chatConversation.channel.buyerSiteKey,
                entity: '',
                entityTypeId: this.chatConversation.channel.entityTypeId,
                channelUuid: this.chatConversation.channel.uuid,
                entityId: this.chatConversation.channel.entityId
            },
            action: 1
        };

        this.pubnub.publish({
            channel: this.getChannelUUID(),
            message: messageData
        });
    }

    initPubnub() {
        this.pubnub = this.pubnubService.getPubNub();
        // remove listeners and subscribers previously added to this chat/channel
        this.pubnub.removeAllListeners();
        this.pubnub.unsubscribeAll();

        this.pubnub.addListener({
            message: (event) => {
                this.updateMessageList(event);
            },
            presence(event) {
                this.updateMessageList(event);
            }
        });
        this.pubnub.subscribe({
            channels: [this.getChannelUUID()],
            withPresence: true
        });
    }

    getChannelUUID() {
        return this.channel.uuid ? this.channel.uuid : 'Order.' + this.chatDisplayStatus.entity;
    }

    isGeneralEntityType(): boolean {
        return this.chatDisplayStatus.entityTypeId === ChatEntityType.General;
    }

    onCloseChat() {
        this.chatDisplayStatus = null;
        this.store.dispatch(new chatActions.ChatDisplayStatusAction(this.chatDisplayStatus));
    }

    getDate(date): string {
        return moment(date).format('L,  h:mm');
    }

    openConversationChat() {
        this.chatDisplayStatus.showNewConversation = false;
    }

    getChatTitle(): string {
        return this.chatDisplayStatus.showNewConversation ? 'New Conversation' : this.channel.entity;
    }

    openNewConversation() {
        this.store.pipe(select(chatSelectors.getBuyersVendors)).subscribe(data => {
            this.buyersVendors = data;
        });
        this.store.dispatch(new chatActions.LoadBuyerVendor(this.keyword));
        this.chatDisplayStatus.showOnlyInbox = false;
        this.chatDisplayStatus.showNewConversation = true;
        this.chatDisplayStatus.showChat = false;
    }

    openConversationWithCustomer(channel: EntityChanel) {
        this.channel = channel;
        if (this.channel.id > 0) {
            this.store.select(chatSelectors.getConversationsByChannel, { channelId: this.channel.id }).subscribe(data => {
                this.chatConversation = data;
            });
        }
        this.store.dispatch(new chatActions.LoadConversation(this.initFilter(this.channel)));
        this.chatDisplayStatus.showNewConversation = false;
        this.chatDisplayStatus.showOnlyInbox = false;
        this.chatDisplayStatus.showChat = true;

        if (this.isGeneralEntityType()) {
            this.initPubnub();
        }
    }

    createGeneralChatChatConversation(buyerVendor: BuyerVendorModel) {
        this.store.select(chatSelectors.getConversationsByChannel, { channelId: 0 }).subscribe(data => {
            if (data) {
                this.chatConversation = data;
                this.chatConversation.channel.entity = buyerVendor.name;
                this.chatConversation.channel.buyerSiteKey = this.site.secretKey;
                this.chatConversation.channel.vendorSiteKey = buyerVendor.secretKey;
                this.chatConversation.channel.entityTypeId = this.chatDisplayStatus.entityTypeId;
                this.chatConversation.channel.entityId = -1;
                this.openConversationWithCustomer(this.chatConversation.channel);
            }
        });
        this.store.dispatch(new chatActions.GenerateChannelUUID(this.chatDisplayStatus.entityTypeId));
    }

    createOrderChatConversation() {
        const channel = new EntityChanel();
        channel.entityTypeId = this.chatDisplayStatus.entityTypeId;
        channel.entityId = this.chatDisplayStatus.entityId;
        channel.entity = this.chatDisplayStatus.entity;

        this.chatConversation.channel = channel;
        this.store.select(chatSelectors.getEntityChannel, { entityId: this.chatDisplayStatus.entityId }).subscribe(storeChannel => {
            if (storeChannel) {
                this.channel.uuid = storeChannel.uuid;

                this.store.select(chatSelectors.getConversationsByChannel, { channelId: storeChannel.id }).subscribe(data => {
                    if (data) {
                        this.chatConversation = data;
                        this.chatConversation.channel.entity = this.chatDisplayStatus.entity;
                        this.chatConversation.channel.buyerSiteKey = this.site.secretKey;

                        this.initPubnub();
                    }
                });
            }
        });

        this.openConversationWithCustomer(this.chatConversation.channel);
    }

    closeInbox() {
        this.chatDisplayStatus.showOnlyInbox = false;
        this.chatDisplayStatus.showChat = true;
    }

    initFilter(entityChanel: EntityChanel): ChannelFilter {
        return {
            currentPage: 1,
            entityId: entityChanel.entityId,
            entityTypeId: entityChanel.entityTypeId,
            pageSize: 50
        };
    }

    updateMessageList(event: any) {
        const message = new MessageModel();

        message.acronym = event.message.member.acronym;
        message.memberKey = event.message.member.secretKey;
        message.channelUuid = event.message.message.channelUuid;
        message.text = event.message.message.text;
        message.fromBuyerSide = event.message.message.fromBuyerSide;
        message.createdDate = new Date(this.getDate(event.timeToken));
        message.typeId = event.message.message.typeId;
        message.displayName = event.message.member.displayName;

        this.chatConversation.messages.push(message);
        this.chatConversation.channel.latestMessageDate = message.createdDate;
        this.chatConversation.channel.latestmessage = message.text;

        this.message = null;
    }

    searchBuyerVendor() {
        this.store.pipe(debounceTime(1500));
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === 13 && this.message !== null) {
            this.sendMessage();
        }
    }

}
