import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page: number = 1;
  @Input() itemDetails: any;

  @Output() pageChanged = new EventEmitter();

  public term$ = new Subject<number>();

  constructor() { }

  ngOnInit() {
    this.term$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((newPage) => {
      this.setPage(isNaN(newPage) ? 1 : newPage);
    });
  }

  setPage (newPage) {
    if (newPage < 1) {
      this.page = 1;
    } else if (newPage > this.itemDetails.totalPages) {
      this.page = this.itemDetails.totalPages;
    } else {
      this.page = newPage;
    }
    
    this.pageChanged.emit(this.page);
  }
}
