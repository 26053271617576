import { SettingsActions, SettingsActionTypes } from '../actions/settings.actions';

export interface SettingsState {
    settingsOrderMin: any[];
    settingsOrderWeight: any[];
}

export const initialState: SettingsState = {
    settingsOrderMin: [],
    settingsOrderWeight: []
};

export function settingsReducer(state = initialState,
    action: SettingsActions): SettingsState {
    switch (action.type) {
        case SettingsActionTypes.SettingsOrderMinLoaded:
            return {
                ...state,
                settingsOrderMin: action.payload.settingsOrderMin
            };
        case SettingsActionTypes.AddOrderMinSuccess:
            let allOrderMin = state.settingsOrderMin;
            const editOrderMin = allOrderMin.find(o => o.id === action.payload.newOrderMin.id);
            if (editOrderMin) {
                editOrderMin.minAmount = action.payload.newOrderMin.minAmount;
                editOrderMin.deliveryCharge = action.payload.newOrderMin.deliveryCharge;
                editOrderMin.acceptOrder = action.payload.newOrderMin.acceptOrder;
            } else {
                allOrderMin.push(action.payload.newOrderMin);
            }

            return {
                ...state,
                settingsOrderMin: [...allOrderMin]
            };
        case SettingsActionTypes.DeleteOrderMinSuccess:
            let newSettings = state.settingsOrderMin;
            const orderMinToDeleteId = newSettings.findIndex(t => t.id === action.payload.orderMinId);
            newSettings.splice(orderMinToDeleteId, 1);

            return {
                ...state,
                settingsOrderMin: [...newSettings]
            };
        case SettingsActionTypes.SettingsOrderWeightLoaded:
            return {
                ...state,
                settingsOrderWeight: action.payload.settingsOrderWeight
            };

        case SettingsActionTypes.AddOrderWeightSuccess:

            let allOrderWeight = state.settingsOrderWeight;

            const editOrderWeight = allOrderWeight.find(o => o.id === action.payload.newOrderWeight.id);
            if (editOrderWeight) {
                editOrderWeight.minWeight = action.payload.newOrderWeight.minWeight;
            } else {
                allOrderWeight.push(action.payload.newOrderWeight);
            }

            return {
                ...state,
                settingsOrderWeight: [...allOrderWeight]
            };
        case SettingsActionTypes.DeleteOrderWeightSuccess:
            let newSettingsWeight = state.settingsOrderWeight;
            const orderWeightToDeleteId = newSettingsWeight.findIndex(t => t.id === action.payload.orderWeightId);
            newSettingsWeight.splice(orderWeightToDeleteId, 1);

            return {
                ...state,
                settingsOrderWeight: [...newSettingsWeight]
            };
        default:
            return state;
    }
}