import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { PriceLevelModel } from 'src/app/core/models/product/price-level.model';
import { SavePriceLevel, UpdatePriceLevel } from 'src/app/shared/actions/site.actions';
import { Observable } from 'rxjs';
import { selectLoadingSitesList } from 'src/app/shared/selectors/site.selector';

@Component({
    selector: 'app-new-price-level',
    templateUrl: './new-price-level.component.html'
})
export class NewPriceLevelComponent implements OnInit {

    loadingSites$: Observable<boolean>;
    form: FormGroup;
    @Input() priceLevel: PriceLevelModel;

    constructor(private fb: FormBuilder, private store: Store<AppState>) { }

    ngOnInit() {
        this.loadingSites$ = this.store.select(selectLoadingSitesList);
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            name: [this.priceLevel.id ? this.priceLevel.name : '', Validators.required],
            discountFee: [this.priceLevel.id ? this.priceLevel.discountFee : '', Validators.required]
        });
    }

    save() {
        if (!this.form.valid) {
            return;
        }

        const priceLevel = new PriceLevelModel();
        priceLevel.id = this.priceLevel.id ? this.priceLevel.id : -1;
        priceLevel.discountFee = this.form.value.discountFee;
        priceLevel.name = this.form.value.name;
        priceLevel.baseCatalogId = this.priceLevel.baseCatalogId;

        if (priceLevel.id > 0) {
            this.store.dispatch(new UpdatePriceLevel(priceLevel));
        } else {
            this.store.dispatch(new SavePriceLevel(priceLevel));
        }
    }

    cancel() {
        this.form.reset();
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }
}
