import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription, Subject } from 'rxjs';
import { selectCountries, selectStates } from '../../selectors/utils.selector';
import { LoadCountries, LoadStates } from '../../actions/utils.actions';
import { filter, takeUntil } from 'rxjs/operators';


const creditcards = [
    {
        brand: 'American Express',
        verification: '^3[47][0-9]',
        separation: '^([0-9]{4})([0-9]{6})?(?:([0-9]{6})([0-9]{5}))?$',
        hidden: '**** ****** *[0-9][0-9][0-9][0-9]',
        length: 15
    },
    {
        brand: 'Master Card',
        verification: '^5[1-5][0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    },
    {
        brand: 'Visa',
        verification: '^4[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    },
    {
        brand: 'Discover',
        verification: '^6(?:011|5[0-9]{2})[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    },
    {
        brand: 'Diners Club',
        verification: '^3(?:0[0-5]|[68][0-9])[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?(?:([0-9]{4})([0-9]{4})([0-9]{2}))?$',
        hidden: '**** **** **[0-9][0-9] [0-9][0-9]',
        length: 14
    },
    {
        brand: 'JCB',
        verification: '^(?:2131|1800|35[0-9]{3})[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    }
];

@Component({
    selector: 'app-add-credit-card',
    templateUrl: './add-credit-card.component.html',
    styleUrls: ['./add-credit-card.component.scss']
})
export class AddCreditCardComponent implements OnInit, OnDestroy {

    formCC: FormGroup;
    currentCardType;

    countries$: Observable<any[]>;
    states$: Observable<any[]>;
    months$: Observable<number[]>;
    years$: Observable<number[]>;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    currentDate = new Date();

    @Input() showSavePayment = true;
    @Output() onSave = new EventEmitter<any>();

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>
    ) {
        this.formCC = this.fb.group({
            creditCard: new FormGroup({
                cardNumber: new FormControl(null, [Validators.required]),
                name: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-z \-]*$/)]),
                month: new FormControl(null, [Validators.required]),
                year: new FormControl(null, [Validators.required]),
                cvv: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]),
            }),
            address: new FormGroup({
                address: new FormControl(null, [Validators.required, Validators.pattern(/^.*[a-zA-Z0-9\. \-]$/)]),
                city: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-z \-]*$/)]),
                zipCode: new FormControl(null, [Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]),
                countryId: new FormControl(null, [Validators.required]),
                stateId: new FormControl(null, [Validators.required]),
            }),
            save: [false, []]
        });

        this.setMonths(1);
        this.setYears(0);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.countries$ = this.store.select(selectCountries).pipe(takeUntil(this.ngUnsubscribe));
        this.states$ = this.store.select(selectStates, {
            countryId: null
        }).pipe(takeUntil(this.ngUnsubscribe));

        this.store.dispatch(new LoadCountries());

        this.address.countryId.valueChanges
            .pipe(filter(value => value !== null && value !== undefined), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.states$ = this.store.select(selectStates, {
                    countryId: this.address.countryId.value
                });

                this.store.dispatch(new LoadStates({
                    countryId: this.address.countryId.value
                }));

                this.address.stateId.setValue(null);
                this.address.stateId.updateValueAndValidity({ onlySelf: true });
            });
        this.setSubscriptions();
    }

    get form() { return this.formCC.controls; }

    get address() { return (this.formCC.controls.address as FormGroup).controls; }

    get creditCard() { return (this.formCC.controls.creditCard as FormGroup).controls; }

    onSubmit() {
        if (this.formCC.invalid) {
            return;
        }

        const formValue = this.formCC.value;
        formValue.creditCard.cardNumber = formValue.creditCard.cardNumber.replace(/ /g, '');
        this.onSave.emit(formValue);
    }

    onCardNumberKeyDown($event) {
        const card = (this.creditCard.cardNumber.value || '').replace(/[^0-9]/g, '');
        const currentCreditCard = creditcards.find(c => card.match(new RegExp(c.verification)));
        if (currentCreditCard) {

            if (card.length >= currentCreditCard.length && ![8, 9].includes($event.keyCode)) {
                $event.preventDefault();
                return;
            }

            // Add a Space if the Regex Passes
            if (new RegExp(currentCreditCard.separation).exec(card) && $event.keyCode >= 48 && $event.keyCode <= 57) {
                this.creditCard.cardNumber.setValue(this.creditCard.cardNumber.value + ' ');
            }

            this.currentCardType = currentCreditCard.brand;

        } else {
            this.currentCardType = undefined;
        }
    }
    // forceIncrementYear should be 0 or 1.
    // it is used to force the user to select a date which is in the future.
    // 0 - selected month is greater or equal to current month, this means that the year
    // can be current year.
    // 1 - selected month is less than the current month, this means that the year
    // need to be next year.
    setYears(forceIncrementYear: number) {
        const years = [];
        // in order to always display to the user 20
        for (let i = forceIncrementYear; i <= 20 + forceIncrementYear; i++) {
            years.push(this.currentDate.getFullYear() + i);
        }
        this.years$ = of(years);
    }

    setMonths(startingMonth: number) {
        const months = [];
        // in order to always display to the user 20
        for (let i = startingMonth; i <= 12; i++) {
            months.push(i);
        }

        this.months$ = of(months);
    }

    setSubscriptions() {
        this.creditCard.month.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(value => {
                // getMonth() start from 0 index
                // current months = getMonths + 1
                if (+value < this.currentDate.getMonth() + 1) {
                    this.setYears(1);
                } else {
                    this.setYears(0);
                }
            });

        this.creditCard.year.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(value => {
                if (+value > this.currentDate.getFullYear()) {
                    this.setMonths(1);
                } else {
                    // getMonth() start from 0 index
                    // current months = getMonths + 1
                    this.setMonths(this.currentDate.getMonth() + 1);
                }
            });
    }

}
