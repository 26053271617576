import { Injectable, NgZone } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, exhaustMap, map, catchError, withLatestFrom, take } from 'rxjs/operators';
import {
    LoadCartSummary, CartActionTypes, CartSummaryLoaded, LoadCart, CartLoaded, DeleteCartByVendorId, DeleteCartByVendorIdSuccess, SaveNotes, SaveNotesSuccess, MoveProduct, SubmitOrder, SubmitOrderSuccess,
    SaveDelivery, SaveDeliverySuccess, SaveDeliveryError, SubmitOrderError, LoadCartTotals, LoadCartTotalsSuccess, CheckTipsAvailability, CheckTipsAvailabilitySuccess, SaveTips, SaveTipsSuccess
} from '../actions/cart.actions';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { LoadNewOrdersCount } from '../actions/order.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { OrderService } from 'src/app/core/services/order/order.service';
import { DatePipe } from '@angular/common';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material/dialog';
import { ClearHistory } from '../actions/order-guide.action';
import { PaymentModalComponent } from 'src/app/shared/components/payment-modal/payment-modal.component';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { TipsModalComponent } from '../components/tips-modal/tips-modal.component';
import { getAccountTypeId } from '../selectors/auth.selector';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { selectCart } from '../selectors/cart.selector';

declare let fbq: Function;
declare let gtag: Function;
declare let ga: Function;

@Injectable()
export class CartEffects {

    @Effect({ dispatch: false })
    loadCartSummary$ = this.actions$.pipe(
        ofType<LoadCartSummary>(CartActionTypes.LoadCartSummaryAction),
        exhaustMap(() => this.cartSvc.loadCartSummary().pipe(
            map(summary => {
                this.store.dispatch(new CartSummaryLoaded({ summary }));
                this.store.dispatch(new LoadNewOrdersCount());
            })
        ))
    );

    @Effect()
    loadCart$ = this.actions$.pipe(
        ofType<LoadCart>(CartActionTypes.LoadCart),
        exhaustMap(() => this.cartSvc.loadCart().pipe(
            map((cartDetails: any[]) => {
                if (cartDetails) {
                    cartDetails.map(v => ({
                        ...v,
                        isExpanded: true
                    }));
                }

                return new CartLoaded({ cartDetails });
            })
        ))
    );

    @Effect()
    deleteCartByVendorId$ = this.actions$.pipe(
        ofType<DeleteCartByVendorId>(CartActionTypes.DeleteCartByVendorId),
        exhaustMap((action) => this.cartSvc.deleteCartByVendorId(action.payload.vendorId).pipe(
            map(() => action.payload.vendorId)
        )),
        map((vendorId) => new DeleteCartByVendorIdSuccess({ vendorId }))
    );

    @Effect({ dispatch: false })
    deleteCartByVendorIdSuccess$ = this.actions$.pipe(
        ofType<DeleteCartByVendorIdSuccess>(CartActionTypes.DeleteCartByVendorIdSuccess),
        map(() => {
            this.store.dispatch(new LoadCartSummary());
            this.store.dispatch(new ClearHistory());
        })
    );

    @Effect()
    saveNotes$ = this.actions$.pipe(
        ofType<SaveNotes>(CartActionTypes.SaveNotes),
        exhaustMap((action) => this.cartSvc.saveNotes(action.payload.notes, action.payload.vendorId).pipe(
            map(() => action.payload)
        )),
        map(payload => new SaveNotesSuccess(payload))
    );

    @Effect()
    saveDelivery$ = this.actions$.pipe(
        ofType<SaveDelivery>(CartActionTypes.SaveDelivery),
        exhaustMap((action) => this.cartSvc.saveDelivery(action.payload.deliveryDate, action.payload.deliveryFromTime, action.payload.deliveryToTime, action.payload.vendorId).pipe(
            map(() => action.payload),
            withLatestFrom(this.store.select(getAccountTypeId)),
            tap(([payload, accountTypeId]) => {
                // open modal for credit card payments
                if (action.payload.showPaymentModal) {
                    if (action.payload.vendor.acceptedPaymentMethods.find(pm => pm.id === PAYMENT_METHOD.CREDIT_CARD)) {
                        this.store.dispatch(new CheckTipsAvailability({ vendorId: action.payload.vendor.id, vendor: action.payload.vendor }));
                    } else {
                        const paymentModalRef = this.matDialog.open(PaymentModalComponent, {
                            id: 'submit-and-pay',
                            data: {
                                availablePaymentMethods: action.payload.vendor.acceptedPaymentMethods,
                                vendorId: action.payload.vendor.id,
                                vendor: action.payload.vendor
                            }
                        });
                    }
                }
            }),
            map(([payload, accountTypeId]) => new SaveDeliverySuccess({
                vendorId: payload.vendorId,
                deliveryDate: this.datePipe.transform(payload.deliveryDate, 'MM/dd/yyyy'),
                deliveryFromTime: this.datePipe.transform(payload.deliveryFromTime, 'HH:mm'),
                deliveryToTime: this.datePipe.transform(payload.deliveryToTime, 'HH:mm'),
            })
            ),
            catchError((e: HttpErrorResponse) => {
                return of(new SaveDeliveryError({
                    vendorId: action.payload.vendorId,
                    errorMessage: e.error.message,
                    deliveryDate: this.datePipe.transform(action.payload.deliveryDate, 'MM/dd/yyyy'),
                    deliveryFromTime: this.datePipe.transform(action.payload.deliveryFromTime, 'HH:mm'),
                    deliveryToTime: this.datePipe.transform(action.payload.deliveryToTime, 'HH:mm'),
                }));
            })
        ))
    );

    @Effect()
    saveDeliverySuccess$ = this.actions$.pipe(
        ofType<SaveDeliverySuccess>(CartActionTypes.SaveDeliverySuccess),
        map((action) => new LoadCartTotals({ vendorId: action.payload.vendorId }))
    );

    @Effect({ dispatch: false })
    moveProduct$ = this.actions$.pipe(
        ofType<MoveProduct>(CartActionTypes.MoveProduct),
        exhaustMap((action) => this.cartSvc.moveProduct(action.payload)),
        map(() => {
            this.store.dispatch(new LoadCart());
            this.store.dispatch(new ClearHistory());
        })
    );

    @Effect()
    submit$ = this.actions$.pipe(
        ofType<SubmitOrder>(CartActionTypes.SubmitOrder),
        exhaustMap((action) => this.orderSvc.save(action.payload)
            .pipe(
                map((response) => {
                    const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'submit-and-pay');
                    if (modalInstance) {
                        modalInstance.close();
                    }

                    this.store.dispatch(new ClearHistory());

                    return new SubmitOrderSuccess({
                        response
                    });
                }),
                catchError((e: HttpErrorResponse) => {
                    if (action.payload.popNotifications) {
                        this.notifySvc.show({
                            type: 'error',
                            message: e.error.message
                        })
                    }

                    return of(new SubmitOrderError({
                        vendorId: action.payload.vendorId,
                        message: e.error.message
                    }));
                })
            )
        )
    );

    @Effect()
    submitOrderSuccess$ = this.actions$.pipe(
        ofType<SubmitOrderSuccess>(CartActionTypes.SubmitOrderSuccess),
        tap((action) => {
            this.store.select(selectCart)
                .pipe(
                    take(1),
                ).subscribe(cart => {
                    const currentCartOrder = cart.find(c => c.id === action.payload.response.vendorId);
                    if (currentCartOrder) {
                        this.sendAnalytics(currentCartOrder);
                    }
                });
        }),
        map(() => new LoadCartSummary())
    );

    @Effect()
    loadCartTotals$ = this.actions$.pipe(
        ofType<LoadCartTotals>(CartActionTypes.LoadCartTotals),
        exhaustMap((action) => this.cartSvc.loadCartTotals(action.payload.vendorId)),
        map((response) => new LoadCartTotalsSuccess({
            cartVendorTotals: response
        }))
    );

    @Effect()
    checkTipsAvailability$ = this.actions$.pipe(
        ofType<CheckTipsAvailability>(CartActionTypes.CheckTipsAvailability),
        exhaustMap((action) => this.cartSvc.hasTipsEnabled(action.payload.vendorId).pipe(
            tap((isEnabled: boolean) => {
                if (isEnabled) {
                    this.matDialog.open(TipsModalComponent, {
                        id: 'tips',
                        data: {
                            vendorId: action.payload.vendorId,
                            vendor: action.payload.vendor
                        }
                    });
                } else {
                    this.matDialog.open(PaymentModalComponent, {
                        id: 'submit-and-pay',
                        data: {
                            availablePaymentMethods: action.payload.vendor.acceptedPaymentMethods,
                            vendorId: action.payload.vendor.id,
                            vendor: action.payload.vendor
                        }
                    });
                }
            }),
            map((isEnabled: boolean) => new CheckTipsAvailabilitySuccess({
                vendorId: action.payload.vendorId,
                isEnabled
            }))
        )),
    );

    @Effect()
    saveTips$ = this.actions$.pipe(
        ofType<SaveTips>(CartActionTypes.SaveTips),
        exhaustMap((action) => this.cartSvc.saveTips(action.payload.vendorId, action.payload.amount).pipe(
            tap(() => {
                const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'tips');
                if (modalInstance) {
                    modalInstance.close();
                }

                this.matDialog.open(PaymentModalComponent, {
                    id: 'submit-and-pay',
                    data: {
                        availablePaymentMethods: action.payload.vendor.acceptedPaymentMethods,
                        vendorId: action.payload.vendor.id,
                        vendor: action.payload.vendor
                    }
                });
            }),
            map(() => new SaveTipsSuccess())
        ))
    );

    constructor(
        private actions$: Actions,
        private cartSvc: CartService,
        private orderSvc: OrderService,
        private store: Store<AppState>,
        private datePipe: DatePipe,
        private notifySvc: NotifierService,
        private matDialog: MatDialog
    ) { }

    private sendAnalytics(order) {
        try {
            const dataLayer: any[] = (window as any).dataLayer || [];
            const products = order.items.map(i => ({
                name: i.name,
                id: i.sku,
                price: i.price,
                brand: '',
                category: i.category || '',
                quantity: i.quantity,
            }));

            const ecommerceItems = order.items.map(i => ({
                sku: i.sku,
                name: i.name,
                category: '',
                price: i.price,
                quantity: i.quantity
            }));

            dataLayer.push({
                event: 'fire_ecommerce',
                ecommerce: {
                    currencyCode: 'USD',
                    purchase: {
                        actionField: {
                            id: order.submittedDetails.orderNumber,
                            affiliation: order.submittedDetails.vendorName,
                            revenue: order.submittedDetails.total,
                            tax: '0',
                            shipping: order.deliveryFee,
                            coupon: ''
                        },
                        products
                    }
                }
            });

            dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'Ecommerce SHOP',
                eventAction: order.submittedDetails.vendorName,
                eventLabel: order.submittedDetails.id,
                eventValue: order.submittedDetails.total
            });

            dataLayer.push({
                transactionId: order.submittedDetails.id,
                transactionAffiliation: order.submittedDetails.vendorName,
                transactionTotal: order.submittedDetails.total,
                transactionTax: 0,
                transactionShipping: order.deliveryFee,
                transactionProducts: ecommerceItems,
                event: 'transactionComplete'
            });

            console.log('Send ga ecommerce conversion');
        } catch (ex) {
            // console.log(ex);
        }

        try {

            const contents = order.items.map(i => ({
                id: i.sku,
                name: i.name,
                price: i.price,
                quantity: i.quantity
            }));

            fbq('track', 'Purchase',
                // begin parameter object data
                {
                    value: order.submittedDetails.total,
                    currency: 'USD',
                    contents: contents,
                    content_type: 'product'
                }
                // end parameter object data
            );
            console.log('Send facebook pixel conversion');
        }
        catch (ex) { }

        try {
            gtag('event', 'conversion', {
                'send_to': 'AW-759948046/yx91CNa5ht0BEI7Gr-oC',
                'value': order.submittedDetails.total,
                'currency': 'USD',
                'transaction_id': order.submittedDetails.orderNumber
            });
            console.log('Send ad word conversion');
        }
        catch (ex) { }

        try {

            ga('tracker.ecommerce:addTransaction', {
                'id': `${order.submittedDetails.orderNumber}`, // Transaction ID. Required.
                'affiliation': '', // store name.
                'revenue': `${order.submittedDetails.total}`, // total revenue.
                'shipping': `${order.deliveryFee}`, // Shipping.
                'tax': '0', // Tax.
                'currency': 'USD'
            });

            order.items.forEach(item => {
                ga('tracker.ecommerce:addItem', {
                    'id': `${order.submittedDetails.orderNumber}`, // Transaction ID. Required. Same as in the transaction data.
                    'name': `${item.name}`, // Product name. Required.
                    'sku': `${item.sku}`, // Product SKU.
                    'category': `${item.category}`, // Product Category or variation.
                    'price': `${item.price}`, // Product price.
                    'quantity': `${item.quantity}`, // Product Quantity.
                    'currency': 'USD'
                });
            });

            ga('tracker.ecommerce:send');

            console.log('Send analytics ecommerce tracking 2');

            ga('send', 'event', 'Submit and Pay', 'Click', '', order.submittedDetails.total);

            console.log('Send analytics goal tracking');
        } catch (ex) {
            console.log('Send analytics ecommerce tracking error 2', ex);
        }
    }
}
