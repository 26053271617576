import { Injectable } from '@angular/core';
declare var pubnubInitializer: any;
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PubnubService {
    pubnub: any;

    constructor() { }

    initPubnub(secretKey: string) {
        this.pubnub = pubnubInitializer.init(environment.PUBNUB_KEYS.PUBLISH_KEY, environment.PUBNUB_KEYS.SUBSCRIBE_KEY, secretKey);
    }

    getPubNub(): any {
        return this.pubnub;
    }
}
