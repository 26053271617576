
import { Injectable } from '@angular/core';
import {
    Resolve
} from '@angular/router';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BrandingService } from '../services/branding/branding.service';
import { PlatformConfigurationModel } from '../models/branding/platform-config.model';
import { LoadPlatformConfigurationSuccess } from 'src/app/shared/actions/branding.actions';

@Injectable()
export class PlatformConfigurationResolve implements Resolve<any> {
    constructor(
        private brandingService: BrandingService,
        private store: Store<AppState>
    ) { }

    resolve(
    ): Observable<any> | Promise<any> | any {
        document.body.classList.add('loading', 'loading--large');
        return this.brandingService.getPlatformConfiguration().subscribe((platformConfiguration: PlatformConfigurationModel) => {
            document.body.classList.remove('loading', 'loading--large');
            this.store.dispatch(new LoadPlatformConfigurationSuccess(platformConfiguration));
            return platformConfiguration;
        }, () => {
            return false;
        });
    }
}
