export class EntityChanel {
    id: number;
    uuid: string;
    entityId: number;
    entity: any;
    acronym: string;
    entityTypeId: number;
    entityType: string;
    vendorSiteKey: string;
    buyerSiteKey: string;
    ownerUserKey: string;
    fromBuyerSide: boolean;
    channelStatusId: number;
    channelStatus: any;
    createdDate: Date;
    lastActivity: Date;
    unreadMessagesNo: number;
    latestmessage: string;
    latestMessageDate: Date;
    badgeColor: string;
}
