import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { of } from 'rxjs';
import { catchError, exhaust, map, mergeMap } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { CouponDetails } from 'src/app/core/models/cupons/coupon-details.model';
import { CouponModel } from 'src/app/core/models/cupons/coupon.model';
import { SaveCouponModel } from 'src/app/core/models/cupons/save-coupon.model';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LoadCart } from '../actions/cart.actions';
import {
    ActivateCoupon,
    ActivateCouponFailed,
    ActivateCouponSuccess,
    ApplyCoupon, ApplyCouponError, CouponActionTypes, LoadCoupon, LoadCouponDetails, LoadCouponDetailsSuccess, LoadCouponSuccess, RemoveCoupon, SaveCoupon, SaveCouponFailed,
    SaveCouponSuccess,
    SaveEditCoupon,
    SaveEditCouponFailed,
    SaveEditCouponSuccess
} from '../actions/coupon.actions';
import { ToggleInfobar } from '../actions/infobar.actions';



@Injectable()
export class CouponEffects {

    @Effect({ dispatch: false })
    applyCoupon$ = this.actions$.pipe(
        ofType<ApplyCoupon>(CouponActionTypes.ApplyCoupon),
        mergeMap((action) => this.couponSvc.applyCoupon(action.payload.coupon).pipe(
            map(() =>
                this.store.dispatch(new LoadCart())
            ),
            catchError((e: HttpErrorResponse) => {
                return of(this.store.dispatch(new ApplyCouponError({
                    vendorSiteId: action.payload.coupon.vendorSiteId,
                    message: e.error.message
                })));
            })
        ))
    );

    @Effect({ dispatch: false })
    removeCoupon$ = this.actions$.pipe(
        ofType<RemoveCoupon>(CouponActionTypes.RemoveCoupon),
        mergeMap((action) => this.couponSvc.removeCoupon(action.payload.vendorSiteId).pipe(
            map(() => this.store.dispatch(new LoadCart()))
        ))
    );

    @Effect()
    addCoupon$ = this.actions$.pipe(
        ofType(CouponActionTypes.SaveCoupon),
        mergeMap((action: SaveCoupon) => this.couponSvc.addCupon(action.payload).pipe(
            map((response: CouponModel) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false,
                }));

                return new SaveCouponSuccess(response);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(new SaveCouponFailed(e.error.message));
            })
        ))
    );

    @Effect({ dispatch: false })
    addCouponFailed$ = this.actions$.pipe(
        ofType(CouponActionTypes.SaveCouponFailed),
        map((action: SaveCouponFailed) => {
            this.notifierSvc.show({
                type: 'error',
                message: action.payload
            });
        })
    );

    @Effect()
    loadCoupon$ = this.actions$.pipe(
        ofType(CouponActionTypes.LoadCoupon),
        mergeMap((action: LoadCoupon) => this.couponSvc.getCupons(action.payload).pipe(
            map((data: CouponDetails) => new LoadCouponSuccess(data))
        ))
    );

    @Effect()
    loadCouponDetails$ = this.actions$.pipe(
        ofType(CouponActionTypes.LoadCouponDetails),
        mergeMap((action: LoadCouponDetails) => this.couponSvc.getCuponDetails(action.payload).pipe(
            map((data: SaveCouponModel) => new LoadCouponDetailsSuccess(data))
        ))
    );

    @Effect()
    activateCoupon$ = this.actions$.pipe(
        ofType(CouponActionTypes.ActivateCoupon),
        mergeMap((action: ActivateCoupon) => this.couponSvc.activateCoupon(action.payload.couponId, action.payload.active).pipe(
            map(() => {
                this.notifierSvc.show({
                    type: 'success',
                    message: 'Coupon has been saved successfully.'
                });
                return new ActivateCouponSuccess();
            }),
            catchError((e: HttpErrorResponse) => {
                this.notifierSvc.show({
                    type: 'error',
                    message: e.error.message
                });
                return of(new ActivateCouponFailed());
            })
        ))
    );

    @Effect()
    saveEditCoupon$ = this.actions$.pipe(
        ofType(CouponActionTypes.SaveEditCoupon),
        mergeMap((action: SaveEditCoupon) => this.couponSvc.saveEditCoupon(action.payload.couponId, action.payload.coupon).pipe(
            map((data: CouponModel) => {
                this.notifierSvc.show({
                    type: 'success',
                    message: 'Coupon has been updated successfully.'
                });
                return new SaveEditCouponSuccess(data);
            }),
            catchError((e: HttpErrorResponse) => {
                this.notifierSvc.show({
                    type: 'error',
                    message: e.error.message
                });
                return of(new SaveEditCouponFailed());
            })
        ))
    );

    constructor(
        private actions$: Actions,
        private couponSvc: CouponService,
        private notifierSvc: NotifierService,
        private store: Store<AppState>) { }

}
