import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { selectRelatedProducts } from '../../selectors/order-guide.selector';
import { LoadRelatedProducts } from '../../actions/order-guide.action';

@Component({
    selector: 'app-product-related',
    templateUrl: './product-related.component.html',
    styleUrls: ['./product-related.component.scss']
})
export class ProductRelatedComponent implements OnInit, OnChanges {

    @Input() productId: any;
    products$: any;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.products$ = this.store.pipe(select(selectRelatedProducts));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.productId) {
            this.store.dispatch(new LoadRelatedProducts({
                productId: this.productId
            }));
        }
    }

}
