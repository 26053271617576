import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { GridService } from 'src/app/core/services/grid/grid.service';
import { tap } from 'rxjs/operators';
import { GRID_TYPE } from 'src/app/core/enums/grid-type.enum';
import { DATE_RANGE_DEFAULT_OPTIONS, DATE_CUSTOM_RANGES, DEFAULT_DATE_RANGE_SELECTION } from 'src/app/shared/constants/global.contants';
import { OrderFilterType } from 'src/app/core/enums/date-groping.enum';
import { ProductCostByCategoryService } from 'src/app/modules/product-cost-by-category/product-cost-by-category.service';

@Component({
    selector: 'app-datepicker-range-filter',
    templateUrl: './datepicker-range-filter.component.html',
    styleUrls: ['./datepicker-range-filter.component.scss']
})
export class DatepickerRangeFilterComponent implements OnInit {

    @Input() title: string;
    gridApi: any;
    maxDate = moment();

    selectedRange: any;
    selected = DEFAULT_DATE_RANGE_SELECTION;

    rangeOptions: any[] = DATE_RANGE_DEFAULT_OPTIONS;
    ranges: any = DATE_CUSTOM_RANGES;
    columnDefs: any;
    rowData: any;
    selectedFilterType: OrderFilterType;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private service: ProductCostByCategoryService,
        private gridSvc: GridService) { }

    ngOnInit() {
        this.selectedRange = this.rangeOptions[1];
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onDateRangeChanged(params) {
        if (!this.selectedFilterType) {
            return;
        }

        const startDate = params.startDate || this.selected.startDate;
        const endDate = params.endDate || this.selected.endDate;

        this.columnDefs = null;
        this.gridSvc.getHeader(GRID_TYPE.PRODUCT_COST_BY_CATEGORY, {
            startDate: startDate.format('MM/DD/YYYY'),
            endDate: endDate.format('MM/DD/YYYY'),
            dateGrouping: this.selectedRange.value
        }).subscribe((columnDefs) => {
            this.columnDefs = columnDefs;
            this.rowData = this.service.loadProductCost(startDate.format('MM/DD/YYYY'), endDate.format('MM/DD/YYYY'), this.selectedRange.value, this.selectedFilterType).pipe(
                tap((rows: any[]) => {
                    const totalsRow = rows.pop();
                    this.gridApi.setPinnedBottomRowData([totalsRow]);
                })
            );
        });
    }

    applyRange(range: string) {
        if (!range.includes('-')) {
            return;
        }

        const dates = range.split('-');
        const startDate = moment(dates[0].trim());
        const endDate = moment(dates[1].trim());

        // validate dates
        if (!startDate.isValid() || !endDate.isValid()) {
            return;
        }

        if (startDate > endDate) {
            return;
        }

        // dates are valid -> do call
        this.onDateRangeChanged({
            startDate,
            endDate
        })
    }

}

