import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-edit',
  templateUrl: './icon-edit.component.html',
  styleUrls: ['./icon-edit.component.scss']
})
export class IconEditComponent implements OnInit {
  
  @Input() cssClass = '';

  constructor() { }

  ngOnInit() {
  }

}
