import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Observable } from 'rxjs';
import { tagsList } from '../../selectors/tags.selector';
import { LoadTags } from '../../actions/tags.actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TagsFilterModalComponent } from '../tags-filter-modal/tags-filter-modal.component';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-tags-filter',
  templateUrl: './tags-filter.component.html',
  styleUrls: ['./tags-filter.component.scss']
})
export class TagsFilterComponent implements OnInit, OnChanges {

  tags$: Observable<any>;

  @Input() selectedTagId: number;

  @Output() change = new EventEmitter<any>();

  constructor(  
    private store: Store<AppState>,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.store.dispatch(new LoadTags());
  }

  ngOnChanges() {
    this.tags$ = this.store.select(tagsList).pipe(
      map(tags => tags.map((t) => ({
        ...t,
        isSelected: t.id === this.selectedTagId
      })))
    );
  }

  openModal(): void {
    this.dialog.open(TagsFilterModalComponent, {
      data: this.tags$
    });
  }
  
  onSelect(tag) {
    this.change.emit(tag.id);
  }

  onSelectAll() {
    this.change.emit(undefined);
  }

}
