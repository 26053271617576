import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    constructor(
        private http: HttpClient
    ) { }

    register(account: any) {
        return this.http.post(`${environment.api.auth}/Auth/register`, {
            email: account.email,
            password: account.password,
            confirmPassword: account.confirmPassword,
            acceptedTermsAndConditions: true,
            profileId: account.profileId,
            inviteCode: account.inviteCode
        });
    }

    registerInfo(account: any) {
        return this.http.post(`${environment.api.auth}/Auth/register/update_info`, {
            token: account.token,
            companyName: account.businessName,
            firstName: account.firstName,
            lastName: account.lastName,
            street: account.address,
            city: account.city,
            zipCode: account.zip,
            stateId: account.stateId,
            phoneNumber: account.phone,
            countryId: account.countryId
        });
    }

    verifyAccount(token: string) {
        return this.http.post(`${environment.api.auth}/Auth/validate`, {
            token
        });
    }

    loadProfiles() {
        return this.http.get(`${environment.api.auth}/Auth/register/profiles`);
    }

}
