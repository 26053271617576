import { Action } from '@ngrx/store';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { PlatformConfigurationModel } from 'src/app/core/models/branding/platform-config.model';

export enum BrandingActionTypes {
    LoadBranding = '[Branding] Load Branding',
    LoadBrandingSuccess = '[Branding] Load Branding Success',
    LoadPlatformConfigurationSuccess = '[Branding] Load Platform Configuration Success'
}

export class LoadBranding implements Action {
    readonly type = BrandingActionTypes.LoadBranding;
}

export class LoadBrandingSuccess implements Action {
    readonly type = BrandingActionTypes.LoadBrandingSuccess;

    constructor(public payload: BrandingModel) { }
}

export class LoadPlatformConfigurationSuccess implements Action {
    readonly type = BrandingActionTypes.LoadPlatformConfigurationSuccess;

    constructor(public payload: PlatformConfigurationModel) { }
}

export type BrandingActions =
    | LoadBranding
    | LoadBrandingSuccess
    | LoadPlatformConfigurationSuccess;
