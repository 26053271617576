import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { AddToCart } from '../../actions/order-guide.action';

@Component({
    selector: 'app-product-quantity',
    templateUrl: './product-quantity.component.html',
    styleUrls: ['./product-quantity.component.scss']
})
export class ProductQuantityComponent implements OnInit, OnChanges {

    @Input() vendor: any;

    @Input() allowFraction: boolean = false;

    @Input() reloadCartVendor: boolean = false;

    @Input() extraParams: any;
    @Input() externalDispatch: boolean = false;
    @Output() quantityChange = new EventEmitter();

    public quantity: number = 0;
    addToCartAction: AddToCart;
    private timer;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.addToCartAction = new AddToCart({
            vendorId: undefined,
            productId: undefined,
            quantity: undefined,
            reloadCartVendor: undefined
        });
        this.quantity = this.vendor.quantity;
    }

    ngOnChanges() {
        this.quantity = this.vendor.quantity;
    }

    changeQuantity(decreaserQuantity: boolean = false) {
        const changeSize = this.allowFraction ? 0.5 : 1;
        const newQuantity = this.quantity + (decreaserQuantity ? (changeSize * -1) : changeSize);
        if (newQuantity < 0) {
            return;
        }

        this.quantity = newQuantity;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.externalDispatch) {
                this.quantityChange.emit({
                    vendorId: this.vendor.id,
                    productId: this.vendor.productId,
                    productIdFromGroup: this.vendor.realProductId,
                    quantity: this.quantity,
                    reloadCartVendor: this.reloadCartVendor,
                    extraParams: this.extraParams
                });
            } else {
                this.addToCartAction.payload.vendorId = this.vendor.id;
                this.addToCartAction.payload.productId = this.vendor.productId;
                this.addToCartAction.payload.productIdFromGroup = this.vendor.realProductId;
                this.addToCartAction.payload.quantity = this.quantity;
                this.addToCartAction.payload.reloadCartVendor = this.reloadCartVendor;
                this.store.dispatch(this.addToCartAction);
            }
        }, 300);
    }

    submitQuantity($event) {
        if (isNaN($event.target.value)) {
            $event.target.value = this.quantity;
            return;
        }

        const newQuantity = Number($event.target.value);
        const hasValidDecimals = this.allowFraction ? ((newQuantity % 1 === 0 || newQuantity % 1 === 0.5 ? true : false)) : (newQuantity % 1 === 0);
        if (newQuantity < 0 || !hasValidDecimals || newQuantity === this.quantity) {
            $event.target.value = this.quantity;
            return;
        }

        this.quantity = newQuantity;
        if (this.externalDispatch) {
            this.quantityChange.emit({
                vendorId: this.vendor.id,
                productId: this.vendor.productId,
                quantity: this.quantity,
                reloadCartVendor: this.reloadCartVendor,
                extraParams: this.extraParams
            });
        } else {
            this.store.dispatch(new AddToCart({
                vendorId: this.vendor.id,
                productId: this.vendor.productId,
                quantity: this.quantity,
                reloadCartVendor: this.reloadCartVendor
            }));
        }
    }

}
