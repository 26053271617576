import { UnitOfMeasureModel } from './unit-of-measure.model';
import { PriceLevelInfo } from './price-level-info.model';
import { CatalogColorStatus } from './catalog-color-status.enum';

export class ProductCatalogModel {
    isVariation: boolean;
    isParentVariant: boolean;
    mainProductId: number;
    unitUomList: Array<UnitOfMeasureModel>;
    historyID: number;
    unitUomName: string;
    uomDescription: string;
    unitUomDescription: string;
    mainCategoryId: number;
    mainCategoryName: string;
    hasChilds: boolean;
    variations: Array<ProductCatalogModel>;
    price: number;
    unitPrice: number;
    priceFormatted: string;
    vendorSku: string;
    isOffered: boolean;
    isAvailable: boolean;
    isAvailableLocked: boolean;
    commission: number;
    defaultDiscountFee: number;
    defaultInheritTypeId: number;
    baseCatalogDiscountFee: number;
    inheritPrice: boolean;
    inheritAvailable: boolean;
    inheritDiscountFee: boolean;
    vendorId: number;
    baseCatalogId: number;
    id: number;
    name: string;
    customName: string;
    categoryName: string;
    categoryNameUrl: string;
    shortDescription: string;
    description: string;
    imageUrl: string;
    imageUrlSmall: string;
    sku: string;
    isFavorite: boolean;
    uomSize: number;
    uomName: string;
    vendors: Array<any>;
    allowFraction: boolean;
    tags: Array<any>;
    images: Array<any>;
    videos: Array<any>;
    promotedText: string;
    promotedTextColor: string;
    promotedTextBackgroundColor: string;
    isGroupedProduct: boolean;
    productGroupId: number;
    uomId: number;
    unitUomId: number;
    unitUomSize: number;
    displayPricePerUnit: boolean;
    priceLevelInfo: Array<PriceLevelInfo>;
    categoryId: number;
    weight: number;
    isPackPrice: boolean;
    isPricePerUnit: boolean;
    isCalculatedFrom: boolean;
    isPrivate: boolean;
    newPrice: number;
    newUnitPrice: number;
    newIsAvailable: boolean;
    newIsAvailableLocked: boolean;
    markupStatus: CatalogColorStatus;
    priceStatus: CatalogColorStatus;
    priceBase: number;
    // used to display the loading spinner on each product row when
    // saving product settings
    saving: boolean;
    saved = false;
}
