import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddCreditCard, PaymentActionTypes, AddCreditCardSuccess, DeletePaymentProfile, LoadPaymentProfiles, LoadPaymentProfilesSuccess, DeletePaymentProfileSuccess, AddCreditCardError, LoadTransactionHistory, TransactionHistoryLoaded, LoadPaymentSettings, LoadPaymentSettingsSuccess, UpdatePaymentSettings, UpdatePaymentSettingsSuccess, UpdatePaymentSettingsBatch, UpdatePaymentSettingsBatchSuccess } from '../actions/payment.actions';
import { exhaustMap, map, tap, catchError, withLatestFrom } from 'rxjs/operators';
import { PaymentsService } from 'src/app/core/services/payments/payments.service';
import { NotifierService } from 'angular-notifier';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { PaymentSetting } from 'src/app/core/models/payment/payment-setting.model';
import { selectPaymentSettings } from '../selectors/payment.selector';

@Injectable()
export class PaymentEffects {

    @Effect()
    addCreditCard$ = this.actions$.pipe(
        ofType<AddCreditCard>(PaymentActionTypes.AddCreditCard),
        exhaustMap((action) => this.paymentSvc.addCreditCard(action.payload)
            .pipe(
                map(response => {

                    const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'add-payment-method');
                    if (modalInstance) {
                        modalInstance.close();
                    }

                    return new AddCreditCardSuccess({
                        creditCardDetails: response
                    });
                }),
                catchError((e: HttpErrorResponse) => {
                    this.notifySvc.show({
                        type: 'error',
                        message: 'There was an error. Please try again'
                    });
                    return of(new AddCreditCardError());
                })
            )
        )
    );

    @Effect()
    addCreditCardSuccess$ = this.actions$.pipe(
        ofType<AddCreditCardSuccess>(PaymentActionTypes.AddCreditCardSuccess),
        map(action => {

            this.notifySvc.show({
                type: 'success',
                message: 'Payment method added with success'
            });

            return new LoadPaymentProfiles();
        })
    );

    @Effect()
    loadPaymentProfiles$ = this.actions$.pipe(
        ofType<LoadPaymentProfiles>(PaymentActionTypes.LoadPaymentProfiles),
        exhaustMap((action) => this.paymentSvc.loadProfiles()),
        map((profiles: any[]) => new LoadPaymentProfilesSuccess({ profiles }))
    );

    @Effect()
    deletePaymentProfile$ = this.actions$.pipe(
        ofType<DeletePaymentProfile>(PaymentActionTypes.DeletePaymentProfile),
        exhaustMap((action) => this.paymentSvc.deleteProfile(action.payload.profileId).pipe(map(response => action.payload.profileId))),
        map((profileId: any[]) => new DeletePaymentProfileSuccess({ profileId })),
        tap(() => {
            this.notifySvc.notify('success', 'Credit card deleted with success');
        })
    );

    @Effect()
    loadTransactionHistory$ = this.actions$.pipe(
        ofType<LoadTransactionHistory>(PaymentActionTypes.LoadTransactionHistory),
        exhaustMap((action) => this.paymentSvc.loadTransactionHistry(action.payload.transactionId)),
        map((transactionHistory: any[]) => new TransactionHistoryLoaded({ transactionHistory }))
    );

    @Effect()
    loadPaymentSettings$ = this.actions$.pipe(
        ofType<LoadPaymentSettings>(PaymentActionTypes.LoadPaymentSettings),
        exhaustMap((action) => this.paymentSvc.loadSettings()),
        map((settings: PaymentSetting[]) => new LoadPaymentSettingsSuccess({ settings }))
    );

    @Effect()
    updatePaymentSetting$ = this.actions$.pipe(
        ofType<UpdatePaymentSettings>(PaymentActionTypes.UpdatePaymentSettings),
        exhaustMap((action) => this.paymentSvc.updateSetting(action.payload.settings, action.payload.enable)
            .pipe(
                tap(() => {
                    this.notifySvc.show({
                        type: 'success',
                        message: 'Payment Settings updated'
                    });
                }),
                map(() => new UpdatePaymentSettingsSuccess(action.payload))
            )),
    );

    @Effect()
    updatePaymentSettingBatch$ = this.actions$.pipe(
        ofType<UpdatePaymentSettingsBatch>(PaymentActionTypes.UpdatePaymentSettingsBatch),
        withLatestFrom(this.store.select(selectPaymentSettings)),
        exhaustMap(([action, settings]) => {
            const updateSettings = settings.map(s => ({
                priceLevelId: s.vendorId,
                paymentMethods: s.paymentMethods.filter(pm => pm.id === action.payload.paymentMethod).map(pm => pm.id)
            }));
            return this.paymentSvc.updateSetting(updateSettings, action.payload.enable)
                .pipe(
                    tap(() => {
                        this.notifySvc.show({
                            type: 'success',
                            message: 'Payment Settings updated'
                        });
                    }),
                    map(() => new UpdatePaymentSettingsBatchSuccess(action.payload))
                )
        }),
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private matDialog: MatDialog,
        private paymentSvc: PaymentsService,
        private notifySvc: NotifierService
    ) { }

}