import { Action } from '@ngrx/store';
import { SortbyFilter } from 'src/app/core/enums/sortby-filter.enum';

export enum OrderActionTypes {
    LoadNewOrdersCountAction = '[Global] Load New Orders Count',
    NewOrdersCountLoadedAction = '[Global] New Orders Count Loaded',
    LoadOrdersAction = '[Order] Load Orders',
    OrdersLoadedAction = '[Order] Orders Loaded Success',
    LoadStatusAction = '[Order] Load Statuses',
    StatusLoadedAction = '[Order] Status Loaded Success',
    Reorder = '[Order] Reorder',
    ChangeOrderStatus = '[Order] ChangeOrderStatus',
    ChangeOrderStatusSuccess = '[Order] ChangeOrderStatusSuccess',
    LoadOrderAction = '[Order Details] Load Order',
    OrderLoadedAction = '[Order Details] Order Loaded',
    SendVendorCopyAction = '[Order Details] Send Vendor Copy Action',
    SendBuyerCopyAction = '[Order Details] Send Buyer Copy Action',
    PrintOrder = '[Global] PrintOrder',
    EditOrder = '[OrderDetails] EditOrder',
    EditOrderLoaded = '[OrderDetails] EditOrderLoaded',
    CancelEditOrder = '[OrderDetails] CancelEditOrder',
    SaveEditOrder = '[OrderDetails] SaveEditOrder',
    EditOrderChangeQuantity = '[OrderDetails] EditOrderChangeQuantity',
    EditOrderChangeQuantitySuccess = '[OrderDetails] EditOrderChangeQuantitySuccess',
    LoadEditOrderProducts = '[OrderDetails] LoadEditOrderProducts',
    LoadEditOrderProductsSuccess = '[OrderDetails] LoadEditOrderProductsSuccess',
    SaveOrderEditNotes = '[Orderdetails] SaveOrderEditNotes',
    SaveOrderEditNotesSuccess = '[Orderdetails] SaveOrderEditNotesSuccess',
    UpdateOrderEdit = '[OrderDetails] UpdateOrderEdit',
    SaveOrderEditDelivery = '[OrderDetailEdit] SaveOrderEditDelivery',
    SaveOrderEditDeliverySuccess = '[OrderDetailEdit] SaveOrderEditDeliverySuccess',
    SaveOrderEditDeliveryError = '[OrderDetailEdit] SaveOrderEditDeliveryError',
    ReAuthorizeFundboxTransaction = '[OrderDetails] ReAuthorizeFundboxTransaction',
    UpdateOrderDetailsLoad = '[OrderDetails] UpdateOrderDetailsLoad',
    TriggerReAuthorizeFundboxOrder = '[Orders list] TriggerReAuthorizeFundboxOrder'
}

export class LoadNewOrdersCount implements Action {
    readonly type = OrderActionTypes.LoadNewOrdersCountAction
}

export class NewOrdersCountLoaded implements Action {
    readonly type = OrderActionTypes.NewOrdersCountLoadedAction

    constructor(public payload: { count: any }) { }
}

export class LoadOrders implements Action {
    readonly type = OrderActionTypes.LoadOrdersAction;

    constructor(public payload: { page: number, vendors: number[], statuses: number[], sortby: SortbyFilter}) { }
}

export class OrdersLoaded implements Action {
  readonly type = OrderActionTypes.OrdersLoadedAction;

  constructor(public payload: { orders: any }) { }
}

export class LoadStatuses implements Action {
    readonly type = OrderActionTypes.LoadStatusAction;
}

export class StatusesLoaded implements Action {
  readonly type = OrderActionTypes.StatusLoadedAction;

  constructor(public payload: { statuses: any[] }) { }
}

export class Reorder implements Action {
    readonly type = OrderActionTypes.Reorder;

    constructor(public payload: { orderId: number }) { }
}

export class ChangeOrderStatus implements Action {
    readonly type = OrderActionTypes.ChangeOrderStatus;

    constructor(public payload: { orderId: number, statusId: number }) { }
}

export class ChangeOrderStatusSuccess implements Action {
    readonly type = OrderActionTypes.ChangeOrderStatusSuccess;

    constructor(public payload: { orderId: number, statusId: number, availableStatuses: any[] }) { }
}
export class LoadOrder implements Action {
    readonly type = OrderActionTypes.LoadOrderAction;
    
  constructor(public payload: { order: number }) { }
}

export class OrderLoaded implements Action {
    readonly type = OrderActionTypes.OrderLoadedAction;
  
    constructor(public payload: { orderDetails: any }) { }
  }
export class SendVendorCopy implements Action {
    readonly type = OrderActionTypes.SendVendorCopyAction;

    constructor(public payload: { orderId: number }) { }
}
export class SendBuyerCopy implements Action {
    readonly type = OrderActionTypes.SendBuyerCopyAction;

    constructor(public payload: { orderId: number }) { }
}

export class PrintOrder implements Action {
    readonly type = OrderActionTypes.PrintOrder;

    constructor(public payload: { orderId: number }) { }
}

export class EditOrder implements Action {
    readonly type = OrderActionTypes.EditOrder;
    
    constructor(public payload: { orderId: number, cancelPreviousVersion?: boolean, checkPreviousVersion?: boolean }) { }
}

export class EditOrderLoaded implements Action {
    readonly type = OrderActionTypes.EditOrderLoaded;

    constructor(public payload: { editDetails: any }) { }
}

export class CancelEditOrder implements Action {
    readonly type = OrderActionTypes.CancelEditOrder;
    
    constructor(public payload: { orderId: number }) { }
}

export class SaveEditOrder implements Action {
    readonly type = OrderActionTypes.SaveEditOrder;

    constructor(public payload : {
        orderId: number,
        shoppingCartId: number
    }) { }
}

export class EditOrderChangeQuantity implements Action {
    readonly type = OrderActionTypes.EditOrderChangeQuantity;

    constructor(public payload: {
        orderId: number,
        quantity: number,
        productId: number,
        shoppingCartId: number,
    }) { }
}

export class EditOrderChangeQuantitySuccess implements Action {
    readonly type = OrderActionTypes.EditOrderChangeQuantitySuccess;

    constructor(public payload: {
        orderId: number,
        quantity: number,
        productId: number,
        shoppingCartId: number,
    }) { }
}

export class LoadEditOrderProducts implements Action {
    readonly type = OrderActionTypes.LoadEditOrderProducts;

    constructor(public payload: {
        orderId: number,
        vendorId: number,
        keyword: string
    }) { }
}

export class LoadEditOrderProductsSuccess implements Action {
    readonly type = OrderActionTypes.LoadEditOrderProductsSuccess;

    constructor(public payload: { products: any[] }) { }
}

export class SaveOrderEditNotes implements Action {
    readonly type = OrderActionTypes.SaveOrderEditNotes;

    constructor(public payload: { orderId: number, notes: string }) { }
}

export class SaveOrderEditDelivery implements Action {
    readonly type = OrderActionTypes.SaveOrderEditDelivery;

    constructor(public payload: { orderId: number, shoppingCartId: number, deliveryDate: any, deliveryFromTime: any, deliveryToTime: any   }) { }
}

export class SaveOrderEditDeliverySuccess implements Action {
    readonly type = OrderActionTypes.SaveOrderEditDeliverySuccess;

    constructor(public payload: { orderId: number, shoppingCartId: number, deliveryDate: any, deliveryFromTime: any, deliveryToTime: any   }) { }
}

export class SaveOrderEditDeliveryError implements Action {
    readonly type = OrderActionTypes.SaveOrderEditDeliveryError;

    constructor(public payload: { orderId: number, shoppingCartId: number, errorMessage: string }) { }
}

export class SaveOrderEditNotesSuccess implements Action {
    readonly type = OrderActionTypes.SaveOrderEditNotesSuccess;

    constructor(public payload: { orderId: number, notes: string }) {}
}

export class UpdateOrderEdit implements Action {
    readonly type = OrderActionTypes.UpdateOrderEdit;

    constructor(public payload: { orderDetails: any }) {}

}

export class ReAuthorizeFundboxTransaction implements Action {
    readonly type = OrderActionTypes.ReAuthorizeFundboxTransaction;

    constructor( public payload: { orderId: number, transactionId: number, fbxOrderToken: string  }) { }
}

export class UpdateOrderDetailsLoad implements Action {
    readonly type = OrderActionTypes.UpdateOrderDetailsLoad;

    constructor( public payload: { isLoading: boolean } ) { }
}

export class TriggerReAuthorizeFundboxOrder implements Action {
    readonly type = OrderActionTypes.TriggerReAuthorizeFundboxOrder;

    constructor( public payload: { orderId: number } ) { }
}


export type OrderActions = 
    LoadNewOrdersCount 
    | NewOrdersCountLoaded
    | LoadOrders 
    | OrdersLoaded 
    | LoadStatuses 
    | StatusesLoaded
    | Reorder
    | ChangeOrderStatus
    | ChangeOrderStatusSuccess
    | LoadOrder
    | OrderLoaded 
    | SendVendorCopy
    | SendBuyerCopy 
    | PrintOrder
    | EditOrder
    | SaveEditOrder
    | EditOrderLoaded
    | EditOrderChangeQuantity
    | EditOrderChangeQuantitySuccess
    | LoadEditOrderProducts
    | LoadEditOrderProductsSuccess
    | SaveOrderEditNotes
    | SaveOrderEditNotesSuccess
    | CancelEditOrder
    | UpdateOrderEdit
    | SaveOrderEditDelivery
    | SaveOrderEditDeliveryError
    | SaveOrderEditDeliverySuccess
    | ReAuthorizeFundboxTransaction
    | UpdateOrderDetailsLoad
    | TriggerReAuthorizeFundboxOrder;