import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { LoadCartSummary } from '../../actions/cart.actions';
import { cartSummary, loadingCartSummary } from '../../selectors/cart.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent implements OnInit {

  summary$: any;

  loading$: any;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.loading$ = this.store.pipe(select(loadingCartSummary));
    this.summary$ = this.store.pipe(select(cartSummary));
    this.store.dispatch(new LoadCartSummary());
  }

  get cartLink() {return `${environment.basePlatformUrl}/Dmx/Cart`; }

}
