import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { CouponValueType } from 'src/app/core/models/cupons/coupon-value-type.enum';
import { CouponModel } from 'src/app/core/models/cupons/coupon.model';
import { SaveCouponModel } from 'src/app/core/models/cupons/save-coupon.model';
import { SiteModel } from 'src/app/core/models/site.model';
import { SaveCoupon, SaveEditCoupon } from 'src/app/shared/actions/coupon.actions';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { getEditCoupon, loadingSaveCupon } from 'src/app/shared/selectors/coupon.selector';
import { getProductCategories } from 'src/app/shared/selectors/product.selector';
import { sitesList } from 'src/app/shared/selectors/site.selector';
import { CategoryModel } from '../../../core/models/product/category.model';

@Component({
    selector: 'app-add-edit-coupon',
    templateUrl: './add-edit-coupon.component.html',
    styleUrls: ['./add-edit-coupon.component.scss']
})
export class AddEditCouponComponent implements OnInit {

    @Input() set editMode(editMode: boolean) {
        this.isEditMode = editMode;
        if (this.form && !this.isEditMode) {
            this.form.reset();
            this.form.controls.selectedDate.setValue(this.getDefaultDate())
            this.form.updateValueAndValidity();
        }
    }

    isEditMode: boolean;
    form: FormGroup;
    loading$: Observable<boolean>;
    priceLevelList: Array<SiteModel>;
    categoryList: Array<CategoryModel>;
    search$ = new Subject<string>();
    searchPriceLevels: string;
    searchCategory: string;
    selectedPriceLevelIds: Array<number> = new Array<number>();
    selectedCategoryIds: Array<number> = new Array<number>();
    submitted: boolean;
    couponId: number;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.store.pipe(select(getEditCoupon)).subscribe((data: SaveCouponModel) => {
            if (data && this.editMode) {
                this.selectedCategoryIds = this.getCategoriesListIds(data.category);
                this.selectedPriceLevelIds = this.getPriceLevelsListIds(data.customers);
                this.couponId = data.id;
                this.createForm(data);
            } else if (!this.editMode) {
                this.createForm();
            }
        });

        this.loading$ = this.store.select(loadingSaveCupon);
        this.store.pipe(select(getProductCategories)).subscribe(data => {
            this.categoryList = data;
        });

        this.store.pipe(select(sitesList)).subscribe((data: Array<SiteModel>) => {
            this.priceLevelList = data;
        });

        this.search$.pipe(
            distinctUntilChanged()
        ).subscribe((order: any) => {
            this.searchPriceLevels = order;
        });
    }

    // tslint:disable-next-line:adjacent-overload-signatures
    get editMode() { return this.isEditMode; }

    createForm(editCoupon?: SaveCouponModel) {
        this.form = this.formBuilder.group({
            name: [editCoupon ? editCoupon.name : '', Validators.required],
            code: [editCoupon ? editCoupon.code : '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9%$-]{4,}$/)]],
            active: [editCoupon ? editCoupon.active : false, Validators.required],
            value: [editCoupon ? editCoupon.value : '', this.getValueValidators(editCoupon ?
                editCoupon.valueType : CouponValueType.Percentage)],
            valueType: [editCoupon ? editCoupon.valueType : CouponValueType.Percentage, Validators.required],
            minOrderAmount: [editCoupon ? editCoupon.minOrderAmount : '', Validators.pattern(/^[0-9]+$/)],

            maxOrderAmount: [editCoupon ? editCoupon.maxOrderAmount : '', editCoupon
                && editCoupon.minOrderAmount ? [Validators.min(editCoupon.minOrderAmount),
                Validators.pattern(/^[0-9]+$/)] : Validators.pattern(/^[0-9]+$/)],

            usageLimitPerCustomer: [editCoupon ? editCoupon.numberOfUsagesPerUser : '', Validators.pattern(/^[0-9]+$/)],
            usageLimit: [editCoupon ? editCoupon.numberOfUsagesPerCoupon : '', editCoupon
                && editCoupon.numberOfUsagesPerUser ? [Validators.required, Validators.pattern(/^[0-9]+$/),
                Validators.min(editCoupon.numberOfUsagesPerUser)] : Validators.pattern(/^[0-9]+$/)],
            applyOnlyToNewCustomers: [editCoupon ? editCoupon.applyOnlyToNewCustomers : false, Validators.required],
            priceLevels: [editCoupon ? this.getPriceLevelsListIds(editCoupon.customers) : []],
            categories: [editCoupon ? this.getCategoriesListIds(editCoupon.category) : []],
            searchPrice: [''],
            searchCategory: [''],
            selectedDate: [editCoupon ? [new Date(editCoupon.startDate), new Date(editCoupon.endDate)]
                : this.getDefaultDate(), Validators.required]
        });

        this.form.controls.searchPrice.valueChanges.subscribe((data: string) => {
            this.searchPriceLevels = data;
        });

        this.form.controls.searchCategory.valueChanges.subscribe((data: string) => {
            this.searchCategory = data;
        });

        this.form.controls.valueType.valueChanges.subscribe((data: number) => {
            const value = this.form.controls.value.value;
            this.form.controls.value.reset();
            this.form.controls.value.setValidators(this.getValueValidators(data));
            if (value) {
                this.form.controls.value.setValue(value);
            }
            this.form.updateValueAndValidity();
        });

        this.form.controls.usageLimitPerCustomer.valueChanges.subscribe((data: number) => {
            this.form.controls.usageLimit.clearValidators();
            const usageLimit = this.form.controls.usageLimit.value;
            this.form.controls.usageLimit.reset();
            if (data) {
                this.form.controls.usageLimit.setValidators([Validators.required, Validators.pattern(/^[0-9]+$/), Validators.min(data)]);
            } else {
                this.form.controls.usageLimit.clearValidators();
            }

            this.form.controls.usageLimit.setValue(usageLimit);
            this.form.controls.usageLimit.markAsTouched();
            this.form.updateValueAndValidity();
        });

        this.form.controls.minOrderAmount.valueChanges.subscribe((data: number) => {
            this.form.controls.maxOrderAmount.clearValidators();
            const maxOrderAmount = this.form.controls.maxOrderAmount.value;
            this.form.controls.maxOrderAmount.reset();
            if (data) {
                this.form.controls.maxOrderAmount.setValidators([Validators.min(data),
                Validators.pattern(/^[0-9]+$/)]);
            } else {
                this.form.controls.maxOrderAmount.setValidators(Validators.pattern(/^[0-9]+$/));
            }

            this.form.controls.maxOrderAmount.setValue(maxOrderAmount);
            this.form.controls.maxOrderAmount.markAsTouched();
            this.form.updateValueAndValidity();
        });
    }

    getDefaultDate(): Array<Date> {
        const startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        const endDate = new Date();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        endDate.setMilliseconds(999);

        return [startDate, endDate];
    }

    getValueValidators(valueType: CouponValueType) {
        if (valueType === CouponValueType.Percentage) {
            return [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.max(99)];
        }

        return [Validators.required, Validators.pattern(/^[0-9]+$/)];
    }

    getCategoriesListIds(categories: Array<any>): Array<number> {
        return categories.map(category => category.categoryId);
    }

    getPriceLevelsListIds(priceLevels: Array<any>): Array<number> {
        return priceLevels.filter(pl => pl.customerSiteId !== null).map(pl => pl.customerSiteId);
    }

    save() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const saveCoupon = new SaveCouponModel(this.form.value);

        if (this.editMode) {
            this.store.dispatch(new SaveEditCoupon({ couponId: this.couponId, coupon: saveCoupon }));
        } else {
            this.store.dispatch(new SaveCoupon(saveCoupon));
        }
    }

    getButtonTitle() {
        return this.editMode ? 'Save' : 'Add Coupon';
    }

    cancel() {
        this.store.dispatch(new ToggleInfobar({
            open: false,
        }));
    }

    selectPriceLevel(event: Array<number>) {
        this.selectedPriceLevelIds = event;
        this.form.controls.priceLevels.setValue(event);
        this.form.updateValueAndValidity();
    }

    selectCategory(event: Array<number>) {
        this.selectedCategoryIds = event;
        this.form.controls.categories.setValue(event);
        this.form.updateValueAndValidity();
    }

    getPriceLevelName(id: number) {
        return this.priceLevelList.find(priceLevel => priceLevel.id === id).name;
    }

    getCategoryName(id: number) {
        return this.categoryList.find(category => category.id === id).name;
    }

    removeSelectedPriceLevel(removedPriceLevelId: number) {
        const index = this.selectedPriceLevelIds.findIndex(id => id === removedPriceLevelId);

        if (index !== -1) {
            this.selectedPriceLevelIds.splice(index, 1);
            this.form.controls.priceLevels.setValue(this.selectedPriceLevelIds);
            this.form.updateValueAndValidity();
        }
    }

    removeSelectedCategory(removedCategoryId: number) {
        const index = this.selectedCategoryIds.findIndex(id => id === removedCategoryId);

        if (index !== -1) {
            this.selectedCategoryIds.splice(index, 1);
            this.form.controls.categories.setValue(this.selectedCategoryIds);
            this.form.updateValueAndValidity();
        }
    }

    allPriceLevels(deselect?: boolean) {
        if (deselect) {
            this.selectedPriceLevelIds = [];
        } else {
            this.selectedPriceLevelIds = [];
            this.priceLevelList.forEach(priceLevel => this.selectedPriceLevelIds.push(priceLevel.id));
        }

        this.form.controls.priceLevels.setValue(this.selectedPriceLevelIds);
        this.form.updateValueAndValidity();
    }

    allCategories(deselect?: boolean) {
        if (deselect) {
            this.selectedCategoryIds = [];
        } else {
            this.selectedCategoryIds = [];
            this.categoryList.forEach(category => this.selectedCategoryIds.push(category.id));
        }

        this.form.controls.categories.setValue(this.selectedCategoryIds);
        this.form.updateValueAndValidity();
    }

}
