import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  
  @Input() rating: number;
  @Input() iconCssClass: string;
  @Input() categoryId: number;
  @Input() clickable: boolean = false;

  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  ratingInfo: any;
  stars = [1,2,3,4,5];
  
  constructor(
    private store: Store<AppState>,  
  ) { }

  ngOnInit() { }

  onRatingClick(rating: number): void {
    if (!this.clickable) {
      return;
    }

    this.ratingClick.emit({
      rating: rating,
      categoryId: this.categoryId
    });
  }

}
