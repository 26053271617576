import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PAYMENT_STATUS } from '../../enums/payment-status.enum';
import { environment } from 'src/environments/environment';
import { of, Observable } from 'rxjs';
import { Search } from '../../models/search.model';
import { tap } from 'rxjs/operators';
import { PaymentSetting } from '../../models/payment/payment-setting.model';
import { PaymentSettingRequest } from '../../models/payment/payment-setting-request.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {

    constructor(
        private http: HttpClient
    ) { }

    updateTransactionStatus(transactionId: number, status: PAYMENT_STATUS, amount: number = undefined) {
        return this.http.post(`${environment.api.content}/Payment/transactions/${transactionId}/update_status`, {
            transactionId,
            status,
            amount
        });
    }

    reAuthorizeFundboxTransaction(fbxTransactionId: string, oldTransactionId: number, orderId: number) {
        return this.http.post(`${environment.api.content}/Payment/transactions/fbx_reauthorize`, {
            fbxTransactionId,
            transactionId: oldTransactionId,
            orderId
        });
    }

    loadProfiles() {
        return this.http.get(`${environment.api.content}/Payment/profiles`);
    }

    deleteProfile(profileId: number) {
        return this.http.delete(`${environment.api.content}/Payment/profiles/${profileId}`);
    }

    addCreditCard(creditCardDetails: any) {
        return this.http.post(`${environment.api.content}/Payment/profiles`, creditCardDetails);
    }

    loadTransactionHistry(transactionId: number) {
        return this.http.get(`${environment.api.content}/Payment/transactions/${transactionId}/history`);
    }

    loadTransactions(page = 1, filters: Search = {}) {
        return this.http.post(`${environment.api.content}/Payment/transactions`, {
            page,
            ...filters
        });
    }

    loadSummary() {
        return this.http.get(`${environment.api.content}/Payment/transactions/summary`);
    }

    markSettled(transactionId: number, isSettled: boolean) {
        return this.http.post(`${environment.api.content}/Payment/transactions/${transactionId}/mark_settled`, {
            isSettled
        });
    }

    loadSettings(): Observable<PaymentSetting[]> {
        return this.http.get<PaymentSetting[]>(`${environment.api.content}/Payment/payment_methods`);
    }

    updateSetting(settings: PaymentSettingRequest[], enable: boolean) {
        return this.http.put(`${environment.api.content}/Payment/methods`, {
            settings,
            enable
        });
    }

}
