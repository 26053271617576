import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { SubmitOrder } from 'src/app/shared/actions/cart.actions';
import { selectCart } from 'src/app/shared/selectors/cart.selector';
import { tap, map, takeUntil, filter, withLatestFrom } from 'rxjs/operators';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { LoadPaymentProfiles, DeletePaymentProfile } from 'src/app/shared/actions/payment.actions';
import { selectPaymentProfiles, selectPaymentProfilesLoading } from 'src/app/shared/selectors/payment.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {

  currentOrderSubmitting$: Observable<boolean>;
  paymentProfiles$: Observable<any[]>;
  selectedProfile: any;
  acceptsCreditCardPayment = false;
  showAddNewCreditCard = false;

  regularPaymentMethod: any;
  fundboxPaymentMethod: any;
  
  private fbxHandler: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    private dialog: MatDialog,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: { availablePaymentMethods: any[], vendorId: number, vendor: any },
    private store: Store<AppState>
  ) { 

    // configure fundbox
    this.fbxHandler = (window as any).FbxCheckout.configure({
      fbxKey: environment.FUNDBOX_CONFIG.KEY,
      env: environment.FUNDBOX_CONFIG.ENV,
      onComplete: (fbxOrderToken: string) => this.onAfterFbxCheckoutFinish(fbxOrderToken)
    });

  }

  ngOnInit() {
    this.currentOrderSubmitting$ = this.store.select(selectCart).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(details => details !== undefined),
      map(details => (details.find(d => d.id === this.data.vendorId)|| {}).loading)
    );

    this.paymentProfiles$ = this.store.select(selectPaymentProfiles).pipe(
      map((profiles: any[]) => {
        // add regular payment method if available from vendor
        const regularPm = this.data.availablePaymentMethods.find(apm => apm.id === PAYMENT_METHOD.REGULAR);
        if (regularPm && !profiles.find(p => p.paymentMethod === regularPm.id)) {
          this.regularPaymentMethod = {
            paymentMethod: regularPm.id,
            name: regularPm.name
          };
        }

        // add fundbox payment method if available from vendor
        const fundboxPm = this.data.availablePaymentMethods.find(apm => apm.id === PAYMENT_METHOD.FUNDBOX);
        if (fundboxPm) {
          const fundboxProfile = profiles.find(p => p.paymentMethod === fundboxPm.id);
          if (fundboxProfile) {
            this.fundboxPaymentMethod = {
              paymentMethod: fundboxPm.id,
              name: fundboxProfile.name,
              paymentProfileId: fundboxProfile.profileGuid,
              profileId: fundboxProfile.id
            };
          }
        }

        return profiles;
      }),
      tap(profiles => {
        if (!this.selectedProfile) {
          if (this.regularPaymentMethod) {
            this.selectedProfile = this.regularPaymentMethod;
          } else {
            this.selectedProfile = profiles[0];
          }
        }

        this.acceptsCreditCardPayment = profiles.find(p => p.paymentMethod === PAYMENT_METHOD.CREDIT_CARD) !== null;

        return profiles;
      }),
      map(profiles => profiles.filter(p => p.paymentMethod === PAYMENT_METHOD.CREDIT_CARD)),
      withLatestFrom(this.store.select(selectPaymentProfilesLoading)),
      map(([profiles, loading]) => {
        if (!loading && !this.selectedProfile) {
          this.showAddNewCreditCard = profiles.length === 0;
        }

        return profiles;
      })
    );

    this.store.dispatch(new LoadPaymentProfiles());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    if (!this.selectedProfile) {
      return;
    }

    if (this.selectedProfile.paymentMethod === PAYMENT_METHOD.FUNDBOX) {
      const { vendor } = this.data;
      const description = `Vendor name: ${vendor.name}, id: ${vendor.id}`;
      this.fbxHandler.open({
        ctaType: "confirm-and-pay",
        orderDetails: {
            amount_cents: (vendor.totalPrice * 100).toFixed(0),
            shipping_amount_cents: (0 * 100).toFixed(0),
            checkout_items: [{
              name: description,
              sku:  description,
              description:  description,
              total_amount_cents: (vendor.totalPrice * 100).toFixed(0),
              item_amount_cents: (vendor.totalPrice * 100).toFixed(0),
              quantity: 1
            }]
        }
      });
      return;
    }

    this.store.dispatch(new SubmitOrder({
      vendorId: this.data.vendorId,
      popNotifications: true,
      paymentMethod: this.selectedProfile.paymentMethod,
      paymentProfileId: this.selectedProfile.profileGuid,
      modalInstance: this.dialogRef
    }));
  }

  onDeleteProfile($event, profile) {
    $event.preventDefault();
    const confirmRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: `Are you sure you want to delete this credit card ?`
      },
    });
    confirmRef.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.store.dispatch(new DeletePaymentProfile({
          profileId: profile.id
        }));
      }
    });
  }

  isSelected(profile: any) {
    if (!this.selectedProfile) {
      return false;
    }

    return profile.id === this.selectedProfile.id && profile.paymentMethod === this.selectedProfile.paymentMethod;
  }

  onSelectedProfile(profile) {
    this.selectedProfile = profile;
  }

  onSubmitAddCreditCard(creditCardDetails: { creditCard: any, address: any, save: boolean }) {
    this.store.dispatch(new SubmitOrder({
      ...creditCardDetails,
      vendorId: this.data.vendorId,
      popNotifications: true,
      paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
      modalInstance: this.dialogRef
    }));
  }
  
  close(): void {
    this.dialogRef.close();
  }

  private onAfterFbxCheckoutFinish(fbxOrderToken: string) {
    // Bring code back into the zone so components are aware of state changes
    this.ngZone.run(() => {
      this.store.dispatch(new SubmitOrder({
        vendorId: this.data.vendorId,
        popNotifications: true,
        paymentMethod: this.selectedProfile.paymentMethod,
        paymentProfileId: this.selectedProfile.profileGuid,
        profileId: this.selectedProfile.profileId,
        fbxOrderToken
      }));
    });

  }

}
