import { Action } from '@ngrx/store';
import { PaymentSetting } from 'src/app/core/models/payment/payment-setting.model';
import { PaymentSettingRequest } from 'src/app/core/models/payment/payment-setting-request.model';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';

export enum PaymentActionTypes {
    AddCreditCard = '[Modal] AddCreditCard',
    AddCreditCardSuccess = '[Modal] AddCreditCardSuccess',
    AddCreditCardError = '[Modal] AddCreditCardError',
    LoadPaymentProfiles = '[Cart Payment Modal] LoadPaymentProfiles',
    LoadPaymentProfilesSuccess = '[Cart Payment Modal] LoadPaymentProfilesSuccess',
    DeletePaymentProfile = '[Global] DeletePaymentProfile',
    DeletePaymentProfileSuccess = '[Global] DeletePaymentProfileSuccess',
    DeletePaymentProfileError = '[Global] DeletePaymentProfileError',
    LoadTransactionHistory = '[Payment Info] LoadTransactionHistory',
    TransactionHistoryLoaded = '[Payment Info Effect] TransactionHistoryLoaded',
    LoadPaymentSettings = '[PaymentSettings] LoadPaymentSettings',
    LoadPaymentSettingsSuccess = '[PaymentSettings] LoadPaymentSettingsSuccess',
    UpdatePaymentSettings = '[PaymentSettings] UpdatePaymentSettings',
    UpdatePaymentSettingsSuccess = '[PaymentSettings] UpdatePaymentSettingsSuccess',
    UpdatePaymentSettingsBatch = '[PaymentSettings] UpdatePaymentSettingsBatch',
    UpdatePaymentSettingsBatchSuccess = '[PaymentSettings] UpdatePaymentSettingsBatchSuccess'
}

export class AddCreditCard implements Action {
    readonly type = PaymentActionTypes.AddCreditCard;

    constructor(public payload: { creditCard: any, address: any, modalInstance?: any }) { }
}

export class AddCreditCardSuccess implements Action {
    readonly type = PaymentActionTypes.AddCreditCardSuccess;

    constructor(public payload: { creditCardDetails: any }) { }
}

export class AddCreditCardError implements Action {
    readonly type = PaymentActionTypes.AddCreditCardError;
}


export class LoadPaymentProfiles implements Action {
    readonly type = PaymentActionTypes.LoadPaymentProfiles;
}

export class LoadPaymentProfilesSuccess implements Action {
    readonly type = PaymentActionTypes.LoadPaymentProfilesSuccess;

    constructor(public payload: { profiles: any[] }) { }
}

export class DeletePaymentProfile implements Action {
    readonly type = PaymentActionTypes.DeletePaymentProfile;

    constructor(public payload: { profileId: any }) { }
}

export class DeletePaymentProfileSuccess implements Action {
    readonly type = PaymentActionTypes.DeletePaymentProfile;

    constructor(public payload: { profileId: any }) { }
}

export class DeletePaymentProfileError implements Action {
    readonly type = PaymentActionTypes.DeletePaymentProfile;

    constructor(public payload: { profileId: any }) { }
}

export class LoadTransactionHistory implements Action {
    readonly type = PaymentActionTypes.LoadTransactionHistory;

    constructor(public payload: { transactionId: number }) { }
}

export class TransactionHistoryLoaded implements Action {
    readonly type = PaymentActionTypes.TransactionHistoryLoaded;

    constructor(public payload: { transactionHistory: any[] }) { }
}

export class LoadPaymentSettings implements Action {
    readonly type = PaymentActionTypes.LoadPaymentSettings;
}

export class LoadPaymentSettingsSuccess implements Action {
    readonly type = PaymentActionTypes.LoadPaymentSettingsSuccess;

    constructor(public payload: { settings: PaymentSetting[] }) { }
}

export class UpdatePaymentSettings implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettings;

    constructor(public payload: { settings: PaymentSettingRequest[], enable: boolean }) { }
}

export class UpdatePaymentSettingsSuccess implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettingsSuccess;

    constructor(public payload: { settings: PaymentSettingRequest[], enable: boolean }) { }
}

export class UpdatePaymentSettingsBatch implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettingsBatch;

    constructor(public payload: { paymentMethod: PAYMENT_METHOD, enable: boolean }) { }
}

export class UpdatePaymentSettingsBatchSuccess implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettingsBatchSuccess;

    constructor(public payload: { paymentMethod: PAYMENT_METHOD, enable: boolean }) { }
}

export type PaymentAction =
    AddCreditCard
    | AddCreditCardSuccess
    | AddCreditCardError
    | LoadPaymentProfiles
    | LoadPaymentProfilesSuccess
    | DeletePaymentProfile
    | DeletePaymentProfileSuccess
    | DeletePaymentProfileError
    | LoadTransactionHistory
    | TransactionHistoryLoaded
    | LoadPaymentSettings
    | LoadPaymentSettingsSuccess
    | UpdatePaymentSettings
    | UpdatePaymentSettingsSuccess
    | UpdatePaymentSettingsBatch
    | UpdatePaymentSettingsBatchSuccess;