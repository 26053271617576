import { OrderActions, OrderActionTypes } from '../actions/order.actions';
import { Statement } from '@angular/compiler';

export interface OrderState {
    newOrdersCount: number,
    loading: boolean,
    orders: any,
    statuses: any[],
    orderDetails: any;
    loadingOrderDetails: boolean;
    editProducts: any[];
    triggerFbxReAuthorization?: boolean;
}

export const initialState: OrderState = {
    newOrdersCount: 0,
    loading: false,
    orders: {},
    statuses: [],
    orderDetails: undefined,
    loadingOrderDetails: false,
    editProducts: [],
    triggerFbxReAuthorization: false
};

export function orderReducer(state = initialState,
    action: OrderActions): OrderState {
    switch (action.type) {
        case OrderActionTypes.NewOrdersCountLoadedAction:
            return {
                ...state,
                newOrdersCount: action.payload.count
            };
        case OrderActionTypes.LoadOrdersAction:
            return {
                ...state,
                loading: true
            };
        case OrderActionTypes.OrdersLoadedAction:
            return {
                ...state,
                loading: false,
                orders: action.payload.orders
            };
        case OrderActionTypes.StatusLoadedAction:
            return {
                ...state,
                statuses: action.payload.statuses
            };
        case OrderActionTypes.ChangeOrderStatusSuccess:
            const newOrders = state.orders.items ? {
                ...state.orders,
                items: state.orders.items.map(o => ({
                    ...o,
                    status: o.id === action.payload.orderId ? o.availableStatuses.find(s => s.id === action.payload.statusId) : o.status,
                    availableStatuses: o.id === action.payload.orderId ? action.payload.availableStatuses : o.availableStatuses
                }))
            } : state.orders;

            const newOrderDetails = state.orderDetails ? {
                ...state.orderDetails,
                availableStatuses: state.orderDetails.id === action.payload.orderId ? action.payload.availableStatuses : state.orderDetails.availableStatuses,
                status: state.orderDetails.id === action.payload.orderId ? state.orderDetails.availableStatuses.find(s => s.id === action.payload.statusId) : state.orderDetails.status
            } : state.orderDetails;

            return {
                ...state,
                orders: newOrders,
                orderDetails: newOrderDetails
            };
        case OrderActionTypes.LoadOrderAction:
            return {
                ...state,
                loadingOrderDetails: true
            };
        case OrderActionTypes.OrderLoadedAction:
            return {
                ...state,
                loadingOrderDetails: false,
                triggerFbxReAuthorization: false,
                orderDetails: { ...action.payload.orderDetails, isEditMode: false, triggerFbxReAuthorization: state.triggerFbxReAuthorization }
            };
            
        case OrderActionTypes.EditOrder:
            return {
                ...state,
                loadingOrderDetails: true
            };
        case OrderActionTypes.EditOrderLoaded:
            const { payload } = action;

            const editOrderDetails = {
                ...state.orderDetails,
                totalPrice: action.payload.editDetails.totalPrice,
                subTotalPrice: action.payload.editDetails.subTotalPrice,
                totalShippingPrice: action.payload.editDetails.deliveryFee,
                shoppingCartId: payload.editDetails.shoppingCartId,
                notes: action.payload.editDetails.notes,
                deliveryDate: action.payload.editDetails.deliveryDate,
                deliveryFromTime: action.payload.editDetails.deliveryFromTime,
                deliveryToTime: action.payload.editDetails.deliveryToTime,
                isEditMode: true
            };

            // check if we need to add new items
            const currentOrderProducts = state.orderDetails.items.map(i => i.productId);
            const newEditItems = action.payload.editDetails.items.filter(i => !currentOrderProducts.includes(i.productId));
            if (newEditItems.length) {
                for(let ni of newEditItems) {
                    editOrderDetails.items.push({
                        productId: ni.productId,
                        quantity: ni.quantity,
                        price: ni.price,
                        sku: ni.sku,
                        sellerSku: ni.vendorSku,
                        uomSize: ni.uomSize,
                        uomName: ni.uomName,
                        uomDescription: ni.uomDescription,
                        itemName: ni.name,
                        customName: ni.customName,
                        totalShippingPrice: 0,
                        totalPrice: ni.totalPrice,
                        imageUrl: ni.imageUrl,
                        imageUrlSmall: ni.imageUrl,
                        priceUom: ni.priceUom,
                        historyChange: null
                    });
                }
            }

            editOrderDetails.items = editOrderDetails.items.map((i) => {
                const editItem = payload.editDetails.items.find(ei => ei.productId === i.productId);
                
                if (editItem) {
                    i.shoppingCartItemId = editItem.shoppingCartItemId;
                    i.vendor = editItem.vendor;
                }

                return i;
            })

            return {
                ...state,
                loadingOrderDetails: false,
                orderDetails: editOrderDetails
            };
        case OrderActionTypes.EditOrderChangeQuantitySuccess:
            const updatedOrderDetails = {
                ...state.orderDetails
            };

            if (action.payload.quantity > 0) {
                updatedOrderDetails.items = updatedOrderDetails.items.map(i => ({
                    ...i,
                    vendor : {
                        ...i.vendor,
                        quantity: i.productId === action.payload.productId ? action.payload.quantity : i.vendor.quantity
                    }
                }));
            } else {
                updatedOrderDetails.items = updatedOrderDetails.items.filter(i => i.productId !== action.payload.productId);
            }

            return {
                ...state,
                orderDetails: updatedOrderDetails
            };
        case OrderActionTypes.LoadEditOrderProductsSuccess:
            return {
                ...state,
                editProducts: action.payload.products
            };
        case OrderActionTypes.SaveOrderEditNotesSuccess:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    notes: action.payload.notes
                }
            };
        case OrderActionTypes.UpdateOrderEdit:
            const newEditOrderDetails = {
                ...state.orderDetails,
                totalPrice: action.payload.orderDetails.totalPrice,
                subTotalPrice: action.payload.orderDetails.subTotalPrice,
                totalShippingPrice: action.payload.orderDetails.deliveryFee
            };

            // check if we need to add new items
            const currentProducts = state.orderDetails.items.map(i => i.productId);
            const newItems = action.payload.orderDetails.items.filter(i => !currentProducts.includes(i.productId));
            if (newItems.length) {
                for(let ni of newItems) {
                    newEditOrderDetails.items.push({
                        productId: ni.productId,
                        quantity: ni.quantity,
                        price: ni.price,
                        sku: ni.sku,
                        sellerSku: ni.vendorSku,
                        uomSize: ni.uomSize,
                        uomName: ni.uomName,
                        uomDescription: ni.uomDescription,
                        itemName: ni.name,
                        customName: ni.customName,
                        totalShippingPrice: 0,
                        totalPrice: ni.totalPrice,
                        imageUrl: ni.imageUrl,
                        imageUrlSmall: ni.imageUrl,
                        priceUom: ni.priceUom,
                        historyChange: null
                    });
                }
            }

            // update items with edit mode fields
            newEditOrderDetails.items.map((i) => {
                const editItem = action.payload.orderDetails.items.find(ei => ei.productId === i.productId);
                
                if (editItem) {
                    i.shoppingCartItemId = editItem.shoppingCartItemId;
                    i.vendor = editItem.vendor;
                }

                return i;
            });

            return {
                ...state,
                loadingOrderDetails: false,
                orderDetails: newEditOrderDetails
            };
        case OrderActionTypes.SaveOrderEditDeliveryError:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    validationMessages: [action.payload.errorMessage]
                }
            };
        case OrderActionTypes.SaveOrderEditDeliverySuccess:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    validationMessages: [],
                    deliveryDate: action.payload.deliveryDate,
                    deliveryFromTime:  action.payload.deliveryFromTime, 
                    deliveryToTime: action.payload.deliveryToTime
                }
            };
        case OrderActionTypes.SaveEditOrder:
            return {
                ...state,
                loadingOrderDetails: true
            }
        case OrderActionTypes.ReAuthorizeFundboxTransaction:
            return {
                ...state,
                loadingOrderDetails: true
            };
        case OrderActionTypes.UpdateOrderDetailsLoad:
            return {
                ...state,
                loadingOrderDetails: action.payload.isLoading
            };
        case OrderActionTypes.TriggerReAuthorizeFundboxOrder:
            return {
                ...state,
                triggerFbxReAuthorization: true
            };
        default:
            return state;
    }
}