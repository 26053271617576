import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconCartComponent } from './icon-cart/icon-cart.component';
import { IconBellComponent } from './icon-bell/icon-bell.component';
import { IconChatComponent } from './icon-chat/icon-chat.component';
import { IconLocationComponent } from './icon-location/icon-location.component';
import { IconSearchComponent } from './icon-search/icon-search.component';
import { IconArrowDownComponent } from './icon-arrow-down/icon-arrow-down.component';
import { IconArrowUpComponent } from './icon-arrow-up/icon-arrow-up.component';
import { IconMenuComponent } from './icon-menu/icon-menu.component';
import { IconHomeComponent } from './icon-home/icon-home.component';
import { IconProductsComponent } from './icon-products/icon-products.component';
import { IconInventoryComponent } from './icon-inventory/icon-inventory.component';
import { IconOrdersComponent } from './icon-orders/icon-orders.component';
import { IconVendorsComponent } from './icon-vendors/icon-vendors.component';
import { IconAnalyticsComponent } from './icon-analytics/icon-analytics.component';
import { IconCompanyComponent } from './icon-company/icon-company.component';
import { IconDashboardComponent } from './icon-dashboard/icon-dashboard.component';
import { IconPlayComponent } from './icon-play/icon-play.component';
import { IconHelpComponent } from './icon-help/icon-help.component';
import { IconShareComponent } from './icon-share/icon-share.component';
import { IconLogoutComponent } from './icon-logout/icon-logout.component';
import { IconCloseComponent } from './icon-close/icon-close.component';
import { IconDocumentComponent } from './icon-document/icon-document.component';
import { IconSupportComponent } from './icon-support/icon-support.component';
import { IconSidebarArrowComponent } from './icon-sidebar-arrow/icon-sidebar-arrow.component';
import { IconDownloadComponent } from './icon-download/icon-download.component';
import { IconGrowthComponent } from './icon-growth/icon-growth.component';
import { IconDecreaseComponent } from './icon-decrease/icon-decrease.component';
import { IconSteadyComponent } from './icon-steady/icon-steady.component';
import { IconPaymentsComponent } from './icon-payments/icon-payments.component';
import { IconCreditCardComponent } from './icon-credit-card/icon-credit-card.component';
import { IconBankComponent } from './icon-bank/icon-bank.component';
import { IconStarComponent } from './icon-star/icon-star.component';
import { IconTagComponent } from './icon-tag/icon-tag.component';
import { IconGridComponent } from './icon-grid/icon-grid.component';
import { IconMinusComponent } from './icon-minus/icon-minus.component';
import { IconPlusComponent } from './icon-plus/icon-plus.component';
import { IconPrintComponent } from './icon-print/icon-print.component';
import { IconSortAzComponent } from './icon-sort-az/icon-sort-az.component';
import { IconSortRelevanceComponent } from './icon-sort-relevance/icon-sort-relevance.component';
import { IconSortRecentComponent } from './icon-sort-recent/icon-sort-recent.component';
import { IconCheckCircleComponent } from './icon-check-circle/icon-check-circle.component';
import { IconRevertComponent } from './icon-revert/icon-revert.component';
import { IconBeveragesComponent } from './icon-beverages/icon-beverages.component';
import { IconBreadComponent } from './icon-bread/icon-bread.component';
import { IconChinaComponent } from './icon-china/icon-china.component';
import { IconDairyComponent } from './icon-dairy/icon-dairy.component';
import { IconFreshpressedjuiceComponent } from './icon-freshpressedjuice/icon-freshpressedjuice.component';
import { IconGroceryComponent } from './icon-grocery/icon-grocery.component';
import { IconMeatComponent } from './icon-meat/icon-meat.component';
import { IconOfficesuppliesComponent } from './icon-officesupplies/icon-officesupplies.component';
import { IconPapergoodsComponent } from './icon-papergoods/icon-papergoods.component';
import { IconPastryComponent } from './icon-pastry/icon-pastry.component';
import { IconProduceComponent } from './icon-produce/icon-produce.component';
import { IconSeafoodComponent } from './icon-seafood/icon-seafood.component';
import { IconAllCategoriesComponent } from './icon-all-categories/icon-all-categories.component';
import { IconEditComponent } from './icon-edit/icon-edit.component';
import { IconCheckComponent } from './icon-check/icon-check.component';
import { IconDeleteComponent } from './icon-delete/icon-delete.component';
import { IconSubmitComponent } from './icon-submit/icon-submit.component';
import { IconExpandComponent } from './icon-expand/icon-expand.component';
import { IconCalendarComponent } from './icon-calendar/icon-calendar.component';
import { IconOrderDetailsComponent } from './icon-order-details/icon-order-details.component';
import { IconNotesComponent } from './icon-notes/icon-notes.component';
import { IconCheckNoBgComponent } from './icon-check-no-bg/icon-check-no-bg.component';
import { IconCartEmptyComponent } from './icon-cart-empty/icon-cart-empty.component';
import { IconMessageOutComponent } from './icon-message-out/icon-message-out.component';
import { IconMessageInComponent } from './icon-message-in/icon-message-in.component';
import { IconReportsComponent } from './icon-reports/icon-reports.component';
import { IconLogoComponent } from './icon-logo/icon-logo.component';
import { IconBricksComponent } from './icon-bricks/icon-bricks.component';
import { IconOrderEditComponent } from './icon-order-edit/icon-order-edit.component';
import { IconSaveComponent } from './icon-save/icon-save.component';
import { IconDontSaveComponent } from './icon-dont-save/icon-dont-save.component';
import { IconBuildingMaterialsComponent } from './icon-building-materials/icon-building-materials.component';
import { IconElectricalComponent } from './icon-electrical/icon-electrical.component';
import { IconFlooringAreaRugsComponent } from './icon-flooring-area-rugs/icon-flooring-area-rugs.component';
import { IconKitchenKitchenwareComponent } from './icon-kitchen-kitchenware/icon-kitchen-kitchenware.component';
import { IconPaintComponent } from './icon-paint/icon-paint.component';
import { IconPlumbingComponent } from './icon-plumbing/icon-plumbing.component';
import { IconToolsComponent } from './icon-tools/icon-tools.component';
import { IconBathFaucetsComponent } from './icon-bath-faucets/icon-bath-faucets.component';
import { IconMaintenanceComponent } from './icon-maintenance/icon-maintenance.component';
import { IconDoorsWindowsComponent } from './icon-doors-windows/icon-doors-windows.component';
import { IconHardwareComponent } from './icon-hardware/icon-hardware.component';
import { IconHeatingCoolingComponent } from './icon-heating-cooling/icon-heating-cooling.component';
import { IconTilesComponent } from './icon-tiles/icon-tiles.component';
import { IconLightningCeilingFansComponent } from './icon-lightning-ceiling-fans/icon-lightning-ceiling-fans.component';
import { IconPlusCircleComponent } from './icon-plus-circle/icon-plus-circle.component';
import { IconHoldComponent } from './icon-hold/icon-hold.component';
import { IconCheckGrayComponent } from './icon-check-gray/icon-check-gray.component';
import { IconApplyComponent } from './icon-apply/icon-apply.component';
import { IconShowComponent } from './icon-show/icon-show.component';
import { IconHideComponent } from './icon-hide/icon-hide.component';
import { IconAddCreditCardComponent } from './icon-add-credit-card/icon-add-credit-card.component';
import { IconArrowLeftComponent } from './icon-arrow-left/icon-arrow-left.component';
import { IconSubmitAndPayComponent } from './icon-submit-and-pay/icon-submit-and-pay.component';
import { IconCommunityComponent } from './icon-community/icon-community.component';
import { IconPaidComponent } from './icon-paid/icon-paid.component';
import { IconTeamComponent } from './icon-team/icon-team.component';
import { IconPhoneComponent } from './icon-phone/icon-phone.component';
import { IconEmailComponent } from './icon-email/icon-email.component';
import { IconNoLocationComponent } from './icon-no-location/icon-no-location.component';
import { IconFilterComponent } from './icon-filter/icon-filter.component';
import { IconNotPaidComponent } from './icon-not-paid/icon-not-paid.component';
import { IconPaidWithFundboxComponent } from './icon-paid-with-fundbox/icon-paid-with-fundbox.component';
import { IconDraftComponent } from './icon-draft/icon-draft.component';
import { IconProcessDraftComponent } from './icon-process-draft/icon-process-draft.component';
import { IconWarningComponent } from './icon-warning/icon-warning.component';
import { IconAccountComponent } from './icon-account/icon-account.component';
import { IconSettingsComponent } from './icon-settings/icon-settings.component';
import { IconSendMessageComponent } from './icon-send-message/icon-send-message.component';
import { IconNewConversationComponent } from './new-conversation/icon-new-conversation.component';
import { IconRequestComponent } from './icon-request/icon-request.component';
import { IconGroupComponent } from './icon-group/icon-group.component';
import { IconMainProductComponent } from './icon-main-product/icon-main-product.component';
import { IconUploadComponent } from './icon-upload/icon-upload.component';
import { IconMarketComponent } from './icon-market/icon-market.component';
import { IconLeadsComponent } from './icon-leads/icon-leads.component';
import { IconInviteComponent } from './icon-invite/icon-invite.component';
import { IconAccountSettingsComponent } from './icon-account-settings/icon-account-settings.component';
import { IconAccountAddComponent } from './icon-account-add/icon-account-add.component';
import { IconArrowRightComponent } from './icon-arrow-right/icon-arrow-right.component';
import { IconChatCleanComponent } from './icon-chat-clean/icon-chat-clean.component';
import { IconAttachmentComponent } from './icon-attachment/icon-attachment.component';
import { IconFileComponent } from './icon-file/icon-file.component';
import { IconExportComponent } from './icon-export/icon-export.component';
import { IconLockComponent } from './icon-lock/icon-lock.component';
import { IconUnlockComponent } from './icon-unlock/icon-unlock.component';
import { IconPriceLevelsComponent } from './icon-price-levels/icon-price-levels.component';
import { IconProductDetailsComponent } from './icon-product-details/icon-product-details.component';
import { IconResendComponent } from './icon-resend/icon-resend.component';
import { IconSmallArrowComponent } from './icon-small-arrow/icon-small-arrow.component';
import { IconProductVariationComponent } from './icon-product-variation/icon-product-variation.component';
import { IconVerifiedComponent } from './icon-verified/icon-verified.component';
import { IconTimePickerComponent } from './icon-time-picker/icon-time-picker.component';
import { IconArchiveComponent } from './icon-archive/icon-archive.component';
import { IconPriceRequestsComponent } from './icon-price-requests/icon-price-requests.component';
import { IconOrdersAmastComponent } from './icon-orders-amast/icon-orders-amast.component';
import { IconVerificationEmailComponent } from './icon-verification-email/icon-verification-email.component';
import { IconCuponsComponent } from './icon-cupons/icon-cupons.component';
import { IconHasCouponComponent } from './icon-has-coupon/icon-has-coupon.component';
import { IconEditNewComponent } from './icon-edit-new/icon-edit-new.component';
import { IconRemoveSelectedComponent } from './icon-remove-selected/icon-remove-selected.component';
import { IconZipCodeComponent } from './icon-zip-code/icon-zip-code.component';



@NgModule({
    declarations: [
        IconCartComponent,
        IconBellComponent,
        IconChatComponent,
        IconLocationComponent,
        IconSearchComponent,
        IconArrowDownComponent,
        IconArrowUpComponent,
        IconMenuComponent,
        IconHomeComponent,
        IconProductsComponent,
        IconInventoryComponent,
        IconOrdersComponent,
        IconVendorsComponent,
        IconAnalyticsComponent,
        IconCompanyComponent,
        IconDashboardComponent,
        IconPlayComponent,
        IconHelpComponent,
        IconShareComponent,
        IconLogoutComponent,
        IconCloseComponent,
        IconDocumentComponent,
        IconSupportComponent,
        IconSidebarArrowComponent,
        IconDownloadComponent,
        IconGrowthComponent,
        IconDecreaseComponent,
        IconSteadyComponent,
        IconPaymentsComponent,
        IconCreditCardComponent,
        IconBankComponent,
        IconStarComponent,
        IconTagComponent,
        IconGridComponent,
        IconMinusComponent,
        IconPlusComponent,
        IconPrintComponent,
        IconSortAzComponent,
        IconSortRelevanceComponent,
        IconSortRecentComponent,
        IconCheckCircleComponent,
        IconRevertComponent,
        IconBeveragesComponent,
        IconBreadComponent,
        IconChinaComponent,
        IconDairyComponent,
        IconFreshpressedjuiceComponent,
        IconGroceryComponent,
        IconMeatComponent,
        IconOfficesuppliesComponent,
        IconPapergoodsComponent,
        IconPastryComponent,
        IconProduceComponent,
        IconSeafoodComponent,
        IconAllCategoriesComponent,
        IconEditComponent,
        IconCheckComponent,
        IconDeleteComponent,
        IconSubmitComponent,
        IconExpandComponent,
        IconCalendarComponent,
        IconOrderDetailsComponent,
        IconNotesComponent,
        IconCheckNoBgComponent,
        IconCartEmptyComponent,
        IconMessageOutComponent,
        IconMessageInComponent,
        IconReportsComponent,
        IconLogoComponent,
        IconBricksComponent,
        IconOrderEditComponent,
        IconSaveComponent,
        IconDontSaveComponent,
        IconBuildingMaterialsComponent,
        IconElectricalComponent,
        IconFlooringAreaRugsComponent,
        IconKitchenKitchenwareComponent,
        IconPaintComponent,
        IconPlumbingComponent,
        IconToolsComponent,
        IconBathFaucetsComponent,
        IconMaintenanceComponent,
        IconDoorsWindowsComponent,
        IconHardwareComponent,
        IconHeatingCoolingComponent,
        IconTilesComponent,
        IconLightningCeilingFansComponent,
        IconPlusCircleComponent,
        IconHoldComponent,
        IconCheckGrayComponent,
        IconApplyComponent,
        IconShowComponent,
        IconHideComponent,
        IconAddCreditCardComponent,
        IconArrowLeftComponent,
        IconSubmitAndPayComponent,
        IconCommunityComponent,
        IconPaidComponent,
        IconTeamComponent,
        IconPhoneComponent,
        IconEmailComponent,
        IconNoLocationComponent,
        IconFilterComponent,
        IconNotPaidComponent,
        IconPaidWithFundboxComponent,
        IconDraftComponent,
        IconProcessDraftComponent,
        IconWarningComponent,
        IconAccountComponent,
        IconSettingsComponent,
        IconSendMessageComponent,
        IconNewConversationComponent,
        IconRequestComponent,
        IconGroupComponent,
        IconMainProductComponent,
        IconUploadComponent,
        IconMarketComponent,
        IconLeadsComponent,
        IconInviteComponent,
        IconAccountSettingsComponent,
        IconAccountAddComponent,
        IconArrowRightComponent,
        IconChatCleanComponent,
        IconAttachmentComponent,
        IconFileComponent,
        IconExportComponent,
        IconLockComponent,
        IconUnlockComponent,
        IconPriceLevelsComponent,
        IconProductDetailsComponent,
        IconResendComponent,
        IconSmallArrowComponent,
        IconProductVariationComponent,
        IconResendComponent,
        IconVerifiedComponent,
        IconTimePickerComponent,
        IconArchiveComponent,
        IconArchiveComponent,
        IconPriceRequestsComponent,
        IconOrdersAmastComponent,
        IconVerificationEmailComponent,
        IconCuponsComponent,
        IconHasCouponComponent,
        IconEditNewComponent,
        IconRemoveSelectedComponent,
        IconHasCouponComponent,
        IconZipCodeComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        IconCartComponent,
        IconBellComponent,
        IconChatComponent,
        IconLocationComponent,
        IconSearchComponent,
        IconArrowDownComponent,
        IconArrowUpComponent,
        IconMenuComponent,
        IconHomeComponent,
        IconProductsComponent,
        IconInventoryComponent,
        IconOrdersComponent,
        IconVendorsComponent,
        IconAnalyticsComponent,
        IconCompanyComponent,
        IconDashboardComponent,
        IconPlayComponent,
        IconHelpComponent,
        IconShareComponent,
        IconLogoutComponent,
        IconCloseComponent,
        IconDocumentComponent,
        IconSupportComponent,
        IconSidebarArrowComponent,
        IconDownloadComponent,
        IconGrowthComponent,
        IconDecreaseComponent,
        IconSteadyComponent,
        IconPaymentsComponent,
        IconCreditCardComponent,
        IconBankComponent,
        IconStarComponent,
        IconTagComponent,
        IconGridComponent,
        IconMinusComponent,
        IconPlusComponent,
        IconPrintComponent,
        IconSortAzComponent,
        IconSortRelevanceComponent,
        IconSortRecentComponent,
        IconRevertComponent,
        IconCheckCircleComponent,
        IconBeveragesComponent,
        IconBreadComponent,
        IconChinaComponent,
        IconDairyComponent,
        IconFreshpressedjuiceComponent,
        IconGroceryComponent,
        IconMeatComponent,
        IconOfficesuppliesComponent,
        IconPapergoodsComponent,
        IconPastryComponent,
        IconProduceComponent,
        IconSeafoodComponent,
        IconAllCategoriesComponent,
        IconEditComponent,
        IconCheckComponent,
        IconDeleteComponent,
        IconSubmitComponent,
        IconExpandComponent,
        IconCalendarComponent,
        IconOrderDetailsComponent,
        IconNotesComponent,
        IconCheckNoBgComponent,
        IconCartEmptyComponent,
        IconMessageOutComponent,
        IconMessageInComponent,
        IconReportsComponent,
        IconLogoComponent,
        IconBricksComponent,
        IconOrderEditComponent,
        IconSaveComponent,
        IconDontSaveComponent,
        IconDontSaveComponent,
        IconBuildingMaterialsComponent,
        IconElectricalComponent,
        IconFlooringAreaRugsComponent,
        IconKitchenKitchenwareComponent,
        IconPaintComponent,
        IconPlumbingComponent,
        IconToolsComponent,
        IconBathFaucetsComponent,
        IconMaintenanceComponent,
        IconDoorsWindowsComponent,
        IconHardwareComponent,
        IconHeatingCoolingComponent,
        IconTilesComponent,
        IconLightningCeilingFansComponent,
        IconToolsComponent,
        IconPlusCircleComponent,
        IconHoldComponent,
        IconCheckGrayComponent,
        IconApplyComponent,
        IconShowComponent,
        IconHideComponent,
        IconAddCreditCardComponent,
        IconArrowLeftComponent,
        IconSubmitAndPayComponent,
        IconCommunityComponent,
        IconPaidComponent,
        IconTeamComponent,
        IconPhoneComponent,
        IconEmailComponent,
        IconNoLocationComponent,
        IconFilterComponent,
        IconNotPaidComponent,
        IconPaidWithFundboxComponent,
        IconDraftComponent,
        IconProcessDraftComponent,
        IconWarningComponent,
        IconAccountComponent,
        IconSettingsComponent,
        IconSendMessageComponent,
        IconNewConversationComponent,
        IconGroupComponent,
        IconMainProductComponent,
        IconUploadComponent,
        IconRequestComponent,
        IconMarketComponent,
        IconLeadsComponent,
        IconInviteComponent,
        IconAccountSettingsComponent,
        IconAccountAddComponent,
        IconArrowRightComponent,
        IconChatCleanComponent,
        IconAttachmentComponent,
        IconFileComponent,
        IconExportComponent,
        IconLockComponent,
        IconUnlockComponent,
        IconPriceLevelsComponent,
        IconProductDetailsComponent,
        IconResendComponent,
        IconSmallArrowComponent,
        IconProductVariationComponent,
        IconResendComponent,
        IconVerifiedComponent,
        IconTimePickerComponent,
        IconArchiveComponent,
        IconArchiveComponent,
        IconPriceRequestsComponent,
        IconOrdersAmastComponent,
        IconVerificationEmailComponent,
        IconCuponsComponent,
        IconHasCouponComponent,
        IconEditNewComponent,
        IconRemoveSelectedComponent,
        IconHasCouponComponent,
        IconZipCodeComponent
    ],
    entryComponents: [
        IconVendorsComponent,
        IconBeveragesComponent,
        IconBreadComponent,
        IconChinaComponent,
        IconDairyComponent,
        IconFreshpressedjuiceComponent,
        IconGroceryComponent,
        IconMeatComponent,
        IconOfficesuppliesComponent,
        IconPapergoodsComponent,
        IconPastryComponent,
        IconProduceComponent,
        IconSeafoodComponent,
        IconAllCategoriesComponent,
        IconEditComponent,
        IconCheckComponent,
        IconDeleteComponent,
        IconDontSaveComponent,
        IconBuildingMaterialsComponent,
        IconElectricalComponent,
        IconFlooringAreaRugsComponent,
        IconKitchenKitchenwareComponent,
        IconPaintComponent,
        IconPlumbingComponent,
        IconToolsComponent,
        IconBathFaucetsComponent,
        IconMaintenanceComponent,
        IconDoorsWindowsComponent,
        IconHardwareComponent,
        IconHeatingCoolingComponent,
        IconTilesComponent,
        IconLightningCeilingFansComponent,
        IconAddCreditCardComponent,
        IconArrowLeftComponent,
        IconSubmitAndPayComponent,
        IconPaidComponent,
        IconNotPaidComponent,
        IconPaidWithFundboxComponent,
        IconDraftComponent,
        IconProcessDraftComponent
    ]
})
export class IconsModule { }
