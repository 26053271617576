import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadStates, LoadCountries } from 'src/app/shared/actions/utils.actions';
import { selectStates, selectCountries } from '../../../shared/selectors/utils.selector';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MustMatch } from 'src/app/shared/validators/mustmatch.validator';
import { CompleteAccountModel } from 'src/app/core/models/auth/complete-account.model';
import { GuestUpdateInfo } from 'src/app/shared/actions/auth.actions';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { getLoading, currentUserDetails } from '../../../shared/selectors/auth.selector';
import { AppUser } from 'src/app/core/models/user.model';

@Component({
    selector: 'app-complete-account',
    templateUrl: './complete-account.component.html'
})
export class CompleteAccountComponent implements OnInit, OnDestroy {
    form: FormGroup;
    states$: Observable<any>;
    countries$: Observable<any>;
    loading$: Observable<boolean>;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    zipCode: string;
    currentUser: AppUser;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.loading$ = this.store.select(getLoading);
        this.countries$ = this.store.select(selectCountries);
        this.states$ = this.store.select(selectStates, {
            countryId: 1
        }).pipe(takeUntil(this.ngUnsubscribe));

        this.store.pipe(select(currentUserDetails), takeUntil(this.ngUnsubscribe)).subscribe(currentUser => {
            if (currentUser) {
                this.currentUser = currentUser;

                this.createForm();
            }
        });

        this.store.dispatch(new LoadCountries());
        this.store.dispatch(new LoadStates({
            countryId: 1
        }));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    createForm() {
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            businessName: [''],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            city: ['', Validators.required],
            address: ['', Validators.required],
            zipCode: [this.currentUser.sites[0].shippingAddress.zipCode, Validators.required],
            countryId: [1, Validators.required],
            state: ['', Validators.required],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    save() {
        if (!this.form.valid) {
            return;
        }

        const completeAccount = new CompleteAccountModel();
        completeAccount.email = this.form.controls.email.value;
        completeAccount.password = this.form.controls.password.value;
        completeAccount.confirmPassword = this.form.controls.confirmPassword.value;
        completeAccount.companyName = this.form.controls.businessName.value;
        completeAccount.firstName = this.form.controls.firstName.value;
        completeAccount.lastName = this.form.controls.lastName.value;
        completeAccount.phoneNumber = this.form.controls.phoneNumber.value;
        completeAccount.city = this.form.controls.city.value;
        completeAccount.countryId = this.form.controls.countryId.value;
        completeAccount.stateId = this.form.controls.state.value;
        completeAccount.street = this.form.controls.address.value;

        this.store.dispatch(new GuestUpdateInfo(completeAccount));
    }

    cancel() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }
}
