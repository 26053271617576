import { createSelector } from '@ngrx/store';

export const selectSiteState = state => state.site;

export const sitesList = createSelector(
    selectSiteState,
    site => site.sites
);

export const selectSitesList = createSelector(
    selectSiteState,
    state => state.sitesList
);

export const selectLoadingSitesList = createSelector(
    selectSiteState,
    state => state.loadingSites
);

export const selectCreditRequestApp = createSelector(
    selectSiteState,
    site => site.creditRequestApp
);
export const selectCompanyDetails = createSelector(
    selectSiteState,
    site => site.companyDetails
);

export const selectLoadingCompanyDetails = createSelector(
    selectSiteState,
    site => site.loadingCompanyDetails
);

export const selectLoadingUpdateAddress = createSelector(
    selectSiteState,
    site => site.loadingEditAddress
);