import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { Logout, SendVerificationEmail } from 'src/app/shared/actions/auth.actions';
import { Observable } from 'rxjs';
import { AppUser } from 'src/app/core/models/user.model';
import {
    currentUserDetails, currentCompanyType, getAccountTypeId, currentSiteID,
    currentSite
} from 'src/app/shared/selectors/auth.selector';
import { newOrdersCount } from 'src/app/shared/selectors/order.selector';
import { environment } from 'src/environments/environment';
import { selectInfobarState, isOpenInfobar } from 'src/app/shared/selectors/infobar.selector';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { InfobarComponents, InfobarCompleteAccountComponents } from 'src/app/core/enums/infobar-components.enum';
import { selectShowSearch } from 'src/app/shared/selectors/search.selector';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { PlatformType } from 'src/environments/platform-type.enum';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { PubnubService } from 'src/app/core/services/chat/pubnub.service';
import { LoadAllChannels, ChatDisplayStatusAction } from 'src/app/shared/actions/chat.actions';
import { getChatChannels, getChatDisplayStatus } from 'src/app/shared/selectors/chat.selector';
import { EntityChanel } from 'src/app/core/models/chat/entity-chanel.model';
import { getBranding } from 'src/app/shared/selectors/branding.selector';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-master-page',
    templateUrl: './master-page.component.html',
    styleUrls: ['./master-page.component.scss']
})
export class MasterPageComponent implements OnInit {

    isMenuOpen = false;

    currentUser: AppUser;

    isAccountVerified$: Observable<boolean>;

    newOrdersCount$: Observable<number>;

    infobarConfigs$: Observable<InfobarState>;

    showSearch$: Observable<boolean>;

    currentCompanyType$: Observable<COMPANY_TYPE>;

    InfobarComponents = InfobarComponents;
    COMPANY_TYPE = COMPANY_TYPE;
    displayNotifier: boolean;
    currentPlatform = environment.platformId;
    PlatformType = PlatformType;
    branding: BrandingModel;
    chatDisplayStatus: ChatDisplayStatus = new ChatDisplayStatus();
    accountType$: Observable<AccountTypes>;
    newMessages = 0;
    AccountTypes = AccountTypes;

    constructor(
        private store: Store<AppState>,
        private pubnunbService: PubnubService
    ) { }

    ngOnInit() {
        this.store.pipe(select(currentUserDetails)).subscribe(userDetails => {
            if (userDetails) {
                this.currentUser = userDetails;
                this.displayNotifier = !this.currentUser.hasValidEmail;
            }
        });
        this.newOrdersCount$ = this.store.select(newOrdersCount);
        this.infobarConfigs$ = this.store.select(selectInfobarState);
        this.showSearch$ = this.store.select(selectShowSearch);
        this.currentCompanyType$ = this.store.select(currentCompanyType);
        this.accountType$ = this.store.select(getAccountTypeId);

        this.store.pipe(select(currentSiteID)).subscribe(siteId => {
            if (siteId && this.currentUser && !this.currentUser.isGuest) {
                this.store.pipe(select(currentSite, { currentSiteId: siteId }))
                    .pipe(
                        filter(site => site !== null && site !== undefined)
                    )
                    .subscribe(site => {
                        this.pubnunbService.initPubnub(site.secretKey);
                    });

                this.store.dispatch(new LoadAllChannels({
                    statuses: [1, 2, 3],
                    currentPage: 1,
                    pageSize: undefined,
                    entityTypeId: undefined,
                    keyword: ''
                }));
            }
        });

        this.store.pipe(select(getBranding)).subscribe((branding: BrandingModel) => {
            if (branding) {
                this.branding = branding;
            }
        });

        this.store.pipe(select(getChatChannels)).subscribe((channels: Array<EntityChanel>) => {
            this.newMessages = 0;
            channels.forEach(channel => {
                this.newMessages += channel.unreadMessagesNo;
            });
        });

        this.store.pipe(select(getChatDisplayStatus)).subscribe((data: ChatDisplayStatus) => {
            if (data && data.entityTypeId) {
                this.chatDisplayStatus = data;
            } else {
                this.resetChatStatus();
            }
        });

        this.store.pipe(select(isOpenInfobar)).subscribe(isOpen => {
            if (isOpen) {
                this.resetChatStatus();
                this.chatDisplayStatus.chatClosed = true;
                this.store.dispatch(new ChatDisplayStatusAction(this.chatDisplayStatus));
            }
        });
    }

    get logo() { return `${environment.assets.baseUrl}/logo.png`; }

    get productsLink() { return `${environment.basePlatformUrl}/Dmx/OrderGuide`; }

    get ordersLink() { return `${environment.basePlatformUrl}/Dmx/Orders`; }

    get vendorsLink() { return `${environment.basePlatformUrl}/Dmx/Vendors`; }

    get companyLink() { return `${environment.basePlatformUrl}/Dmx/Company`; }

    get isCrispAvailable() { return window['$crisp'] }

    openChat() {
        this.chatDisplayStatus.showOnlyInbox = true;
        this.chatDisplayStatus.entityTypeId = 1;
        this.chatDisplayStatus.showChat = false;
        this.chatDisplayStatus.entityId = null;
        this.chatDisplayStatus.entity = null;
        this.store.dispatch(new ChatDisplayStatusAction(this.chatDisplayStatus));
        this.hideInfobar();
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    hideInfobar() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    logout() {
        this.store.dispatch(new Logout());
    }

    openSupportChat() {
        const isVisible = window['$crisp'].is("chat:visible");
        window['$crisp'].push(["do", `chat:${isVisible ? 'hide' : 'show'}`]);
        if (!isVisible) {
            window['$crisp'].push(["do", "chat:open"]);
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        let clickInSidebar = false;
        let node = event.target;
        while (node) {
            if (node.className && node.className.includes && (node.className.includes('sidebar') || node.className.includes('menu-mobile-action-button'))) {
                clickInSidebar = true;
                break;
            }

            node = node.parentElement;
        }

        if (!clickInSidebar) {
            this.isMenuOpen = false;
        }
    }

    onSendVerificationEmail($event) {
        $event.preventDefault();

        this.store.dispatch(new SendVerificationEmail());
    }

    onShowFilter() {
        const pageSidebar = document.getElementsByClassName('page__sidebar')[0];
        const menuCover = document.getElementsByClassName('menu-mobile-cover-close')[0];
        if (pageSidebar) {
            if (pageSidebar.classList.contains('page__sidebar--visible')) {
                pageSidebar.classList.remove('page__sidebar--visible');
            } else {
                pageSidebar.classList.add('page__sidebar--visible');
            }
        }

        if (menuCover) {
            if (menuCover.classList.contains('menu-mobile-cover')) {
                pageSidebar.classList.remove('menu-mobile-cover');
            } else {
                pageSidebar.classList.add('menu-mobile-cover');
            }
        }
    }

    resetChatStatus() {
        this.chatDisplayStatus.showChat = false;
        this.chatDisplayStatus.showOnlyInbox = false;
        this.chatDisplayStatus.showNewConversation = false;
        this.chatDisplayStatus.entityTypeId = null;
        this.chatDisplayStatus.entity = null;
        this.chatDisplayStatus.entityId = null;
        this.chatDisplayStatus.buyerVendor = null;
        this.chatDisplayStatus.chatClosed = true;
    }

    changeZipCode() {
        this.store.dispatch(new Logout({ changeZip: true }));
    }
}
