import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadStatuses } from '../../actions/order.actions';
import { Observable } from 'rxjs';
import { selectStatuses } from '../../selectors/order.selector';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { map, tap } from 'rxjs/operators';
import { CustomerStatusType } from 'src/app/core/enums/customer-status.enum';
import { CsvCreator } from 'ag-grid-community';

@Component({
    selector: 'app-customer-status-filter',
    templateUrl: './customer-status-filter.component.html',
    styleUrls: ['./customer-status-filter.component.scss']
})
export class CustomerStatusFilterComponent implements OnInit {

    // statuses$: Observable<any[]>;
    // private statuses = [];

    @Input() customerStatus: CustomerStatusType[] = [];
    @Output() change = new EventEmitter<any>();

    // @Input() selectedStatuses: number[] = [];
    // @Output() change = new EventEmitter<any>();

    showActive = false;
    showOnHold = false;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        // this.store.dispatch(new LoadStatuses());
        // this.statuses$ = this.store.select(selectStatuses)
        //     .pipe(
        //         map((statuses) => statuses.map((s) => ({
        //             ...s,
        //             isSelected: this.selectedStatuses.includes(s.id)
        //         }))),
        //         tap(statuses => this.statuses = statuses)
        //     );
    }
    ngOnChanges() {
        this.showActive = false;
        this.showOnHold = false;

        this.customerStatus.forEach(cs => {
            switch (cs) {
                case CustomerStatusType.Active:
                    this.showActive = true;
                    break;
                case CustomerStatusType.OnHold:
                    this.showOnHold = true;
                    break;
            }
        });
    }

    onSelectActive($event: MatCheckboxChange) {
        this.showActive = $event.checked;
        this.triggerChange();
    }

    onSelectOnHold($event: MatCheckboxChange) {
        this.showOnHold = $event.checked;
        this.triggerChange();
    }

    onSelectAll() {
        this.showActive = false;
        this.showOnHold = false;
        this.triggerChange();
    }

    private triggerChange() {
        const customerStatus = [];
        if (this.showActive) {
            customerStatus.push(CustomerStatusType.Active);
        }

        if (this.showOnHold) {
            customerStatus.push(CustomerStatusType.OnHold);
        }

        this.change.emit(customerStatus);
    }
}
