import { DeliveryDayModel } from 'src/app/core/models/account/delivery-day.model';
import { DeliveryDayActions, DeliveryDayActionTypes } from '../actions/delivery-day.actions';

export interface DeliveryDayState {
    loading: boolean;
    deliveryDays: Array<DeliveryDayModel>;
    specialDeliveryDays: Array<DeliveryDayModel>;
}

const initialState: DeliveryDayState = {
    loading: false,
    deliveryDays: [],
    specialDeliveryDays: []
};

export function deliveryDayReducer(state = initialState, action: DeliveryDayActions): DeliveryDayState {
    switch (action.type) {
        case DeliveryDayActionTypes.LoadDeliveryDays:
            return {
                ...state,
                loading: true
            };

        case DeliveryDayActionTypes.LoadDeliveryDaysSuccess:
            return {
                ...state,
                loading: false,
                deliveryDays: action.payload
            };
        case DeliveryDayActionTypes.LoadSpecialDeliveryDays:
            return {
                ...state,
                loading: true
            };

        case DeliveryDayActionTypes.LoadSpecialDeliveryDaysSuccess:
            return {
                ...state,
                loading: false,
                specialDeliveryDays: action.payload
            };
        case DeliveryDayActionTypes.SaveDeliveryDay:
            return {
                ...state,
                loading: true
            };

        case DeliveryDayActionTypes.SaveDeliveryDaySuccess:
            const deliveryDayIndex = state.deliveryDays.findIndex(deliveryDay => (deliveryDay.id || deliveryDay.deliverySettingsId) ===
                (action.payload.id || action.payload.deliverySettingsId));

            if (action.payload.deleteSecondDelivery) {
                state.deliveryDays.splice(deliveryDayIndex, 1);
            } else {
                if (deliveryDayIndex !== -1) {
                    state.deliveryDays.splice(deliveryDayIndex, 1, action.payload);
                } else {
                    state.deliveryDays.push(action.payload);
                }
            }

            return {
                ...state,
                loading: false,
                deliveryDays: [...state.deliveryDays]
            };
        case DeliveryDayActionTypes.SaveSpecialDeliveryDay:
            return {
                ...state,
                loading: true
            };

        case DeliveryDayActionTypes.SaveSpecialDeliveryDaySuccess:
            const specialDayIndex = state.specialDeliveryDays.findIndex(specialDay =>
                specialDay.id === action.payload.id);
            if (specialDayIndex === -1) {
                state.specialDeliveryDays.splice(0, 0, action.payload);
            } else {
                state.specialDeliveryDays.splice(specialDayIndex, 1, action.payload);
            }

            return {
                ...state,
                loading: false,
                specialDeliveryDays: [...state.specialDeliveryDays]
            };

        case DeliveryDayActionTypes.DeleteSpecialDeliveryDay:
            return {
                ...state,
                loading: true
            };

        case DeliveryDayActionTypes.DeleteSpecialDeliveryDaySuccess:
            const index = state.specialDeliveryDays.findIndex(specialDeliveryDay => specialDeliveryDay.id === action.payload);
            state.specialDeliveryDays.splice(index, 1);

            return {
                ...state,
                loading: false,
                specialDeliveryDays: [...state.specialDeliveryDays]
            };
        default:
            return state;
    }
}



