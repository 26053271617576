import { Action } from '@ngrx/store';

export enum RegisterActionTypes {
    Register = '[Register] Register Account',
    RegisterInfo = '[Register Info ] Register Info',
    UpdateRegisterLoading = '[Register] Register Account Error',
    VerifyAccount = '[VerifyAccount] VerifyAccount',
    VerifyAccountSuccess = '[VerifyAccount] VerifyAccountSuccess',
    VerifyAccountError = '[VerifyAccount] VerifyAccountError',
    LoadProfiles = '[Profiles] LoadProfiles',
    LoadProfilesSuccess = '[Profiles] LoadProfilesSuccess'
}

export class Register implements Action {
    readonly type = RegisterActionTypes.Register;

    constructor(public payload: { account: any }) { }
}

export class RegisterInfo implements Action {
    readonly type = RegisterActionTypes.RegisterInfo;

    constructor(public payload: { account: any }) { }
}

export class UpdateRegisterLoading implements Action {
    readonly type = RegisterActionTypes.UpdateRegisterLoading;

    constructor(public payload: { loading?: boolean }) { }
}

export class VerifyAccount implements Action {
    readonly type = RegisterActionTypes.VerifyAccount;

    constructor(public payload: { token: string }) { }
}

export class VerifyAccountSuccess implements Action {
    readonly type = RegisterActionTypes.VerifyAccountSuccess;
}

export class VerifyAccountError implements Action {
    readonly type = RegisterActionTypes.VerifyAccountError;

    constructor(public payload: { message: string }) { }
}

export class LoadProfiles implements Action {
    readonly type = RegisterActionTypes.LoadProfiles;
}

export class LoadProfilesSuccess implements Action {
    readonly type = RegisterActionTypes.LoadProfilesSuccess;

    constructor(public payload: { profiles: any[] }) { }
}

export type RegisterActions = Register
    | RegisterInfo
    | UpdateRegisterLoading
    | VerifyAccount
    | VerifyAccountSuccess
    | VerifyAccountError
    | LoadProfiles
    | LoadProfilesSuccess;
