import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-market',
    templateUrl: './icon-market.component.html',
    styleUrls: ['./icon-market.component.scss']
})
export class IconMarketComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
