import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Observable } from 'rxjs';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { CategoryOptionModel } from 'src/app/core/models/product/category-option.model';
import {
    getUomOptions, getCategoryOptions, getProductCatalog, getProductCatalogLoading,
    getRelatedUom
} from 'src/app/shared/selectors/product.selector';
import { LoadUomOptions, LoadCategoryOptions, SaveProductCatalog, LoadRelatedUom } from 'src/app/shared/actions/product.actions';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';

@Component({
    selector: 'app-add-edit-product',
    templateUrl: './add-edit-product.component.html'
})
export class AddEditProductComponent implements OnInit {

    @Input() baseCatalogId: number;
    @Input() productId: number;
    @Input() editMode: boolean;

    uomOptions$: Observable<Array<UnitOfMeasureModel>>;
    relatedUomOptions$: Observable<Array<UnitOfMeasureModel>>;
    categoryOptions$: Observable<Array<CategoryOptionModel>>;
    loading$: Observable<boolean>;

    form: FormGroup;
    isOffer: boolean;
    isAvailable: boolean;
    productCatalog: ProductCatalogModel;
    url: string;
    file: File;

    constructor(private fb: FormBuilder, private store: Store<AppState>) { }

    ngOnInit() {
        this.uomOptions$ = this.store.select(getUomOptions);
        this.relatedUomOptions$ = this.store.select(getRelatedUom);
        this.categoryOptions$ = this.store.select(getCategoryOptions);
        this.loading$ = this.store.select(getProductCatalogLoading);

        this.store.pipe(select(getProductCatalog, { productId: this.productId })).subscribe((data: ProductCatalogModel) => {
            if (data) {
                this.productCatalog = data;
                this.createForm();
            } else {
                this.createForm();
            }
        });

        this.store.dispatch(new LoadUomOptions());
        this.store.dispatch(new LoadCategoryOptions());
    }

    createForm() {
        this.form = this.fb.group({
            name: [this.productCatalog ? this.productCatalog.name : '', Validators.required],
            category: [this.productCatalog ? this.productCatalog.categoryId : '', Validators.required],
            description: [this.productCatalog ? this.productCatalog.shortDescription : ''],
            shippingWeight: [this.productCatalog ? this.productCatalog.weight : ''],
            sku: [this.productCatalog ? this.productCatalog.vendorSku : ''],
            packSize: [this.productCatalog ? this.productCatalog.uomSize : '', Validators.required],
            unitSize: [this.productCatalog ? this.productCatalog.unitUomSize : '', Validators.required],
            packSizeUom: [this.productCatalog ? this.productCatalog.uomId : '', Validators.required],
            unitSizeUom: [this.productCatalog ? this.productCatalog.unitUomId : '', Validators.required],
            packDescription: [this.productCatalog ? this.productCatalog.uomDescription : ''],
            unitUomDescription: [this.productCatalog ? this.productCatalog.unitUomDescription : ''],
            isOffered: [this.productCatalog ? this.productCatalog.isOffered : false],
            isAvailable: [this.productCatalog ? this.productCatalog.isAvailable : false]
        });
    }

    save() {
        if (this.form.invalid) {
            return;
        }

        const productCatalog = new ProductCatalogModel();
        productCatalog.id = this.productCatalog ? this.productCatalog.id : -1;
        productCatalog.isPrivate = this.productCatalog ? this.productCatalog.isPrivate : true;
        productCatalog.name = this.form.value.name;
        productCatalog.categoryId = this.form.value.category;
        productCatalog.shortDescription = this.form.value.description;
        productCatalog.weight = this.form.value.shippingWeight;
        productCatalog.vendorSku = this.form.value.sku;
        productCatalog.uomSize = this.form.value.packSize;
        productCatalog.unitUomSize = this.form.value.unitSize;
        productCatalog.uomId = this.form.value.packSizeUom;
        productCatalog.unitUomId = this.form.value.unitSizeUom;
        productCatalog.uomDescription = this.form.value.packDescription;
        productCatalog.unitUomDescription = this.form.value.unitUomDescription;
        productCatalog.isOffered = this.form.value.isOffered;
        productCatalog.newIsAvailable = this.form.value.isAvailable;
        productCatalog.baseCatalogId = this.baseCatalogId;
        productCatalog.historyID = this.productCatalog ? this.productCatalog.historyID : -1;

        this.store.dispatch(new SaveProductCatalog(
            {
                product: productCatalog,
                file: this.file
            }
        ));
    }

    processFile(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(files.item(0));

        fileReader.onload = (event: any) => {
            this.url = event.target.result;
        };

        this.file = files.item(0);
    }

    cancel() {
        this.form.reset();
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    onSetOffer() {
        this.isOffer = !this.isOffer;
    }

    onSetAvailable() {
        this.isAvailable = !this.isAvailable;
    }

    uploadFile() {
        document.getElementById('selectedFile').click();
    }

    getImageSourceUrl(): string {
        if (this.url) {
            return this.url;
        }

        return this.productCatalog && this.productCatalog.imageUrl ? this.productCatalog.imageUrl :
            ('assets/images/categories/' + (this.productCatalog.categoryNameUrl ?
                this.productCatalog.categoryNameUrl : 'default') + '.svg');
    }

    deleteFile() {
        this.url = undefined;
        this.file = undefined;

        if (this.productCatalog) {
            this.productCatalog.imageUrl = undefined;
        }
    }

    getButtonTitle() {
        return this.editMode ? 'Save' : 'Add Product';
    }

    loadRelatedUom(event: any) {
        if (!this.editMode) {
            this.store.dispatch(new LoadRelatedUom(event.value));
        }
    }

    getUomOptions(): Observable<Array<UnitOfMeasureModel>> {
        return this.editMode ? this.uomOptions$ : this.relatedUomOptions$;
    }
}
