import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/shared/validators/mustmatch.validator';
import { ChangePasswordModel } from 'src/app/core/models/change-password.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import * as authActions from '../../actions/auth.actions';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { InfobarChangePasswordComponents, InfobarComponents } from 'src/app/core/enums/infobar-components.enum';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

    changePasswordForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private store: Store<AppState>) { }

    ngOnInit() {
        this.changePasswordForm = this.formBuilder.group(
            {
                currentPassword: ['', Validators.required],
                newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/)]],
                confirmPassword: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/)]]
            },
            {
                validator: MustMatch('newPassword', 'confirmPassword')
            }
        );
    }

    changePassword() {
        if (this.changePasswordForm.valid) {
            const changePasswordModel = new ChangePasswordModel(this.changePasswordForm.value);
            this.store.dispatch(new authActions.ChangePassword(changePasswordModel));
        }
    }

    get formValid() { return this.changePasswordForm.valid; }

    get form() { return this.changePasswordForm.controls; }
}
