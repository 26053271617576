export class ResendInviteModel {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    priceLevelId: number;
    accountManagerId: number;
    message: string;

    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.priceLevelId = data.priceLevelId;
            this.accountManagerId = data.accountManagerId;
            this.message = data.message;
        }
    }
}
