import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BrandingModel } from '../../models/branding/branding.model';
import { PlatformConfigurationModel } from '../../models/branding/platform-config.model';

const VENDOR_STORE_KEY = 'oewijf43iod-dsifdsf4-sfdsf4-sf4s4';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {

    constructor(private http: HttpClient) { }

    getBranding(siteId: number = null): Observable<BrandingModel> {
        let headers = new HttpHeaders();
        if (siteId) {
            headers = headers.append('SITE-ID', `${siteId}`);
        }

        return this.http.get<BrandingModel>(`${environment.api.content}/Branded/profile`, {
            headers
        });
    }

    getPlatformConfiguration(): Observable<PlatformConfigurationModel> {
        return this.http.get<PlatformConfigurationModel>(`${environment.api.content}/Branded/platform_config`);
    }

    storeVendorStoreKey(vendorStoreAccountId: number, vendorStoreSiteId?: number) {
        localStorage.setItem(VENDOR_STORE_KEY, btoa(`${vendorStoreAccountId}|${vendorStoreSiteId || ''}`));
    }

    clearVendorStoreKey() {
        localStorage.removeItem(VENDOR_STORE_KEY);
    }

    get vendorStoreKey() {
        return localStorage.getItem(VENDOR_STORE_KEY);
    }
}
