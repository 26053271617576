import { EntityChanel } from './entity-chanel.model';
import { MessageModel } from './message.model';

export class ChatConversation {
    channel: EntityChanel;
    member: any;
    messages: Array<MessageModel> = new Array<MessageModel>();

    constructor(data?: any) {
        if (data) {
            this.channel = data.channel;

            if (data.messages) {
                data.messages.messages.map(message => this.messages.push(new MessageModel(message.message)));
            }
        }
    }
}
