import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
    providedIn: 'root'
})

export class OrderGuideService {

    constructor(private http: HttpClient) { }

    getOrderGuide(params: any) {
        return this.http.post(`${environment.api.content}/Products/search`, {
            page: params.page,
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories,
            isMasterCategory: params.isMasterCategory,
            keyword: params.keyword
        });
    }

    print(params: any) {
        return this.http.post(`${environment.api.content}/Products/get_order_guide_pdf`, {
            page: params.page,
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories,
            isMasterCategory: params.isMasterCategory,
            keyword: params.keyword
        });
    }

    addToCart(params: any) {
        return this.http.post(`${environment.api.content}/Products/add_to_cart`, {
            productId: params.productId,
            vendorId: params.vendorId,
            quantity: params.quantity
        });
    }

    @Cacheable()
    getCategories(params: any) {
        return this.http.post(`${environment.api.content}/Category`, {
            sort: params.sort,
            viewModes: params.viewModes,
            vendors: params.vendors,
            categories: params.categories
        });
    }

}
