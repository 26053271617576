import { ProductActions, ProductActionTypes } from '../actions/product.actions';
import { CategoryModel } from 'src/app/core/models/product/category.model';
import { ProductDetailsModel } from 'src/app/core/models/product/product-details.model';
import { BaseCatalogModel } from 'src/app/core/models/product/base-catalog.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { CategoryOptionModel } from 'src/app/core/models/product/category-option.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { ConvertedPriceModel } from 'src/app/core/models/product/converted-price.model';
import { ProductUploadResponseModel } from 'src/app/core/models/product/upload-response.model';
import { ProductPriceLevelModel } from 'src/app/core/models/product/product-price-level.model';

export interface ProductState {
    categories: Array<CategoryModel>;
    productDetails: ProductDetailsModel;
    loading: boolean;
    productCatalog: ProductCatalogModel;
    loadingProductCatalog: boolean;
    baseCatalogs: Array<BaseCatalogModel>;
    categoryOptions: Array<CategoryOptionModel>;
    uomOptions: Array<UnitOfMeasureModel>;
    convertedPrice: Array<ConvertedPriceModel>;
    relatedUom: Array<UnitOfMeasureModel>;
    reloadPage: boolean;
    lastSavedProductId: number;
    uploadPricesSummary: ProductUploadResponseModel;
    priceLevels: Array<ProductPriceLevelModel>;
    uploadLoading: boolean;
}

export const initialState: ProductState = {
    categories: [],
    productDetails: undefined,
    loading: false,
    productCatalog: undefined,
    loadingProductCatalog: false,
    baseCatalogs: [],
    categoryOptions: [],
    uomOptions: [],
    convertedPrice: undefined,
    relatedUom: [],
    reloadPage: false,
    lastSavedProductId: undefined,
    uploadPricesSummary: undefined,
    priceLevels: [],
    uploadLoading: false
};

export function productReducer(state = initialState, action: ProductActions): ProductState {
    switch (action.type) {
        case ProductActionTypes.LoadCategories:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.LoadCategoriesSuccess:
            return {
                ...state,
                loading: false,
                categories: action.payload
            };
        case ProductActionTypes.LoadProducts:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.LoadProductsSuccess:
            return {
                ...state,
                loading: false,
                productDetails: action.payload
            };
        case ProductActionTypes.SaveProductSettingsSuccess:
            return {
                ...state,
                lastSavedProductId: action.payload
            };
        case ProductActionTypes.SaveProduct:
            return {
                ...state,
                lastSavedProductId: undefined
            };
        case ProductActionTypes.SaveProductSuccess:
            return {
                ...state,
                lastSavedProductId: action.payload.id
            };
        case ProductActionTypes.LoadProductDetails:
            return {
                ...state,
                loadingProductCatalog: true
            };
        case ProductActionTypes.LoadProductDetailsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                productCatalog: action.payload
            };
        case ProductActionTypes.SavePriceLevelsForProduct:
            return {
                ...state,
                loadingProductCatalog: true,
                reloadPage: false
            };
        case ProductActionTypes.SavePriceLevelsForProductSuccess:
            const productIndex = state.productDetails.items.findIndex(product => product.id === action.payload.id);

            if (productIndex !== -1) {
                const prodCatalog = state.productDetails.items[productIndex];
                state.productDetails.items[productIndex] = action.payload;
                state.productDetails.items[productIndex].isPackPrice = prodCatalog.isPackPrice;
                state.productDetails.items[productIndex].isPricePerUnit = prodCatalog.isPricePerUnit;
                state.productDetails.items[productIndex].isCalculatedFrom = prodCatalog.isCalculatedFrom;
                state.productDetails.items[productIndex].uomName = prodCatalog.uomName;
                state.productDetails.items[productIndex].unitUomList = prodCatalog.unitUomList;
            }

            return {
                ...state,
                loadingProductCatalog: false,
                productCatalog: action.payload,
                convertedPrice: undefined,
                productDetails: state.productDetails,
                reloadPage: true
            };
        case ProductActionTypes.LoadBaseCatalogs:
            return {
                ...state,
                loadingProductCatalog: true
            };
        case ProductActionTypes.LoadBaseCatalogsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                baseCatalogs: action.payload
            };
        case ProductActionTypes.LoadCategoryOptionsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                categoryOptions: action.payload
            };
        case ProductActionTypes.LoadUomOptionsSuccess:
            return {
                ...state,
                loadingProductCatalog: false,
                uomOptions: action.payload,
                relatedUom: []
            };
        case ProductActionTypes.SaveProductCatalog:
            return {
                ...state,
                loadingProductCatalog: true,
                productCatalog: undefined
            };
        case ProductActionTypes.SaveProductCatalogSuccess:
            const prodIndex = state.productDetails.items.findIndex(product => product.id === action.payload.id);

            if (prodIndex > -1) {
                state.productDetails.items.splice(prodIndex, 1, action.payload);
            } else {
                state.productDetails.items.splice(0, 0, action.payload);
            }

            return {
                ...state,
                loadingProductCatalog: false,
                productDetails: {
                    ...state.productDetails,
                    items: [...state.productDetails.items]
                }
            };
        case ProductActionTypes.LoadConvertedPriceSuccess:
            return {
                ...state,
                convertedPrice: action.payload
            };
        case ProductActionTypes.LoadRelatedUomSuccess:
            return {
                ...state,
                relatedUom: action.payload
            };
        case ProductActionTypes.UploadPrices:
            return {
                ...state,
                uploadLoading: true
            };
        case ProductActionTypes.UploadPricesSuccess:
            return {
                ...state,
                uploadPricesSummary: action.payload,
                uploadLoading: false
            };
        case ProductActionTypes.UploadPricesFinish:
            return {
                ...state,
                uploadLoading: true
            };
        case ProductActionTypes.UploadPricesSuccessFinish:
            return {
                ...state,
                uploadPricesSummary: undefined,
                reloadPage: true,
                uploadLoading: false
            };
        case ProductActionTypes.LoadPriceLevelsSuccess:
            return {
                ...state,
                priceLevels: action.payload
            }
        case ProductActionTypes.ExportProducts:
            return {
                ...state,
                loading: true
            };
        case ProductActionTypes.ExportProductsSuccess:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
