import { BrandingState } from '../reducers/branding.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getBrandingState = createFeatureSelector<BrandingState>('branding');

export const getBrandingLoading = createSelector(
    getBrandingState,
    state => state.loading
);

export const getBranding = createSelector(
    getBrandingState,
    state => state.branding
);

export const getPlatformConfiguration = createSelector(
    getBrandingState,
    state => state.platformConfiguration
);

