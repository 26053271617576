import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import {
    ProductActionTypes, LoadProducts, LoadProductsSuccess, SaveProductSettings, SaveProductSettingsSuccess, SaveProduct,
    SaveProductSuccess, LoadProductDetails, LoadProductDetailsSuccess, SavePriceLevelsForProduct,
    SavePriceLevelsForProductSuccess, LoadBaseCatalogsSuccess,
    LoadCategoryOptionsSuccess, LoadUomOptionsSuccess, SaveProductCatalog, SaveProductCatalogSuccess, LoadConvertedPrice,
    UploadPrices, UploadPricesSuccess,
    UploadPricesFinish, UploadPricesSuccessFinish, LoadPriceLevels, LoadPriceLevelsSuccess,
    LoadConvertedPriceSuccess, LoadRelatedUom, LoadRelatedUomSuccess, ExportProducts, ExportProductsSuccess
} from '../actions/product.actions';
import { mergeMap, map } from 'rxjs/operators';
import { LoadCategoriesSuccess } from '../actions/product.actions';
import { ProductService } from 'src/app/core/services/product/product.service';
import { CategoryModel } from 'src/app/core/models/product/category.model';
import { ProductDetailsModel } from 'src/app/core/models/product/product-details.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { BaseCatalogModel } from 'src/app/core/models/product/base-catalog.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { CategoryOptionModel } from 'src/app/core/models/product/category-option.model';
import { ToggleInfobar } from '../actions/infobar.actions';
import { AppState } from 'src/app/app.reducer';
import { ConvertedPriceModel } from 'src/app/core/models/product/converted-price.model';
import { ProductUploadResponseModel } from 'src/app/core/models/product/upload-response.model';
import { ProductPriceLevelModel } from 'src/app/core/models/product/product-price-level.model';
import * as moment from 'moment';

@Injectable()
export class ProductEffects {
    constructor(
        private actions$: Actions,
        private productService: ProductService,
        private store: Store<AppState>
    ) { }

    @Effect()
    loadCategories$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadCategories),
        mergeMap(() => this.productService.getAllCategories().pipe(
            map((data: Array<CategoryModel>) => {
                return new LoadCategoriesSuccess(data);
            })
        ))
    );

    @Effect()
    loadProducts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadProducts),
        mergeMap((action: LoadProducts) => this.productService.getProducts(action.payload).pipe(
            map((products: ProductDetailsModel) => {
                return new LoadProductsSuccess(products);
            })
        ))
    );

    @Effect()
    saveProductSettings$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveProductSettings),
        mergeMap((action: SaveProductSettings) => this.productService.saveProductSettings(action.payload).pipe(
            map(() => new SaveProductSettingsSuccess(action.payload.productId))
        ))
    );

    @Effect()
    saveProduct$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveProduct),
        mergeMap((action: SaveProduct) => this.productService.saveProduct(action.payload).pipe(
            map((data: ProductCatalogModel) => new SaveProductSuccess(data))
        ))
    );

    @Effect()
    loadProductCatalog$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadProductDetails),
        mergeMap((action: LoadProductDetails) => this.productService.loadPriceLevelsForProduct(action.payload).pipe(
            map((data: ProductCatalogModel) => new LoadProductDetailsSuccess(data))
        ))
    );

    @Effect()
    saveBaseCatalog$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SavePriceLevelsForProduct),
        mergeMap((action: SavePriceLevelsForProduct) => this.productService.savePriceLevelsForProduct(action.payload).pipe(
            map((data: ProductCatalogModel) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new SavePriceLevelsForProductSuccess(data);
            })
        ))
    );

    @Effect()
    convertedPrice$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadConvertedPrice),
        mergeMap((action: LoadConvertedPrice) => this.productService.getConvertedPrice(action.payload).pipe(
            map((data: Array<ConvertedPriceModel>) => {
                if (action.payload[0].productId) {
                    data[0].productId = action.payload[0].productId;
                }
                return new LoadConvertedPriceSuccess(data);
            })
        ))
    );

    @Effect()
    loadBaseCatalogs$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadBaseCatalogs),
        mergeMap(() => this.productService.getBaseCatalogs().pipe(
            map((data: Array<BaseCatalogModel>) => new LoadBaseCatalogsSuccess(data))
        ))
    );

    @Effect()
    loadCategoryOptions$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadCategoryOptions),
        mergeMap(() => this.productService.getCategoryOptions().pipe(
            map((data: Array<CategoryOptionModel>) => new LoadCategoryOptionsSuccess(data))
        ))
    );

    @Effect()
    loadUomOptions$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadUomOptions),
        mergeMap(() => this.productService.getUomOptions().pipe(
            map((data: Array<UnitOfMeasureModel>) => new LoadUomOptionsSuccess(data))
        ))
    );

    @Effect()
    saveProductCatalog$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveProductCatalog),
        mergeMap((action: SaveProductCatalog) => this.productService.saveProductCatalog(action.payload).pipe(
            map((data: ProductCatalogModel) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));
                return new SaveProductCatalogSuccess(data);
            })
        ))
    );

    @Effect()
    loadRelatedUom$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadRelatedUom),
        mergeMap((action: LoadRelatedUom) => this.productService.getRelatedUom(action.payload).pipe(
            map((data: Array<UnitOfMeasureModel>) => new LoadRelatedUomSuccess(data))
        ))
    );

    @Effect()
    uploadPrices$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.UploadPrices),
        mergeMap((action: UploadPrices) => this.productService.uploadProductsPrices(action.payload).pipe(
            map((data: ProductUploadResponseModel) => new UploadPricesSuccess(data))
        ))
    );

    @Effect()
    saveUpload$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.UploadPricesFinish),
        mergeMap((action: UploadPricesFinish) => this.productService.saveUploadProductsPrices(action.payload).pipe(
            map(() => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new UploadPricesSuccessFinish();
            })
        ))
    );

    @Effect()
    loadPriceLevels$ = this.actions$.pipe(
        ofType<LoadPriceLevels>(ProductActionTypes.LoadPriceLevels),
        mergeMap(() => this.productService.getPriceLevels().pipe(
            map((priceLevels: Array<ProductPriceLevelModel>) => new LoadPriceLevelsSuccess(priceLevels))
        ))
    );

    @Effect()
    exportProducts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.ExportProducts),
        mergeMap((action: ExportProducts) => this.productService.exportProducts(action.payload).pipe(
            map((data: any) => {
                const filename = 'Products_Export ' + moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a') + '.csv';
                const blob = new Blob([data], { type: 'application/vnd.ms.excel' });

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    }, 0);
                }

                return new ExportProductsSuccess(data);
            })
        ))
    );
}
