import { Action } from '@ngrx/store';
import { Params } from '@angular/router';

export enum SearchActionTypes {
    ShowSearchAction = '[Global] Show Search',
    SearchByKeywordGlobalAction = '[Header] Search by keyword from header input',
    UpdateSearchKeyword = '[Global] UpdateSearchKeyword',
    UpdateParams = '[Global] Update params'
}

export class SearchByKeywordGlobal implements Action {
    readonly type = SearchActionTypes.SearchByKeywordGlobalAction;

    constructor(public payload: { keyword: string }) { }
}

export class ShowSearch implements Action {
    readonly type = SearchActionTypes.ShowSearchAction;

    constructor(public payload: { show: boolean, searchUrl?: string, searchText?: string }) { }
}

export class UpdateSearchKeyword implements Action {
    readonly type = SearchActionTypes.UpdateSearchKeyword;

    constructor(public payload: { triggerSearch: boolean, keyword: string }) { }
}

export class UpdateParams implements Action {
    readonly type = SearchActionTypes.UpdateParams;

    constructor(public payload: {}) { }
}

export type SearchAction = SearchByKeywordGlobal | ShowSearch | UpdateSearchKeyword | UpdateParams;
