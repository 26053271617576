import { environment } from 'src/environments/environment';
import { GridColumnDateGrouping } from 'src/app/core/enums/date-groping.enum';
import * as moment from 'moment';

export function LOGIN_DATA_STORAGE_KEY() {
    return `mkt_usr_key_${environment.platformId}`;
};

export function USER_PERMISSIONS_STORAGE_KEY() {
    return `mkt_usr_permissions_key_${environment.platformId}`;
}

export const DATE_RANGE_DEFAULT_OPTIONS = [
    { value: GridColumnDateGrouping.DAILY, viewValue: 'Daily' },
    { value: GridColumnDateGrouping.WEEKLY, viewValue: 'Weekly' },
    { value: GridColumnDateGrouping.MONTHLY, viewValue: 'Monthly' }
];

export const DATE_CUSTOM_RANGES = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Year to Date': [moment().startOf('year'), moment()]
};

export const DEFAULT_DATE_RANGE_SELECTION = {
    startDate: moment().subtract(4, 'weeks'),
    endDate: moment()
};

  
export function randomColor() {
    const colorsArray = ['#95BF16', '#E8C625', '#54DD8B'];
    const color = colorsArray[Math.floor(Math.random()*colorsArray.length)];
    return color;
}

export function getDateWithSetTimes(time: string) {
    if (!time || !time.length) {
        return;
    }

    const date = new Date();
    const parts = time.split(":");
    if (parts.length !== 2) {
      date.setHours(0);
      date.setMinutes(0);
      return date;
    }

    date.setHours(parseInt(parts[0]));
    date.setMinutes(parseInt(parts[1].split(" ")[0]));
    return date;
  }