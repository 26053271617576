import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Scroll } from '@angular/router';
import { filter, delay, withLatestFrom } from 'rxjs/operators';
import { AppState } from './app.reducer';
import { Store, select } from '@ngrx/store';
import { ShowSearch } from './shared/actions/search.actions';
import { ViewportScroller } from '@angular/common';
import { currentUserDetails } from './shared/selectors/auth.selector';
import { environment } from 'src/environments/environment';
import { LoadBranding } from './shared/actions/branding.actions';
import { getBranding } from './shared/selectors/branding.selector';
import { BrandingModel } from './core/models/branding/branding.model';
import { PlatformType } from 'src/environments/platform-type.enum';
import { AuthenticationService } from './core/services/authentication/authentication.service';
import { Title } from '@angular/platform-browser';

// declare ga as a function to set and sent the events
declare let ga: Function;
declare let fbq: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store<AppState>,
        private viewportScroller: ViewportScroller,
        private titleSvc: Title
    ) {
        this.router.events
            .pipe(
                filter((e: any): e is Scroll => e instanceof Scroll),
                delay(0),
            )
            .subscribe(e => {
                if (e.position) {
                    viewportScroller.scrollToPosition(e.position);
                } else if (e.anchor) {
                    viewportScroller.scrollToAnchor(e.anchor);
                } else {
                    viewportScroller.scrollToPosition([0, 0]);
                }
            });
    }

    ngOnInit() {
        this.router.events.pipe(
            withLatestFrom(this.store.select(currentUserDetails)),
            filter(([ev, userDetails]) => ev instanceof NavigationEnd)
        ).subscribe(([event, userDetails]: [NavigationEnd, any]) => {
            let activatedRouteData: any = {};
            let child = this.activatedRoute.firstChild;
            while (child !== null) {
                activatedRouteData = { ...activatedRouteData, ...child.snapshot.data };
                child = child.firstChild;
            }
            this.store.dispatch(new ShowSearch({
                show: activatedRouteData.showSearch || false,
                searchUrl: activatedRouteData.searchUrl || undefined,
                searchText: activatedRouteData.searchText || 'Search'
            }));

            if (window['_mfq']) {
                window['_mfq'].push(["newPageView", window.location.pathname]);
                window['_mfq'].push(["setVariable", "Platform", environment.platformName]);
                if (userDetails && userDetails.email) {
                    window['_mfq'].push(["setVariable", "UserName", userDetails.email]);
                }
            }

            try {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
            catch (e) { }

            try {
                fbq('track', 'PageView');
            }
            catch (e) { }

        });

        this.store.pipe(select(getBranding)).subscribe((branding: BrandingModel) => {
            if (branding) {
                this.titleSvc.setTitle(branding.companyName);
                document.documentElement.style.setProperty('--primary', branding.mainColor);
            } else {
                switch (environment.platformId) {
                    case PlatformType.AMAST:
                        this.titleSvc.setTitle('AMAST');
                        document.documentElement.style.setProperty('--primary', '#648091');
                        break;
                    case PlatformType.DINEMARKET:
                        this.titleSvc.setTitle('Dinemarket');
                        document.documentElement.style.setProperty('--primary', '#7aa238');
                        break;
                    case PlatformType.ACADIA_MARKET:
                        this.titleSvc.setTitle('Acadia Market');
                        document.documentElement.style.setProperty('--primary', '#91C82B');
                        break;
                }
            }
        });

        this.store.dispatch(new LoadBranding());
    }


}
