import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {

  @Input() status;
  @Input() orderId: number;
  @Input() availableStatuses = [];

  @Output() selected = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onSelect($event, status) {
    this.selected.emit({
      orderId: this.orderId,
      statusId: status.id
    });
  }

}
