import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { AppUser } from 'src/app/core/models/user.model';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { UserPermissionGroup } from 'src/app/core/models/user-permission-group.model';
import { LoginDataModel } from 'src/app/core/models/auth/login-data.model';

export interface AuthState {
    loggedIn: boolean;
    loginData: LoginDataModel;
    userDetails: AppUser;
    currentSiteID: any;
    companyType?: COMPANY_TYPE;
    homeUrl?: string;
    userPermissions: Array<UserPermissionGroup>;
    loading: boolean;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    loginData: undefined,
    userDetails: undefined,
    currentSiteID: undefined,
    homeUrl: undefined,
    userPermissions: undefined,
    loading: false
};

export function authReducer(state = initialAuthState,
    action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.LoginAction:
            return {
                loggedIn: true,
                loginData: action.payload.loginResponse,
                userDetails: undefined,
                currentSiteID: undefined,
                userPermissions: action.payload.userPermissionGroups,
                loading: false
            };
        case AuthActionTypes.LogoutAction:
            return {
                loggedIn: false,
                loginData: undefined,
                userDetails: undefined,
                currentSiteID: undefined,
                companyType: undefined,
                userPermissions: undefined,
                loading: false
            };
        case AuthActionTypes.LoadUserDetailsAction:
            const currentSite = action.payload.userDetails.sites.find(s => s.id === action.payload.userDetails.defaultSiteId)
                || action.payload.userDetails.sites[0];
            return {
                ...state,
                userDetails: action.payload.userDetails,
                currentSiteID: currentSite.id,
                companyType: currentSite.companyTypeId
            };
        case AuthActionTypes.UpdateSelectedSiteAction:
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    defaultSiteId: action.payload.siteId
                },
                currentSiteID: action.payload.siteId,
                companyType: action.payload.companyTypeId
            };
        case AuthActionTypes.HomeUrlLoaded:
            return {
                ...state,
                homeUrl: action.payload.url
            };
        case AuthActionTypes.LoadUserPermissions:
            return {
                ...state
            };
        case AuthActionTypes.LoadUserPermissionsSuccess:
            return {
                ...state,
                userPermissions: action.payload
            };
        case AuthActionTypes.ChangePasswordSuccess:
            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    accessToken: action.payload
                }
            };
        case AuthActionTypes.GuestRegister:
            return {
                ...state,
                loading: true
            };
        case AuthActionTypes.GuestRegisterSuccess:
            return {
                ...state,
                loading: false
            };
        case AuthActionTypes.GuestUpdateInfo:
            return {
                ...state,
                loading: true
            };
        case AuthActionTypes.GuestUpdateInfoSuccess:
            return {
                ...state,
                loading: false
            };
        case AuthActionTypes.GuestUpdateInfoFail:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
