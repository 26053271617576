import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { currentUserDetails } from '../../selectors/auth.selector';

@Component({
    selector: 'app-page-with-sidebar',
    templateUrl: './page-with-sidebar.component.html',
    styleUrls: ['./page-with-sidebar.component.scss']
})
export class PageWithSidebarComponent implements OnInit {

    isSidebarOpen = true;
    currentUserDetails$: Observable<any>;
    @Input() addContentPadding = true;

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.currentUserDetails$ = this.store.select(currentUserDetails);
    }

    toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
    }

}
