import { createSelector, select } from '@ngrx/store';

export const selectOrderState = state => state.order;

export const newOrdersCount = createSelector(
    selectOrderState,
    order => order.newOrdersCount
);
export const selectOrderDetails = createSelector(
    selectOrderState,
    state => state.orders
);
export const selectOrderDetailssLoading = createSelector(
    selectOrderState,
    state => state.loading
  );

export const selectStatuses = createSelector(
    selectOrderState,
    state => state.statuses
);

export const selectOrder = createSelector(
    selectOrderState,
    state => state.orderDetails
  );

export const selectLoadingOrderDetails = createSelector(
    selectOrderState,
    state => state.loadingOrderDetails
);


export const selectEditProducts = createSelector(
    selectOrderState,
    state => state.editProducts
);