import { Component, OnInit, Injector, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { LoadCategories } from '../../actions/order-guide.action';
import { Observable } from 'rxjs';
import { selectCategories } from '../../selectors/order-guide.selector';
import { map } from 'rxjs/operators';
import { IconBeveragesComponent } from '../../icons/icon-beverages/icon-beverages.component';
import { IconBreadComponent } from '../../icons/icon-bread/icon-bread.component';
import { IconChinaComponent } from '../../icons/icon-china/icon-china.component';
import { IconDairyComponent } from '../../icons/icon-dairy/icon-dairy.component';
import { IconFreshpressedjuiceComponent } from '../../icons/icon-freshpressedjuice/icon-freshpressedjuice.component';
import { IconMeatComponent } from '../../icons/icon-meat/icon-meat.component';
import { IconOfficesuppliesComponent } from '../../icons/icon-officesupplies/icon-officesupplies.component';
import { IconPapergoodsComponent } from '../../icons/icon-papergoods/icon-papergoods.component';
import { IconPastryComponent } from '../../icons/icon-pastry/icon-pastry.component';
import { IconProduceComponent } from '../../icons/icon-produce/icon-produce.component';
import { IconSeafoodComponent } from '../../icons/icon-seafood/icon-seafood.component';
import { IconAllCategoriesComponent } from '../../icons/icon-all-categories/icon-all-categories.component';
import { IconGroceryComponent } from '../../icons/icon-grocery/icon-grocery.component';
import { IconBuildingMaterialsComponent } from '../../icons/icon-building-materials/icon-building-materials.component';
import { IconElectricalComponent } from '../../icons/icon-electrical/icon-electrical.component';
import { IconFlooringAreaRugsComponent } from '../../icons/icon-flooring-area-rugs/icon-flooring-area-rugs.component';
import { IconKitchenKitchenwareComponent } from '../../icons/icon-kitchen-kitchenware/icon-kitchen-kitchenware.component';
import { IconPaintComponent } from '../../icons/icon-paint/icon-paint.component';
import { IconPlumbingComponent } from '../../icons/icon-plumbing/icon-plumbing.component';
import { IconToolsComponent } from '../../icons/icon-tools/icon-tools.component';
import { IconBathFaucetsComponent } from '../../icons/icon-bath-faucets/icon-bath-faucets.component';
import { IconMaintenanceComponent } from '../../icons/icon-maintenance/icon-maintenance.component';
import { IconDoorsWindowsComponent } from '../../icons/icon-doors-windows/icon-doors-windows.component';
import { IconHardwareComponent } from '../../icons/icon-hardware/icon-hardware.component';
import { IconHeatingCoolingComponent } from '../../icons/icon-heating-cooling/icon-heating-cooling.component';
import { IconTilesComponent } from '../../icons/icon-tiles/icon-tiles.component';
import { IconLightningCeilingFansComponent } from '../../icons/icon-lightning-ceiling-fans/icon-lightning-ceiling-fans.component';

@Component({
    selector: 'app-categories-filter',
    templateUrl: './categories-filter.component.html'
})
export class CategoriesFilterComponent implements OnInit, OnChanges {

    categories$: Observable<any[]>;

    @Input() selectedCategoryId: number;
    @Input() selectedCategories: number[] = [];
    @Input() multiSelection = false;
    @Input() categories = [];

    @Output() change = new EventEmitter<any>();


    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.categories = this.categories.map(c => {
            c.isSelected = this.selectedCategories.includes(c.id);
            c.isOpen = c.isSelected;

            if (c.subCategories) {
                c.subCategories.forEach(sc => {
                    sc.isSelected = this.selectedCategories.includes(sc.id);
                    if (sc.isSelected) {
                        c.isOpen = true;
                    }
                });
            }

            return {
                ...c,
                component: this.getCategoryIcon(c.name)
            };
        });
    }

    onSelect($event, category) {
        $event.preventDefault();
        if (this.multiSelection) {
            if (this.selectedCategories.includes(category.id)) {
                this.selectedCategories = this.selectedCategories.filter(c => c !== category.id);
            } else {
                this.selectedCategories.push(category.id);
            }

            this.change.emit(this.selectedCategories);
        } else {
            if (category.isSelected) {
                category.isOpen = !category.isOpen;
            }
            this.change.emit(category);
        }
    }

    onSelectAll() {
        this.selectedCategories = [];
        this.change.emit(undefined);
    }

    private getCategoryIcon(categoryName: string) {
        switch (categoryName.toLowerCase().replace(/ /g, '')) {
            case 'beverages':
                return IconBeveragesComponent;
            case 'bread':
                return IconBreadComponent;
            case 'china':
                return IconChinaComponent;
            case 'dairy':
                return IconDairyComponent;
            case 'freshpressedjuice':
                return IconFreshpressedjuiceComponent;
            case 'grocery':
                return IconGroceryComponent;
            case 'meat':
                return IconMeatComponent;
            case 'officesupplies':
                return IconOfficesuppliesComponent;
            case 'papergoods':
                return IconPapergoodsComponent;
            case 'pastry':
                return IconPastryComponent;
            case 'produce':
                return IconProduceComponent;
            case 'seafood':
                return IconSeafoodComponent;
            case 'buildingmaterials':
                return IconBuildingMaterialsComponent;
            case 'electrical':
                return IconElectricalComponent;
            case 'flooring&arearugs':
                return IconFlooringAreaRugsComponent;
            case 'kitchen&kitchenware':
                return IconKitchenKitchenwareComponent;
            case 'paint':
                return IconPaintComponent;
            case 'plumbing':
                return IconPlumbingComponent;
            case 'tools':
                return IconToolsComponent;
            case 'bath&faucets':
                return IconBathFaucetsComponent;
            case 'maintenance':
                return IconMaintenanceComponent;
            case 'doors&windows':
                return IconDoorsWindowsComponent;
            case 'hardware':
                return IconHardwareComponent;
            case 'heating&cooling':
                return IconHeatingCoolingComponent;
            case 'tiles':
                return IconTilesComponent;
            case 'lightning&ceilingfans':
                return IconLightningCeilingFansComponent;
            default:
                return IconAllCategoriesComponent;
        }
    }

}
