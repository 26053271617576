import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-vendors',
  templateUrl: './icon-vendors.component.html',
  styleUrls: ['./icon-vendors.component.scss']
})
export class IconVendorsComponent implements OnInit {

  @Input() cssClass = 'icon--18';
  
  constructor() { }

  ngOnInit() {
  }

}
