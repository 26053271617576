import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
  selector: 'app-icon-plus',
  templateUrl: './icon-plus.component.html',
  styleUrls: ['./icon-plus.component.scss']
})
export class IconPlusComponent implements OnInit {
  
  currentPlatform = environment.platformId;
  PlatformType = PlatformType;

  @Input() cssClass = '';

  constructor() { }

  ngOnInit() {
  }

}
