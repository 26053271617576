import { BrandingActionTypes, BrandingActions } from '../actions/branding.actions';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { PlatformConfigurationModel } from 'src/app/core/models/branding/platform-config.model';

export interface BrandingState {
    loading: boolean;
    branding: BrandingModel;
    platformConfiguration: PlatformConfigurationModel;
}

const initialState: BrandingState = {
    loading: false,
    branding: undefined,
    platformConfiguration: undefined
};

export function brandingReducer(state = initialState, action: BrandingActions): BrandingState {
    switch (action.type) {
        case BrandingActionTypes.LoadBranding:
            return {
                ...state,
                loading: true
            };

        case BrandingActionTypes.LoadBrandingSuccess:
            return {
                ...state,
                loading: false,
                branding: action.payload
            };
        case BrandingActionTypes.LoadPlatformConfigurationSuccess:
            return {
                ...state,
                platformConfiguration: action.payload
            };
        default:
            return state;
    }
}



