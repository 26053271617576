import { Component, OnInit, Input } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { getProductCatalog, getProductCatalogLoading, getConvertedPrice } from 'src/app/shared/selectors/product.selector';
import { LoadProductDetails, SavePriceLevelsForProduct, LoadConvertedPrice } from 'src/app/shared/actions/product.actions';
import { Observable } from 'rxjs';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { PriceLevelInfo } from 'src/app/core/models/product/price-level-info.model';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { ConvertedPriceModel } from 'src/app/core/models/product/converted-price.model';
import { CatalogColorStatus } from 'src/app/core/models/product/catalog-color-status.enum';
import { PricingUpdateType } from 'src/app/core/enums/price-level.enum';

@Component({
    selector: 'app-price-level-list',
    templateUrl: './price-level-list.component.html'
})
export class PriceLevelListComponent implements OnInit {

    @Input() productId: number;
    productCatalog: ProductCatalogModel;
    loadingPriceLevels$: Observable<boolean>;
    catalogColorStatus = CatalogColorStatus;
    priceLevelUpdateType = PricingUpdateType;

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.loadingPriceLevels$ = this.store.select(getProductCatalogLoading);

        this.store.pipe(select(getProductCatalog, { productId: this.productId })).subscribe((data: ProductCatalogModel) => {
            if (data) {
                data.displayPricePerUnit = true;
                this.productCatalog = data;
            }
        });

        this.store.pipe(select(getConvertedPrice)).subscribe((data: Array<ConvertedPriceModel>) => {
            if (data && this.productCatalog) {
                data.forEach(convertedPrice => {
                    const index = this.productCatalog.priceLevelInfo.findIndex(priceLev => priceLev.vendorId ===
                        convertedPrice.priceLevelId);

                    if (index > -1) {
                        this.productCatalog.priceLevelInfo[index].price = convertedPrice.packPrice;
                        this.productCatalog.priceLevelInfo[index].unitPrice = convertedPrice.unitPrice;
                        this.productCatalog.priceLevelInfo[index].baseCatalogDiscountFee = data.length !== 1 ?
                            convertedPrice.defaultDiscountFee : this.setPriceLevelAfterConversion(convertedPrice);
                    }
                });

                const baseCatalogIndex = data.findIndex(convertedPrice =>
                    convertedPrice.priceLevelId === this.productCatalog.baseCatalogId);

                if (baseCatalogIndex > -1) {
                    this.productCatalog.price = data[baseCatalogIndex].packPrice;
                    this.productCatalog.unitPrice = data[baseCatalogIndex].unitPrice;
                }
            }
        });

        this.store.dispatch(new LoadProductDetails(this.productId));
    }

    setPriceLevelAfterConversion(convertedPrice: ConvertedPriceModel): number {
        const baseCatalogDiscountFee = this.productCatalog.price === 0 ? 0 :
            +((convertedPrice.packPrice - this.productCatalog.price) * 100 / this.productCatalog.price).toFixed(2);

        return baseCatalogDiscountFee;
    }

    isOfferToggleChange(event: any, baseCatalog: ProductCatalogModel) {
        baseCatalog.isOffered = event.checked;
    }

    isAvailableToggleChange(event: any, priceLevel: PriceLevelInfo) {
        priceLevel.isAvailable = event.checked;
    }

    savePriceLevelList() {
        this.productCatalog.newPrice = this.productCatalog.price;
        this.productCatalog.newUnitPrice = this.productCatalog.unitPrice;
        this.productCatalog.newIsAvailable = this.productCatalog.isAvailable;
        this.productCatalog.newIsAvailableLocked = this.productCatalog.isAvailableLocked;
        this.store.dispatch(new SavePriceLevelsForProduct(this.productCatalog));
    }

    cancel() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    getConvertedPriceAll(productCatalog: ProductCatalogModel, type: number) {
        const convertedPrice = new Array<ConvertedPriceModel>();
        convertedPrice.push(this.setConvertedPrice(productCatalog.price, productCatalog.unitPrice,
            productCatalog.baseCatalogId, type, productCatalog.baseCatalogDiscountFee));

        productCatalog.priceLevelInfo.forEach(priceLevel => {
            if (priceLevel.allowEdit) {
                convertedPrice.push(this.setConvertedPrice(priceLevel.price, priceLevel.unitPrice,
                    priceLevel.vendorId, PricingUpdateType.Pack, priceLevel.baseCatalogDiscountFee));
            }
        });

        this.store.dispatch(new LoadConvertedPrice(convertedPrice));
    }

    getConvertedPrice(priceLevel: PriceLevelInfo, type: number, inheritPrice: boolean, inheritDiscountFee: boolean) {
        if (!inheritDiscountFee) {
            priceLevel.price = this.productCatalog.price + (this.productCatalog.price * priceLevel.baseCatalogDiscountFee) / 100;
            priceLevel.markupStatus = CatalogColorStatus.Blue;
        }

        if (!inheritPrice) {
            priceLevel.priceStatus = CatalogColorStatus.Blue;
        }

        const convertedPrice = [this.setConvertedPrice(priceLevel.price, priceLevel.unitPrice,
            priceLevel.vendorId, type, priceLevel.baseCatalogDiscountFee)];

        priceLevel.inheritDiscountFee = inheritDiscountFee;
        priceLevel.inheritPrice = inheritPrice;

        this.store.dispatch(new LoadConvertedPrice(convertedPrice));
    }

    reset(priceLevel: PriceLevelInfo) {
        priceLevel.baseCatalogDiscountFee = priceLevel.defaultDiscountFee;
        priceLevel.price = this.productCatalog.price + (this.productCatalog.price * priceLevel.baseCatalogDiscountFee) / 100;
        priceLevel.markupStatus = CatalogColorStatus.Green;
        priceLevel.priceStatus = CatalogColorStatus.Green;

        this.getConvertedPrice(priceLevel, this.priceLevelUpdateType.Pack, true, true);
    }

    setConvertedPrice(price: number, unitPrice: number, priceLevelId: number, type: number, discountFee: number): ConvertedPriceModel {
        const convertedPrice = new ConvertedPriceModel();

        convertedPrice.packPrice = price;
        convertedPrice.unitPrice = unitPrice;
        convertedPrice.packUomId = this.productCatalog.uomId;
        convertedPrice.unitUomId = this.productCatalog.unitUomId;
        convertedPrice.unitUomSize = this.productCatalog.unitUomSize;
        convertedPrice.packUomSize = this.productCatalog.uomSize;
        convertedPrice.type = type;
        convertedPrice.priceLevelId = priceLevelId;
        convertedPrice.defaultDiscountFee = discountFee;

        return convertedPrice;
    }
}
