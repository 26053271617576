import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { LoadCountries, LoadStates } from '../../actions/utils.actions';
import { takeUntil, filter } from 'rxjs/operators';
import { selectStates, selectCountries } from '../../selectors/utils.selector';
import { ToggleInfobar } from '../../actions/infobar.actions';
import { ADDRESS_TYPE } from 'src/app/core/enums/address-type.enum';
import { UpdateAddress } from '../../actions/site.actions';
import { selectLoadingUpdateAddress } from '../../selectors/site.selector';

@Component({
    selector: 'app-edit-address',
    templateUrl: './edit-address.component.html',
    styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit, OnChanges {

    @Input() address: any;
    @Input() addressType: ADDRESS_TYPE;

    formEditAddress: FormGroup;
    countries$: Observable<any[]>;
    states$: Observable<any[]>;
    loading$: Observable<boolean>;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>) {
        this.formEditAddress = this.fb.group({
            id: [null, []],
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
            phone: ['', [Validators.pattern(/^[0-9]*$/), Validators.minLength(10), Validators.maxLength(10)]],
            address1: [null, [Validators.required]],
            city: [null, [Validators.required]],
            zipCode: [null, [Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
            countryId: [null, [Validators.required]],
            stateId: [null, [Validators.required]]
        });
    }

    ngOnInit() {
        this.loading$ = this.store.select(selectLoadingUpdateAddress);
        this.countries$ = this.store.select(selectCountries).pipe(takeUntil(this.ngUnsubscribe));
        this.store.dispatch(new LoadCountries());

        this.form.countryId.valueChanges
            .pipe(filter(value => value !== null && value !== undefined),
                takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.states$ = this.store.select(selectStates, {
                    countryId: this.form.countryId.value
                });

                this.store.dispatch(new LoadStates({
                    countryId: this.form.countryId.value
                }));

                this.form.stateId.setValue(null);
                this.form.stateId.updateValueAndValidity({ onlySelf: true });
            });
    }

    ngOnChanges(changes) {
        if (changes.address.currentValue) {
            this.formEditAddress.patchValue(changes.address.currentValue, {
                emitEvent: false
            });

            this.states$ = this.store.select(selectStates, {
                countryId: changes.address.currentValue.countryId
            });

            this.store.dispatch(new LoadStates({ countryId: changes.address.currentValue.countryId }));
        } else {
            this.formEditAddress.reset(undefined, { emitEvent: false });
        }
    }

    get form() { return this.formEditAddress.controls; }

    onSubmit() {
        if (this.formEditAddress.invalid) {
            return;
        }

        this.store.dispatch(new UpdateAddress({
            address: this.formEditAddress.value,
            addressType: this.addressType
        }))
    }

    onCancel() {
        this.formEditAddress.reset();
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

}
