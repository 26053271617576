import {
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { AuthState, authReducer } from './shared/reducers/auth.reducer';
import { CartState, cartReducer } from './shared/reducers/cart.reducer';
import { OrderState, orderReducer } from './shared/reducers/order.reducer';
import { SiteState, siteReducer } from './shared/reducers/site.reducer';
import { InfobarState, infobarReducer } from './shared/reducers/infobar.reducer';
import { SearchState, searchReducer } from './shared/reducers/search.reducer';
import { OrderGuideState, orderGuideReducer } from './shared/reducers/order-guide.reducer';
import { TagsState, tagsReducer } from './shared/reducers/tags.reducer';
import { PaymentState, paymentReducer } from './shared/reducers/payment.reducer';
import { UtilsState, utilsReducer } from './shared/reducers/utils.reducer';
import { vendorReducer, VendorState } from './shared/reducers/vendor.reducer';
import { UserState, userReducer } from './shared/reducers/user.reducer';
import { AuthActionTypes } from './shared/actions/auth.actions';
import { DraftState, draftReducer } from './shared/reducers/draft.reducer';
import { RatingState, ratingReducer } from './shared/reducers/rating.reducer';
import { chatReducer, ChatState } from './shared/reducers/chat.reducer';
import { CustomerState, customerReducer } from './shared/reducers/customer.reducer';
import { productReducer, ProductState } from './shared/reducers/product.reducer';
import { SettingsState, settingsReducer } from './shared/reducers/settings.reducer';
import { DeliveryDayState, deliveryDayReducer } from './shared/reducers/delivery-day.reducer';
import { PriceRequestsState, priceRequestsReducer } from './shared/reducers/price-requests.reducer';
import { brandingReducer, BrandingState } from './shared/reducers/branding.reducer';
import { RegisterState, registerReducer } from './shared/reducers/register.reducer';
import { couponReducer, CouponState } from './shared/reducers/coupon.reducer';

export interface AppState {
    auth: AuthState;
    cart: CartState;
    order: OrderState;
    draft: DraftState;
    site: SiteState;
    infobar: InfobarState;
    search: SearchState;
    orderGuide: OrderGuideState;
    tags: TagsState;
    payment: PaymentState;
    utils: UtilsState;
    vendors: VendorState;
    users: UserState;
    rating: RatingState;
    chat: ChatState;
    customer: CustomerState;
    product: ProductState;
    settings: SettingsState;
    deliveryDays: DeliveryDayState;
    priceRequests: PriceRequestsState;
    branding: BrandingState;
    register: RegisterState;
    coupon: CouponState;
}

export const reducers: ActionReducerMap<AppState> = {
    auth: authReducer,
    cart: cartReducer,
    order: orderReducer,
    draft: draftReducer,
    site: siteReducer,
    infobar: infobarReducer,
    search: searchReducer,
    orderGuide: orderGuideReducer,
    tags: tagsReducer,
    payment: paymentReducer,
    utils: utilsReducer,
    vendors: vendorReducer,
    users: userReducer,
    rating: ratingReducer,
    chat: chatReducer,
    customer: customerReducer,
    product: productReducer,
    settings: settingsReducer,
    deliveryDays: deliveryDayReducer,
    priceRequests: priceRequestsReducer,
    branding: brandingReducer,
    register: registerReducer,
    coupon: couponReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearState] : [clearState];

export function clearState(reducer) {
    return function (state, action) {

        if (action.type === AuthActionTypes.LogoutAction) {
            Object.keys(state).forEach(key => {
                if (key !== 'branding') {
                    state[key] = undefined;
                }
            });
        }

        return reducer(state, action);
    };
}
